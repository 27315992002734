import FaucetAbi from '../ABI/ABIFaucet.json';
import ContractsAddresses from './ContractsAdresses';

const faucetAddress = ContractsAddresses.faucet;

export async function getPrimarisHandler(web3) {

    try {
  
      if (!web3) {
        console.error('Web3 not provided');
        return;
      }
  
      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }

      const account = accounts[0];
  
      const faucetContract = new web3.eth.Contract(FaucetAbi, faucetAddress);
  
      // Estimate gas
      const gasEstimate = await faucetContract.methods.getPrimaris().estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
      
      // Obtener el gas price y aumentar en un 10%
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);
  
      // Send transaction
      const transaction = faucetContract.methods.getPrimaris()
        .send({ 
          from: account,
          gas: gasLimit,
          gasPrice: increasedGasPrice,
        });
  
      transaction
        .on('transactionHash', function(hash) {
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt) {
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
  
      const receipt = await transaction;
      console.log('PRIMARIS sended', receipt);
      return receipt;
    } catch (error) {
        if (error.message.includes("Error sending primaris")) {
            } 
        console.error("Error sending primaris:", error);
      throw error; 
    }
  }