import CampaignsAbi from '../ABI/ABICampaigns.json';
import { handlePermissions } from './PrimarisTokenPermissions';
import ContractsAddresses from './ContractsAdresses';

const campaignsAddress = ContractsAddresses.campaigns;
const approvalAmount = ContractsAddresses.approvalAmount;

// Campaigns Calls *****************************************************
export async function getReward(campaignId, web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(CampaignsAbi, campaignsAddress);
        const reward = await contract.methods.calculateMissionReward(campaignId).call();
        return reward;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting REWARD:', error);
      return [];
    }
}

export async function getEnergyRequired(campaignId, web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(CampaignsAbi, campaignsAddress);
        const energy = await contract.methods.calculateEnergyRequired(campaignId).call();
        return energy;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting REQUIRED ENERGY:', error);
      return [];
    }
}

export async function getEnergyCost(campaignId, web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(CampaignsAbi, campaignsAddress);
        const energyCost = await contract.methods.calculateEnergyCost(campaignId).call();
        return energyCost;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting ENERGY COST:', error);
      return [];
    }
}

export async function getSuccessChance(campaignId, gradeOfPower, currentMorale, currentGlory, web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(CampaignsAbi, campaignsAddress);
        const successRate = await contract.methods.getSuccessRate(campaignId, gradeOfPower, currentMorale, currentGlory).call();
        return successRate;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting SUCCESS CHANCE:', error);
      return [];
    }
}

export async function getDamage(campaignId, legionCombatPower, web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(CampaignsAbi, campaignsAddress);
        const damage = await contract.methods.calculateDamage(campaignId, legionCombatPower).call();
        return damage;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting POSIBLE DAMAGE:', error);
      return [];
    }
}

  // GO TO CAMPAIGN *****************************************************************************************************

export async function sendLegionToCampaign(campaignId, legionId, web3) {
    try {
  
      if (!window.ethereum) {
        console.error('MetaMask is not installed');
        return;
      }
  
      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];
      const isApproved = await handlePermissions(campaignsAddress, approvalAmount, web3);
      if (!isApproved) {
        console.error('Approval not granted');
        return;
      }
      console.log('Approval granted, proceeding to mint');
  
      const contract = new web3.eth.Contract(CampaignsAbi, campaignsAddress);
  
      const gasEstimate = await contract.methods.goToCampaign(campaignId, legionId).estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
  
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.5);
  
      console.log(`Gas Estimate: ${gasEstimate}`);
      console.log(`Gas Limit: ${gasLimit}`);
      console.log(`Gas Price: ${gasPrice}`);
      console.log(`Increased Gas Price: ${increasedGasPrice}`);
  
      await contract.methods.goToCampaign(campaignId, legionId).send({
        from: account,
        gas: gasLimit,
        gasPrice: increasedGasPrice
      })
        .on('transactionHash', function(hash){
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt){
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt){
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
      console.log('Legion send to Campaign');
    } catch (error) {
      console.error('Error:', error);
    }
}
