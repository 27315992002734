import React from 'react';
import Home from './Components/Home';
import ComingSoon from './Components/ComingSoon';
import { Web3Provider } from './Handlers/ChainContext';
import { SelectedTokensProvider } from './Handlers/NftsContext';


function App() {
  return (
    <Web3Provider>
      <SelectedTokensProvider>
        <Home />
      </SelectedTokensProvider>
    </Web3Provider>
  );
}

export default App;

