import LegionaryAbi from '../ABI/ABILegionaries.json';
import SpaceshipAbi from '../ABI/ABISpaceships.json';
import LegionAbi from '../ABI/ABILegions.json';
import LegionManagementAbi from '../ABI/ABILegionsManagement.json';
import { handlePermissions } from './PrimarisTokenPermissions';
import ContractsAddresses from './ContractsAdresses';

const legionaryAddress = ContractsAddresses.legionary;
const spaceshipAddress = ContractsAddresses.spaceship;
const legionAddress = ContractsAddresses.legion;
const legionManagementAddress = ContractsAddresses.legionManagement;
const approvalAmount = ContractsAddresses.approvalAmount;

// Permissions **********************************************************

async function handleSetApprovalForAll(contract, operatorAddress, web3) {
  try {

    if (!window.ethereum) {
      console.error('MetaMask is not installed');
      return;
    }

    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      console.error('No accounts found');
      return;
    }

    const account = accounts[0];

    const isApproved = await contract.methods.isApprovedForAll(account, operatorAddress).call();
    if (!isApproved) {

      const gasEstimate = await contract.methods.setApprovalForAll(operatorAddress, true).estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.1);

      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);

      console.log(`Gas Estimate: ${gasEstimate}`);
      console.log(`Gas Limit: ${gasLimit}`);
      console.log(`Gas Price: ${gasPrice}`);
      console.log(`Increased Gas Price: ${increasedGasPrice}`);

      await contract.methods.setApprovalForAll(operatorAddress, true).send({
        from: account,
        gas: gasLimit,
        gasPrice: increasedGasPrice
      });
      console.log(`Approval granted for ${operatorAddress} on contract ${contract.options.address}`);
    } else {
      console.log(`Already approved for ${operatorAddress} on contract ${contract.options.address}`);
    }
  } catch (error) {
    console.error('Error setting approval for all: ', error);
    alert('Error approving smart contracts interactions: ' + error.message);
  }
}

// LEGIONARIES SMART CONTRACTS FUNCTIONS *********************************

export async function mintLegionary(web3) {
  try {

    if (!web3) {
      console.error('Web3 not provided');
      return;
    }
    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      console.error('No accounts found');
      return;
    }

    const account = accounts[0];

    const isApproved = await handlePermissions(legionaryAddress, approvalAmount, web3);
    if (!isApproved) {
      console.error('Approval not granted');
      return;
    }
    console.log('Approval granted, proceeding to mint');

    const contract = new web3.eth.Contract(LegionaryAbi, legionaryAddress);

    const gasEstimate = await contract.methods.mintL().estimateGas({ from: account });
    const gasLimit = Math.floor(Number(gasEstimate) * 1.1);

    const gasPrice = await web3.eth.getGasPrice();
    const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);

    console.log(`Gas Estimate: ${gasEstimate}`);
    console.log(`Gas Limit: ${gasLimit}`);
    console.log(`Gas Price: ${gasPrice}`);
    console.log(`Increased Gas Price: ${increasedGasPrice}`);

    await contract.methods.mintL().send({
      from: account,
      gas: gasLimit,
      gasPrice: increasedGasPrice
    })
      .on('transactionHash', function(hash){
        console.log('Transaction Hash:', hash);
      })
      .on('confirmation', function(confirmationNumber, receipt){
        console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
      })
      .on('receipt', function(receipt){
        console.log('Receipt:', receipt);
      })
      .on('error', function(error, receipt) {
        console.error('Error in transaction:', error, 'Receipt:', receipt);
      });
    console.log('For the glory');
    return { success: true };
  } catch (error) {
    console.error('Error minting token:', error);
    return { success: false };
  }
}

export async function getLegionariesIds(web3) {
  try {
    if (window.ethereum) {
      const contract = new web3.eth.Contract(LegionaryAbi, legionaryAddress);
      const ids = await contract.methods.getTokenIds().call();
      return ids;
    } else {
      console.error('MetaMask is not installed');
      return [];
    }
  } catch (error) {
    console.error('Error getting token IDs:', error);
    alert('Error: ' + error.message);
    return [];
  }
}

export async function checkLegionaryUsed(tokenId, web3) {
  try {
    if (window.ethereum) {
      const contract = new web3.eth.Contract(LegionAbi, legionAddress);
      const isUsed = await contract.methods.legionaryUsed(tokenId).call();
      return isUsed;
    } else {
      console.error('MetaMask is not installed');
      return false;
    }
  } catch (error) {
    console.error('Error checking legionary usage:', error);
    alert('Error checking legionary usage: ' + error.message);
    return false;
  }
}

// SPACESHIPS SMART CONTRACTS FUNCTIONS************************************************

export async function mintShip(web3) {
  try {

    if (!web3) {
      console.error('Web3 not provided');
      return;
    }

    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      console.error('No accounts found');
      return;
    }

    const account = accounts[0];
    const isApproved = await handlePermissions(spaceshipAddress, approvalAmount, web3);
    if (!isApproved) {
      console.error('Approval not granted');
      return;
    }
    console.log('Approval granted, proceeding to mint');

    const contract = new web3.eth.Contract(SpaceshipAbi, spaceshipAddress);

    const gasEstimate = await contract.methods.mintS().estimateGas({ from: account });
    const gasLimit = Math.floor(Number(gasEstimate) * 1.1);

    const gasPrice = await web3.eth.getGasPrice();
    const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);

    console.log(`Gas Estimate: ${gasEstimate}`);
    console.log(`Gas Limit: ${gasLimit}`);
    console.log(`Gas Price: ${gasPrice}`);
    console.log(`Increased Gas Price: ${increasedGasPrice}`);

    await contract.methods.mintS().send({
      from: account,
      gas: gasLimit,
      gasPrice: increasedGasPrice
    })
      .on('transactionHash', function(hash){
        console.log('Transaction Hash:', hash);
      })
      .on('confirmation', function(confirmationNumber, receipt){
        console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
      })
      .on('receipt', function(receipt){
        console.log('Receipt:', receipt);
      })
      .on('error', function(error, receipt) {
        console.error('Error in transaction:', error, 'Receipt:', receipt);
      });
    console.log('All set');
    return { success: true };
  } catch (error) {
    console.error('Error minting token:', error);
    return { success: false };
  }
}

export async function getSpaceshipsIds(web3) {
  try {
    if (window.ethereum) {
      const contract = new web3.eth.Contract(SpaceshipAbi, spaceshipAddress);
      const ids = await contract.methods.getTokenIds().call();
      return ids;
    } else {
      console.error('MetaMask is not installed');
      return [];
    }
  } catch (error) {
    console.error('Error getting token IDs:', error);
    alert('Error getting token IDs: ' + error.message);
    return [];
  }
}

export async function checkSpaceshipUsed(tokenId, web3) {
  try {
    if (window.ethereum) {
      const contract = new web3.eth.Contract(LegionAbi, legionAddress);
      const isUsed = await contract.methods.spaceshipUsed(tokenId).call();
      return isUsed;
    } else {
      console.error('MetaMask is not installed');
      return false;
    }
  } catch (error) {
    console.error('Error checking spaceship usage:', error);
    alert('Error checking spaceship usage: ' + error.message);
    return false;
  }
}

// LEGIONS AND LEGIONS MANAGEMENT SMART CONTRACTS FUNCTIONS *****************************

export async function combineAndMintLegion(spaceshipTokenIds, legionaryTokenIds, name, web3) {
  try {

    if (!window.ethereum) {
      console.error('MetaMask is not installed');
      return;
    }

    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      console.error('No accounts found');
      return;
    }

    const account = accounts[0];

    const spaceshipContract = new web3.eth.Contract(SpaceshipAbi, spaceshipAddress);
    const legionaryContract = new web3.eth.Contract(LegionaryAbi, legionaryAddress);

    // Approve all tokens for the Legions contract
    await handleSetApprovalForAll(spaceshipContract, legionAddress, web3);
    await handleSetApprovalForAll(legionaryContract, legionAddress, web3);

    const isApproved = await handlePermissions(legionAddress, approvalAmount, web3);
    if (!isApproved) {
      console.error('Approval not granted');
      return;
    }
    console.log('Approval granted, proceeding to mint');

    const legionsContract = new web3.eth.Contract(LegionAbi, legionAddress);

    // Estimate gas
    const gasEstimate = await legionsContract.methods.combineAndMintLegion(account, spaceshipTokenIds, legionaryTokenIds, name).estimateGas({ from: account });
    const gasLimit = Math.floor(Number(gasEstimate) * 1.1);
    
    // Obtener el gas price y aumentar en un 50%
    const gasPrice = await web3.eth.getGasPrice();
    const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);

    // Send transaction
    const transaction = legionsContract.methods.combineAndMintLegion(account, spaceshipTokenIds, legionaryTokenIds, name)
      .send({ 
        from: account,
        gas: gasLimit,
        gasPrice: increasedGasPrice,
      });

    transaction
      .on('transactionHash', function(hash) {
        console.log('Transaction Hash:', hash);
      })
      .on('confirmation', function(confirmationNumber, receipt) {
        console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
      })
      .on('receipt', function(receipt) {
        console.log('Receipt:', receipt);
      })
      .on('error', function(error, receipt) {
        console.error('Error in transaction:', error, 'Receipt:', receipt);
      });

    const receipt = await transaction;
    console.log('Legion minted successfully', receipt);
    alert('Your legion: ' + name + " ist ready for the glory ");
    return { success: true, receipt: receipt };
  } catch (error) {
    console.error('Error crafting legion:', error);
    alert('Error crafting legion: ' + error.message);
    return { success: false, message: 'Error crafting legion: ', error: error };
  }
}

export async function addToLegion(legionId, legionaryTokenIds, spaceshipTokenIds, web3) {
  try {

    if (!window.ethereum) {
      console.error('MetaMask is not installed');
      return;
    }

    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      console.error('No accounts found');
      return;
    }

    const account = accounts[0];

    const spaceshipContract = new web3.eth.Contract(SpaceshipAbi, spaceshipAddress);
    const legionaryContract = new web3.eth.Contract(LegionaryAbi, legionaryAddress);

    await handleSetApprovalForAll(spaceshipContract, legionAddress, web3);
    await handleSetApprovalForAll(legionaryContract, legionAddress, web3);
    
    const isApproved = await handlePermissions(legionManagementAddress, approvalAmount, web3);
    if (!isApproved) {

      console.error('Approval not granted');
      return;
    }
    console.log('Approval granted, proceeding to add tokens');

    const legionsManagementContract = new web3.eth.Contract(LegionManagementAbi, legionManagementAddress);

    legionaryTokenIds = legionaryTokenIds || [];
    spaceshipTokenIds = spaceshipTokenIds || [];

    // Estimate gas
    const gasEstimate = await legionsManagementContract.methods.addNFTToLegion(account, legionId, legionaryTokenIds, spaceshipTokenIds).estimateGas({ from: account });
    const gasLimit = Math.floor(Number(gasEstimate) * 1.1);
    
    // Obtener el gas price y aumentar en un 10%
    const gasPrice = await web3.eth.getGasPrice();
    const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);

    // Send transaction
    const transaction = legionsManagementContract.methods.addNFTToLegion(account, legionId, legionaryTokenIds, spaceshipTokenIds)
      .send({ 
        from: account,
        gas: gasLimit,
        gasPrice: increasedGasPrice,
      });

    transaction
      .on('transactionHash', function(hash) {
        console.log('Transaction Hash:', hash);
      })
      .on('confirmation', function(confirmationNumber, receipt) {
        console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
      })
      .on('receipt', function(receipt) {
        console.log('Receipt:', receipt);
      })
      .on('error', function(error, receipt) {
        console.error('Error in transaction:', error, 'Receipt:', receipt);
      });

    const receipt = await transaction;
    console.log('Your legion has been updated: ', receipt);
    alert('Your legion has been updated');
    return { success: true, receipt: receipt };
  } catch (error) {
    console.error('Error adding troops to your legion:', error);
    alert('Error adding troops to your legion: ' + error.message);
    return { success: false, message: 'Error adding troops to your legion', error: error };
  }
}



