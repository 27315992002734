import React, { useState , useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import swap from "../Assets/Images/Icons/Secundarybar/swap.png";
import swap_ from "../Assets/Images/Icons/Secundarybar/swap_.png";
import contract from "../Assets/Images/Icons/Secundarybar/contract.png";
import contract_ from "../Assets/Images/Icons/Secundarybar/contract_.png";
import reward from "../Assets/Images/Icons/Secundarybar/reward.png";
import reward_ from "../Assets/Images/Icons/Secundarybar/reward_.png";
import partners from "../Assets/Images/Icons/Secundarybar/partners.png";
import partners_ from "../Assets/Images/Icons/Secundarybar/partners_.png";
import whitepaper from "../Assets/Images/Icons/Secundarybar/whitepaper.png";
import whitepaper_ from "../Assets/Images/Icons/Secundarybar/whitepaper_.png";

const Sidebar = styled.nav`
    position: fixed;
    top: 15%;
    right: 3.3%;
    height: ${({ isExpanded }) => (isExpanded ? "68%" : "0px")};
    width: 80px;
    background-color: #242124;
    box-shadow: 0 5px 10px rgba(141, 81, 207, 1);
    padding-top: 30px;
    border-radius: 10px;
    z-index: 7;
    overflow: ${({ overflow }) => overflow};
    transition: height 0.5s ease;
    transition-timing-function: ${({ isExpanded }) => (isExpanded ? "ease-out" : "ease-in")};
    .links {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 10%; 
        margin-top: 0;
        justify-content: center;
        align-items: center;
        height: 90%;
        li {
            width: 5vh; 
            height: 5vh; 
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            }
    } 
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 80px;
        right: 5%;
        
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 60px;
        height: ${({ isExpanded }) => (isExpanded ? "40%" : "0px")};
        padding-top: ${({ isExpanded }) => (isExpanded ? "50px" : "0px")};
        right: 3.8%;
        top: 18%;
        .links {
        gap: 2rem; 
        margin-top: 0;
        height: 100%;
        li {
            width: 3vh; 
            height: 3vh; 
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            }
    } 

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      display: none;

    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      display: none;
    }
    @media screen and (max-width: 479px) {
      display: none;
    }
`;

const ToggleButton = styled.button`
    position: fixed;
    top: 15%;
    right: 2%;
    z-index: 9;
    background-color: #1b1b1b;
    color: #8D51CF;
    padding: 15px 25px;
    border: 2px solid #8D51CF;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: #8D51CF;
        border: 2px solid #8D51CF;
        color: #FFFFFF;
        box-shadow: 0 5px 10px rgba(141, 81, 207, 0.5);
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding: 15px 25px;
        
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        top: 17%;
        right:2%;
        padding: 10px 20px;
        font-size: 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      display: none;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      display: none;
    }
    @media screen and (max-width: 479px) {
      display: none;
    }
`;

const IconImage = styled.img`
    width: 70%;
    margin-top: 10%;
    height: auto;
    z-index: 1;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 50%;
        
    }
`;

const IconDescription = styled.label`
    width: 30vh;
    height: 6vh;
    top: 0;
    right: 30%;
    text-align: left;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(10px);
    border-left: 0.5px solid #FF5733;
    top: 0;
`;


const IconText = styled.span`
    font-size: 2.4vh;
    color: rgba(255, 255, 255, 0.95);
    line-height: 7vh;
    margin-left: 10%;
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5vh; /* Ajusta el tamaño del contenedor */
    height: 5vh;
    &:hover ${IconImage} {
        transform: scale(1.2);
    }
    &:hover ${IconDescription} {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }
`;


export default function RightSideBar() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [overflow, setOverflow] = useState('hidden');

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        let timeout;
        if (isExpanded) {
            timeout = setTimeout(() => setOverflow('none'), 500); 
        } else {
            setOverflow('hidden'); 
        }
        return () => clearTimeout(timeout);
    }, [isExpanded]);

    const [currentIcon, setCurrentIcon] = useState({
        swap: swap,
        contract: contract,
        rewardpool: reward,
        partners: partners,
        whitepaper: whitepaper
    });

    const handleMouseEnter = (iconName) => {
        setCurrentIcon((prevIcons) => ({
            ...prevIcons,
            [iconName]: {
                swap: swap_,
                contract: contract_,
                rewardpool: reward_,
                partners: partners_,
                whitepaper: whitepaper_
            }[iconName]
        }));
    };

    const handleMouseLeave = (iconName) => {
        setCurrentIcon((prevIcons) => ({
            ...prevIcons,
            [iconName]: {
                swap: swap,
                contract: contract,
                rewardpool: reward,
                partners: partners,
                whitepaper: whitepaper
            }[iconName]
        }));
    };

    return (
        <>
            <ToggleButton onClick={handleToggle}>Help Center</ToggleButton>
            <Sidebar isExpanded={isExpanded} overflow={overflow}>

                <div className="links">

                        <Link to="/faucet">
                            <a href="https://app.uniswap.org/swap" target="_blank" rel="noopener noreferrer">
                                <IconWrapper
                                    onMouseEnter={() => handleMouseEnter('swap')}
                                    onMouseLeave={() => handleMouseLeave('swap')}
                                >
                                    <IconImage src={currentIcon.swap} alt="swap" />
                                    <IconDescription><IconText>Get $PRIMARIS</IconText></IconDescription>
                                </IconWrapper>
                            </a>
                        </Link>
                        <li>
                            <a href="https://amoy.polygonscan.com/token/0x563056eb6daafbd93227beb569bcbac09c10816e" target="_blank" rel="noopener noreferrer">
                                <IconWrapper
                                    onMouseEnter={() => handleMouseEnter('contract')}
                                    onMouseLeave={() => handleMouseLeave('contract')}
                                >
                                    <IconImage src={currentIcon.contract} alt="contract" />
                                    <IconDescription><IconText>Scan $PRIMARIS</IconText></IconDescription>
                                </IconWrapper>
                            </a>
                        </li>
                        <li>
                            <Link to="/">
                                <IconWrapper
                                    onMouseEnter={() => handleMouseEnter('rewardpool')}
                                    onMouseLeave={() => handleMouseLeave('rewardpool')}
                                >
                                    <IconImage src={currentIcon.rewardpool} alt="Reward Chest" />
                                    <IconDescription><IconText>Reward Chest</IconText></IconDescription>
                                </IconWrapper>
                            </Link>
                        </li>
                        <li>
                            <a href="https://app.gitbook.com/o/lXrbguLdbdGVjRRJZqHj/s/B6Q2ycNovylcpKJQLNYi/hoja-de-ruta/tecnologias-usadas" target="_blank" rel="noopener noreferrer">
                                <IconWrapper
                                    onMouseEnter={() => handleMouseEnter('partners')}
                                    onMouseLeave={() => handleMouseLeave('partners')}
                                >
                                    <IconImage src={currentIcon.partners} alt="Partners" />
                                    <IconDescription><IconText>Powered By</IconText></IconDescription>
                                </IconWrapper>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.gitbook.com/o/lXrbguLdbdGVjRRJZqHj/s/B6Q2ycNovylcpKJQLNYi" target="_blank" rel="noopener noreferrer">
                                <IconWrapper
                                    onMouseEnter={() => handleMouseEnter('whitepaper')}
                                    onMouseLeave={() => handleMouseLeave('whitepaper')}
                                >
                                    <IconImage src={currentIcon.whitepaper} alt="whitepaper & F.A.Q." />
                                    <IconDescription><IconText>F.A.Q.</IconText></IconDescription>
                                </IconWrapper>
                            </a>
                        </li>

                </div>
            </Sidebar>
        </>
    );
}
