import WarTributeAbi from '../ABI/ABIWarTribute.json';
import { handlePermissions } from './PrimarisTokenPermissions';
import ContractsAddresses from './ContractsAdresses';

const warTributeAddress = ContractsAddresses.warTribute;
const approvalAmount = ContractsAddresses.approvalAmount;


export async function buyWarTribute(option, legionId, web3) {
    try {
  
      if (!window.ethereum) {
        console.error('MetaMask is not installed');
        return;
      }
  
      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];
  
      const isApproved = await handlePermissions(warTributeAddress, approvalAmount, web3);
      if (!isApproved) {
        console.error('Approval not granted');
        return;
      }
      console.log('Approval granted, proceeding to mint');
  
      const warTributeContract = new web3.eth.Contract(WarTributeAbi, warTributeAddress);
  
      // Estimate gas
      const gasEstimate = await warTributeContract.methods.getBless(option, legionId).estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
      
      // Obtener el gas price y aumentar en un 50%
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);
  
      // Send transaction
      const transaction = warTributeContract.methods.getBless(option, legionId)
        .send({ 
          from: account,
          gas: gasLimit,
          gasPrice: increasedGasPrice,
        });
  
      transaction
        .on('transactionHash', function(hash) {
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt) {
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
  
      const receipt = await transaction;
      console.log('The Omnilex give you bless', receipt);
      return receipt;
    } catch (error) {
      console.error('Error:', error);
      throw error; 
    }
  }