import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Web3 from 'web3';
import { getCurrentRewardBalance, getCurrentPenalty, claimFunction } from '../Handlers/RewardPoolHandler';
import styled from "styled-components";
import Forces from "./Forces";
import Legionaries from "./Subcomponents/Forces/Legionaries";
import Ships from "./Subcomponents/Forces/Ships";
import Legions from "./Subcomponents/Forces/Legions";
import CreateLegionsContainer from "./Subcomponents/Forces/LegionsSupport/CreateLegionsContainer"
import AddingTroopsContainer from "./Subcomponents/Forces/LegionsSupport/AddingTroopsContainer";
import Missions from "./Missions";
import Campaings from "./Subcomponents/Missions/Campaings";
import Ritual from "./Subcomponents/Missions/Ritual";
import WarCohort from "./Subcomponents/Missions/WarCohort";
import Lands from "./Lands"
import Energy from "./Subcomponents/Lands/Energy"
import Health from "./Subcomponents/Lands/Health"
import Market from "./Market";
import LegionariesMarket from "./Subcomponents/Marketplace/LegionariesMarket"
import SpaceshipsMarket from "./Subcomponents/Marketplace/ShipsMarket"
import LegionsMarket from "./Subcomponents/Marketplace/LegionsMarket"
import Sidebar from "./Sidebar";
import Upperbar from "./Upperbar";
import RightSideBar from "./SecundaryBar";
import Omnilex from "../Assets/omnilex.png"
import ComingSoon from "./ComingSoon";
import PrimarisFaucet from "./Faucet";
import ClaimButton_ from './Buttons/ClaimButton';
import Reward from "../Assets/Decorative/reward_.png";
import LoadingGif from "../Assets/loading.gif";
import WarningImage from "../Assets/warning1.png"; 
import Logo from "../Assets/PRIMARISMETAVERSALWARLOGO.png";

/* global BigInt */

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000000;
`;

const LoadingImg = styled.img`
  width: 10%;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 15%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 20%;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 25%;
  }
  @media (max-width: 479px) {
    width: 30%;
  }
`;

const WarningImg = styled.img`
  position: absolute;
  width: 20%;
  top: 0%;
  opacity: 90%;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 25%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 40%;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 479px) {
    width: 60%;
  }
`;

const LogoImg = styled.img`
  position: absolute;
  width: 15%;
  bottom: 10%;
  opacity: 60%;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 20%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 30%;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 35%;
  }
  @media (max-width: 479px) {
    width: 40%;
  }
`;

export default function Home() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [currentReward, setCurrentReward] = useState(0);
  const [currentPenalty, setCurrentPenalty] = useState(0);
  
  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
  
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
        } catch (error) {
          console.error('User denied account access');
        }
      } else {
        console.error('No Ethereum interface detected');
      }
    };

    loadWeb3();
  }, []);

  
  useEffect(() => {
    checkConnection();
  }, []);

  const checkConnection = async () => {
    try {
      const networkId = await window.ethereum.request({ method: 'net_version' });
      console.log('Conectado a la red Ethereum. Network ID:', networkId);
    } catch (error) {
      console.error('Error al conectar con la red Ethereum:', error);
    }
  };

  useEffect(() => {
    if(web3 && account){
    const getCurrentPlayerReward = async () => {
    const reward = await getCurrentRewardBalance(web3, account);

  
    const priceBigInt = BigInt(reward);
    const adjustedPrice = Number(priceBigInt) / 1e18;
    const formattedPrice = adjustedPrice.toFixed(2);

      setCurrentReward(formattedPrice);
    };
    getCurrentPlayerReward();
  } else {
    console.log("Loading web3")
  }
  }, [web3, account]);
  
  useEffect(() => {
    if(web3 && account){
    const getCurrentPlayerPenalty = async () => {
      const penalty = await getCurrentPenalty(web3, account);
      setCurrentPenalty(penalty);
    };
    getCurrentPlayerPenalty();
  } else {
    console.log("Loading web3")
  }
  }, [web3, account]);

  
  const handleClaimReward = async () => {
    if (!web3) {
      alert('Web3 not provided');
      return;
    }
  
    try {
      const result = await claimFunction(web3);
      if (result.success) { 
        window.location.reload();
      } else {
        console.error('Failed to mint Legionary', result.message);
        alert('Failed to mint Legionary', result.message);
      }
    } catch (error) {
      console.error('Error in the async function:', error);
    }
  };



  if (!web3) {
    
    return (
      <LoadingContainer>
        <LoadingImg src={LoadingGif} alt="Loading" />
        <WarningImg src={WarningImage} alt="Install" />
        <LogoImg src={Logo} alt="Primaris" />
      </LoadingContainer>
    );
  } else{
    return (
      
        <PageBackground>
          <Router>
            <Sidebar />
            <Upperbar />
            <RightSideBar />
              <Content>
                <Routes>
                  <Route path="/" element={<Navigate to="/Home" replace />} />
                  <Route path="/Home" element={
                    <HomeContent 
                    currentReward={currentReward}
                    currentPenalty={currentPenalty}
                    onClaimReward={handleClaimReward}
                    />}/>
                  <Route path="/forces" element={<Forces />} />
                  <Route path="/legionaries" element={<Legionaries />} />
                  <Route path="/ships" element={<Ships />} />
                  <Route path="/legions" element={<Legions />} />
                  <Route path="/createLegionsContainer" element={<CreateLegionsContainer />} />
                  <Route path="/addingTroopsContainer" element={<AddingTroopsContainer />} />
                  <Route path="/missions" element={<Missions />} />
                  <Route path="/campaings" element={<Campaings />} />
                  {/*<Route path="/ritual" element={<Ritual />} />*/}
                  {/*<Route path="/warCohort" element={<WarCohort />} />*/} 
                  <Route path="/lands" element={<Lands />} />
                  <Route path="/energy" element={<Energy />} />
                  <Route path="/health" element={<Health />} />
                  <Route path="/market" element={<Market />} />
                  <Route path="/legionariesMarket" element={<LegionariesMarket />} />
                  <Route path="/spaceshipsMarket" element={<SpaceshipsMarket />} />
                  <Route path="/legionsMarket" element={<LegionsMarket />} />
                  <Route path="/comingSoon" element={<ComingSoon />} />
                  <Route path="/faucet" element={<PrimarisFaucet />} />

                </Routes>
              </Content>
          </Router>
        </PageBackground>
    );}
}

function HomeContent({ currentReward, currentPenalty, onClaimReward }) {
  return (
    <OmnilexContainer>
      <OmnilexImgContainer>
        <OmnilexImg src={Omnilex} alt="Omnilex" />
      </OmnilexImgContainer>
      <ClaimButtonCont>
        <ClaimButton_ onClick={onClaimReward} />
      </ClaimButtonCont>
      <RewardInfoContainer>
        <RewardInfoPanel src={Reward} alt="reward" />
        <CurrentRewardAmount>
          Current Reward: {currentReward.toString()}
        </CurrentRewardAmount>
        <CurrentPenaltyFee>
          Current Penalty: {currentPenalty.toString()} %
        </CurrentPenaltyFee>
      </RewardInfoContainer>
    </OmnilexContainer>
  );
}

const PageBackground = styled.div`
  background-color: #0a0a0a;
  width: 100vw;
  height: 100vh;
  display: flex;
  object-fit: cover;
  align-items: center;
}
@media screen and (max-width: 768px) {
  width: 100vw;
  height: 100vh;
}
`;

const OmnilexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
  padding: 20px;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 12%;
    width: 94%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 16%;
    width: 94%;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    margin-top: 16%;
    width: 94%;
  }

  @media (max-width: 479px) {
    width: 90%;
    margin-top: 30%;
    margin-bottom: 10%;
  }
`;

const OmnilexImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  width: 40%;
  position: relative;
  padding: 0;
    @media (min-width: 1200px) and (max-width: 1400px) {
    width: 45%;
    margin-top: 2%
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 40%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 50%;

  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 70%;

  }

  @media (max-width: 479px) {
    width: 100%;
    margin-top: 30%;
    margin-bottom: 10%;
  }
`;

const OmnilexImg = styled.img`
    max-width: 80%;  
    max-height: 80%;
    position: relative; 
`;

// REWARD INFO STYLES *****************************************************

const RewardInfoContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 25%;

  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 30%;
    bottom: 1%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 30%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 35%;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 50%;
    bottom: 5%;
  }

  @media (max-width: 479px) {
    width: 70%;
    margin-top: 15%;
  }
`;

const RewardInfoPanel = styled.img`
  width: 100%;
  height: auto;
  opacity: 0.9;
`;

const CurrentRewardAmount = styled.div`
    width: 60%;
    left: 20%;
    top: 30%;
    position: absolute;
    font-weight: 600;
    font-size: 16px;
    color: rgba(255,255,255,0.8);
    letter-spacing: 1px;
  @media (min-width: 1200px) and (max-width: 1400px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    font-size: 14px;
  }

  @media (max-width: 479px) {
    font-size: 12px;
  }
`;

const CurrentPenaltyFee = styled.div`
    width: 60%;
    left: 20%;
    top: 55%;
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255,255,255,0.8);
    letter-spacing: 1px;
  @media (min-width: 1200px) and (max-width: 1400px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    font-size: 14px;
  }

  @media (max-width: 479px) {
    font-size: 12px;
  }

`;

 /* background-color: #232835;*/

const Content = styled.div`
  flex: 1;
  margin: 0.05rem;
`;

const ClaimButtonCont = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40%;
  display: flex;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 30%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 25%;
    bottom: 0%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 30%;
    bottom: 2%;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 30%;
  }

  @media (max-width: 479px) {
    
  }
`;
