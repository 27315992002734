import React from 'react';
import { useWeb3 } from '../Handlers/ChainContext';
import styled from "styled-components";
import { getPrimarisHandler } from '../Handlers/FaucetHandler';
import ContractsAddresses from '../Handlers/ContractsAdresses';
import { FaucetButton } from './Buttons/SecundaryButton';
import PrimarisToken from '../Assets/Decorative/PrimarisLogo.png';

/* global BigInt */

const contractLegionsAdd = ContractsAddresses.legion;

const PrimarisFaucet = () => {
  const { web3, account } = useWeb3(); 

  const getPrimarisFunction = () => {
    getPrimarisHandler(web3);
  };
  

  return (
    <PrincipalContainer>
        <OmnilexContainer>
            <OmnilexImg src={PrimarisToken} alt="Primaris" />
        </OmnilexContainer>
        <PanelCell>
            <FaucetButton onClick={() => getPrimarisFunction()} />
            <ChainInfo>{"0xA60f5db0bB6beBBDA0de4BcB477A1d3A09125d77"}</ChainInfo>
        </PanelCell>
    </PrincipalContainer>
  );
};

export default PrimarisFaucet;

const PrincipalContainer = styled.div`
    display: flex;
    flex-direction: column;  /* Asegura que los elementos estén uno debajo del otro */
    justify-content: center;
    align-items: center;
    margin-top: calc(1% + 80px);
    flex: 1;
    margin-left: 1rem;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }
`;

const OmnilexContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #8D51CF;
    border-radius: 10px;
    height: auto;
    width: 20%;  
    margin-bottom: 100px;  /* Agrega espacio entre OmnilexContainer y PanelCell */
`;

const PanelCell = styled.div`
  background-color: #0c0d10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 5px 60px #8D51CF;
  width: 25%;
  z-index: 2;

  @media screen and (max-width: 1280px) {
    height: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: 15%;
    padding: 1rem;
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    width: 50%;
    height: 15%;
    padding: 1rem;
    font-size: 10px;
  }
`;

const OmnilexImg = styled.img`
    max-width: 100%;  
    max-height: 100%;  
`;

const ChainInfo = styled.div`
    color: #8D51CF;
    font-size: 100%;
    background-color: #262426;
    border-radius: 10px;
    width: 90%;
    padding: 10px; /* Añade padding para que el texto no toque los bordes */
    
    /* Controla el ajuste del texto */
    word-wrap: break-word; /* Permite que el texto largo se divida en varias líneas */
    overflow-wrap: break-word; /* Evita que las palabras largas desborden el contenedor */
    text-align: left; /* Asegura que el texto esté alineado a la izquierda, puedes cambiarlo según necesites */
`;

