import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MenuContainer>
      <MenuToggle onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </MenuToggle>
      {isOpen && (
        <MenuList>
          <StyledLink to="/forces" onClick={toggleMenu}>
            <MenuItem>Forces</MenuItem>
          </StyledLink>
          <StyledLink to="/missions" onClick={toggleMenu}>
            <MenuItem>Missions</MenuItem>
          </StyledLink>
          <StyledLink to="/lands" onClick={toggleMenu}>
            <MenuItem>Lands</MenuItem>
          </StyledLink>
          <StyledLink to="/ComingSoon" onClick={toggleMenu}>
            <MenuItem>Trade Station</MenuItem>
          </StyledLink>
          <StyledLink to="/Faucet" onClick={toggleMenu}>
            <MenuItem>Get Test $PRIMARIS</MenuItem>
          </StyledLink>
        </MenuList>
      )}
    </MenuContainer>
  );
};

export default MobileMenu;

// Styled Components

const MenuContainer = styled.div`
  display: none;
  @media (min-width: 768px) and (max-width: 991px) {
      display: flex;
      width: 30%;
      height: auto;
      padding-right: 40px;
  }
  @media (min-width: 480px) and (max-width: 769px) {
      display: flex;
      width: 30%;
      height: auto;
  }

  @media (max-width: 479px) {
      display: flex;
      width: 40%;
      height: auto;
  }
`;

const MenuToggle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  width: 100%;
  height: 30px;
  span {
    display: block;
    width: 80%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 1px 2px rgba(141, 81, 207, 1);
    margin-bottom: 5px;
  }
  span:last-child {
    margin-bottom: 0;
  }
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #333;
  position: absolute;
  z-index: 50;
  top: 100%;
  right: 0;
  width: 92vw;
  border-radius: 5px;
  overflow: hidden;
`;

const MenuItem = styled.li`
  display: block;
  padding: 15px;
  color: #fff;
  text-decoration: none;
  background-color: #333;
  border-bottom: 1px solid #444;
  &:hover {
    background-color: #444;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

