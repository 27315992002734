import React, { createContext, useState, useEffect } from 'react';
import Web3 from 'web3';

const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {

        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        try {
          // METAMASK ACCESS REQUEST*****************************************************
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]); 

          window.ethereum.on('accountsChanged', (accounts) => {
            if (accounts.length > 0) {
              setAccount(accounts[0]);
              window.location.reload();
            } else {
              console.warn('No accounts found');
            }
          });

        } catch (error) {
          console.error('User denied account access or another error occurred:', error);
        }
      } else {
        console.error('MetaMask not detected. Please install MetaMask to use this application.');
      }
    };

    loadWeb3();
  }, []);

  return (
    <Web3Context.Provider value={{ web3, account }}>
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3 = () => {
  return React.useContext(Web3Context);
};

export default Web3Context;
