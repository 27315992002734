import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import styled from "styled-components";
import astromancer from '../../../Assets/astromant.jpeg';

const Ritual = () => {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState('');
  
    useEffect(() => {
      const loadWeb3 = async () => {
        if (window.ethereum) {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
  
          try {
            // Request account access if needed
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            // Accounts now exposed
            const accounts = await web3Instance.eth.getAccounts();
            setAccount(accounts[0]);
          } catch (error) {
            console.error('User denied account access');
          }
        } else {
          console.error('No Ethereum interface detected');
        }
      };
  
      loadWeb3();
    }, []);
  
      return (
        <PrincipalContainer>
            <PanelContainer>ASTROMANCERS RITUAL</PanelContainer>
    
          {/* Scrollable NFT container */}
          <ScrollableContainer>
          

              <Container>
                <Img src={astromancer} alt='astromancer' />
                <Name>Reveal the chooseone</Name>
                <Address>asdasdaffa</Address>
                <RemainingT>Remaining Time</RemainingT>
                <CounterT>00:00:00</CounterT>

              </Container>
        
          </ScrollableContainer>
        </PrincipalContainer>
      );
    };

export default Ritual;

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    margin-left: 1rem;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

const PanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(225,225,225, 0.9);
  background-color: rgba(0,0,0, 0.7);
  border-radius: 60px;
  font-size: 3rem;
  min-height: 6rem;
  margin: 0 auto;
  width: 30%;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:before, &:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  
  &:before {
    bottom: 100%;
    border-bottom: 15px solid rgba(0,0,0, 0.7);
  }

  &:after {
    top: 100%;
    border-top: 15px solid rgba(0,0,0, 0.7);
  }

  @media screen and (max-width: 1280px) {
    transform: translateY(18%);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const ScrollableContainer = styled.div`
    overflow-y: auto;
    margin-top: 0.5%;
    width: 100%;
    height: 72vh;
    max-height: 74vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*Scrollbar*/
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
    @media screen and (max-width: 1280px) {
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      width: 100%;
      margin-top: 13.5vh;
      max-height: 86.5vh;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 17vh;
      max-height: 83vh;
    }
    @media screen and (max-width: 426px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      max-height: 95vh;
      transform: translateY(11%);
      margin-top: 15vw;
    }
  `;

const Container = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 40rem;
    width: 50%;
    margin: 1rem;
    position: relative;
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
    }
    @media screen and (max-width: 426px)
      margin:0;
      } 
    }
`;

const Img = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
`;

const Name = styled.p`
    position: absolute;
    top: 81%; 
    left: 0; 
    padding: 5px 20px 3px 8px;
    color: white;
    font-size: 1.7rem;
    font-weight: normal; 
    background-color: rgba(255,255,255,0.1); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const Address = styled.p`
    position: absolute;
    top: 89%; 
    left: 0; 
    padding: 5px 20px 3px 8px;
    color: white;
    font-size: 1.5rem;
    font-weight: normal; 
    background-color: rgba(0,0,0,0.5); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const RemainingT = styled.p`
    position: absolute;
    top: 81%; 
    right: 0%; 
    padding: 5px 20px 3px 8px;
    color: white;
    font-size: 1.7rem;
    font-weight: normal; 
    background-color: rgba(255,255,255,0.1); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const CounterT = styled.p`
    position: absolute;
    top: 89%; 
    right: 0; 
    padding: 5px 20px 3px 8px;
    color: white;
    font-size: 1.5rem;
    font-weight: normal; 
    background-color: rgba(0,0,0,0.5); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;