import React from "react";
import styled from "styled-components";
import Button from "./Button";
import logo from "../Assets/PRIMARISMETAVERSALWARLOGO.png";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "./Mobilemenu";

export default function Upperbar() {

    const location = useLocation();

    const getSectionName = (path) => {
        switch (path) {
            case "/Home":
                return "Home";
            case "/forces":
                return "Forces";
            case "/Legionaries":
                return "Legionaries";
            case "/Ships":
                return "Spaceships";
            case "/Legions":
                return "Legions";
            case "/CreateLegionsContainer":
                return "Create Legions";
            case "/AddingTroopsContainer":
                return "Adding Troops";
            case "/missions":
                return "Missions";
            case "/Campaings":
                return "Campaigns";
            case "/Ritual":
                return "Astromancers Ritual";
            case "/WarCohort":
                return "War Cohort";
            case "/lands":
                return "Lands";
            case "/Energy":
                return "Fill Energy";
            case "/Health":
                return "Health Troops";
            case "/market":
                return "Trade Station";
            case "/LegionariesMarket":
                return "Trading Legionaries";
            case "/SpaceshipsMarket":
                return "Trading Spaceships";
            case "/LegionsMarket":
                return "Trading Legions";
            case "/landsMarket":
                return "Trading Lands";
            case "/ComingSoon":
                return "Coming Soon...";
            default:
                return "Home";
        }
    };

    const sectionName = getSectionName(location.pathname);

    return (
        <Nav>
            <SectionNameContainer>
                <SectionName>{sectionName}</SectionName>
            </SectionNameContainer>
            <LogoContainer>
                <Link to="/Home">
                    <Logo src={logo} alt="Primaris | Metaversalwar" />
                </Link>
            </LogoContainer>
            <ButtonContainer>
                <Button />
                <SectionName_>{sectionName}</SectionName_>
                <MobileMenuContainer><MobileMenu /></MobileMenuContainer>
            </ButtonContainer>
        </Nav>
    );
}

// NAVBAR *******************************************************

const Nav = styled.header`
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    background-color: #0b0d10;
    padding: 0.5rem;
    z-index: 11;
    box-shadow: 0px 0px 50px rgba(225, 225, 225, 0.2);
    @media (min-width: 1200px) and (max-width: 1400px) {

    }

    @media (min-width: 992px) and (max-width: 1199px) {

    }

    @media (min-width: 768px) and (max-width: 991px) {
        flex-direction: column; 
        align-items: center;
    }

    @media (min-width: 480px) and (max-width: 767px) {
        flex-direction: column; 
        align-items: center;
    }

    @media (max-width: 479px) {
        flex-direction: column; 
        align-items: center;
    }
`;

// LOGO *******************************************************

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    @media (min-width: 1200px) and (max-width: 1400px) {

    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 40%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        width: 60%;
    }

    @media (min-width: 480px) and (max-width: 767px) {
        width: 65%;
    }

    @media (max-width: 479px) {
        width: 70%;
    }
`;

const Logo = styled.img`
    max-width: 100%; 
    height: auto;
`;

// METAMASK BUTTON *******************************************************

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 33.33%;
    @media (min-width: 1200px) and (max-width: 1400px) {

    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 30%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        width: 98%;
    }

    @media (min-width: 480px) and (max-width: 767px) {
        width: 90%;
    }

    @media (max-width: 479px) {
        width: 90%;
    }
    
`;

// SECTION NAME *******************************************************

const SectionNameContainer = styled.div`
    align-items: left;
    display: flex;
    width: 33.33%;
    @media (min-width: 1200px) and (max-width: 1400px) {

    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 30%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        display: none;
    }

    @media (min-width: 480px) and (max-width: 767px) {
        display: none;
    }

    @media (max-width: 479px) {
        display: none;
    }
`;

const SectionName = styled.div`
    font-size: 2rem;
    margin-left: 10%;
    color: rgba(225, 225, 225, 1);
    flex: 1;
    text-align: left;
    @media (min-width: 1200px) and (max-width: 1400px) {
    
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      
    }
`;

const SectionName_ = styled.div`
    display: none;
    
    @media (min-width: 768px) and (max-width: 991px) {
        display: flex;
        font-size: 1.5rem;
        color: rgba(225,225,225,1);
        text-align: center;
        justify-content: center;
        width: 30%;
    }

    @media (min-width: 480px) and (max-width: 767px) {
        display: flex;
        font-size: 1.5rem;
        color: rgba(225,225,225,1);
        text-align: center;
        justify-content: center;
        width: 30%;
    }

    @media (max-width: 479px) {
        display: flex;
        font-size: 1.5rem;
        color: rgba(225,225,225,1);
        text-align: center;
        justify-content: center;
        width: 30%;
    }

`;

// MOBILE MENU *******************************************************

const MobileMenuContainer = styled.div`
    display: none;
    @media (min-width: 768px) and (max-width: 991px) {
        display: flex;
        width: 20%;
        height: auto;
        justify-content: right;
    }

    @media (min-width: 480px) and (max-width: 769px) {
        display: flex;
        width: 20%;
        height: auto;
        justify-content: right;
    }

    @media (max-width: 479px) {
        display: flex;
        width: 20%;
        height: auto;
        justify-content: right;
    }
`;
