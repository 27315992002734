import ContractsAddresses from "./ContractsAdresses";
const { Web3 } = require("web3");

const web3 = new Web3(
  new Web3.providers.HttpProvider(
    "https://polygon-amoy.infura.io/v3/305a790b14dc4686906de7796f9dff0b",
  ),
);

const balanceOfABI = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

let currentWallet = null;

async function getCurrentWallet() {
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        return accounts[0];
    } else {
        throw new Error('MetaMask not detected. Please make sure MetaMask is installed and unlocked.');
    }
}

async function init() {
    try {
        currentWallet = await getCurrentWallet();
        console.log('Dirección de la billetera actual:', currentWallet);

        window.ethereum.on('accountsChanged', function(accounts) {
            const updatedWallet = accounts[0];
            console.log('Se cambió a la billetera:', updatedWallet);
            currentWallet = updatedWallet;
        });
    } catch (error) {
        console.error('Error:', error.message);
    }
}

const tokenContract = ContractsAddresses.primarisToken;

const contract = new web3.eth.Contract(balanceOfABI, tokenContract);

export async function getTokenBalance() {
  const tokenHolder = await getCurrentWallet(); // Obtiene la dirección de la billetera actual en cada llamada
  const result = await contract.methods.balanceOf(tokenHolder).call();
  const formattedResult = web3.utils.fromWei(result, "ether");
  console.log(formattedResult);
  return formattedResult;
}

init(); // Inicializa la billetera actual cuando se carga el script
