import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Forces from "../Assets/Images/Icons/Sidebar/Forces__.png";
import Forces_ from "../Assets/Images/Icons/Sidebar/Forces___.png";
import Mission from "../Assets/Images/Icons/Sidebar/Missions__.png";
import Mission_ from "../Assets/Images/Icons/Sidebar/Missions___.png";
import Lands from "../Assets/Images/Icons/Sidebar/Lands__.png";
import Lands_ from "../Assets/Images/Icons/Sidebar/Lands___.png";
import Trade from "../Assets/Images/Icons/Sidebar/Trade__.png";
import Trade_ from "../Assets/Images/Icons/Sidebar/Trade___.png";
import LogoImp from "../Assets/Images/logoimperial.png";

const IconImage = styled.img`
    width: 90%;
    margin-top: 20%;
    height: auto;
    z-index: 1;
    @media screen and (max-width: 1280px) {
        margin-top:5%;
        width: 60%;
    }
`;

const IconDescription = styled.label`
    width: 28vh;
    height: 6.5vh;
    top: 0;
    left: 0;
    text-align: center;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    position: absolute;
    z-index: 0;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(-10px);
    border-right: 0.5px solid #FF5733;
    
    @media screen and (max-width: 1280px) {
        width: 32vh;
    }
`;


const IconText = styled.span`
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.95);
    line-height: 7vh;
    margin-left: 20%;

    @media screen and (max-width: 1280px) {
        font-size: 1rem;
    }
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover ${IconImage} {
        transform: scale(1.2);
    }
    &:hover ${IconDescription} {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }
`;

const Sidebar = styled.nav`
    position: fixed;
    top: 15%;
    left: 1%;
    height: 70%;
    width: 100px;
    background-color: #0c0d10; 
    color: white;
    box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8); /*rgba(16, 123, 179, 0.8);*/
    border: 1px solid rgba(16, 123, 179, 1);
    padding-top: 20px; 
    border-radius: 10px;
    z-index: 7;

    .links { 
        list-style-type: none;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 4rem; 
        margin-top: 80%;
        justify-content: center;
        align-items: center;

        li {
            width: 50%; /* Ancho del icono */
            height: 50% /* Alto del icono */
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
        
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        top: 17%;
        left: 2.5%;
        height: 70%;
        width: 7vw;
        padding-top: 20px;
        .links { 
            gap: 2rem;
            transform: translateY(5%);
        }
    }
            
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        top: 17%;
        height: auto;
        width: 7%;
        left: 2%;
        background-color: #0c0d10; 
        color: white;
        padding-top: 20px;
        padding-bottom: 60px; 

        .links { 
            gap: 2rem; 
            margin-top: 15px;
            justify-content: center;
            align-items: center;

            li {
                width: 50px; 
                height: 50px; 
                display: flex;
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        display: none;
    }

    @media screen and (min-width: 480px) and (max-width: 767px) {
        display: none;
    }

    @media screen and (max-width: 479px) {
        display: none;
    }
`;


const LogoHuman = styled.img`
    width: 100%;
    height: auto;
    margin: auto;
    display: block;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export default function LeftSideBar() {
    const [currentIcon, setCurrentIcon] = useState({
        forces: Forces,
        campaigns: Mission,
        lands: Lands,
        trade: Trade
    });

    const handleMouseEnter = (iconName) => {
        setCurrentIcon((prevIcons) => ({
            ...prevIcons,
            [iconName]: {
                forces: Forces_,
                campaigns: Mission_,
                lands: Lands_,
                trade: Trade_
            }[iconName]
        }));
    };

    const handleMouseLeave = (iconName) => {
        setCurrentIcon((prevIcons) => ({
            ...prevIcons,
            [iconName]: {
                forces: Forces,
                campaigns: Mission,
                lands: Lands,
                trade: Trade
            }[iconName]
        }));
    };

    return (
        <Sidebar>
            <LogoHuman src={LogoImp} alt="Estandarte" />
            <div className="links">
                <li>
                    <Link to="/forces">
                        <IconWrapper
                            onMouseEnter={() => handleMouseEnter('forces')}
                            onMouseLeave={() => handleMouseLeave('forces')}
                        >
                            <IconImage src={currentIcon.forces} alt="forces" />
                            <IconDescription><IconText>Forces</IconText></IconDescription>
                        </IconWrapper>
                    </Link>
                </li>
                <li>
                    <Link to="/missions">
                        <IconWrapper
                            onMouseEnter={() => handleMouseEnter('campaigns')}
                            onMouseLeave={() => handleMouseLeave('campaigns')}
                        >
                            <IconImage src={currentIcon.campaigns} alt="Missions" />
                            <IconDescription><IconText>Missions</IconText></IconDescription>
                        </IconWrapper>
                    </Link>
                </li>
                <li>
                    <Link to="/lands">
                        <IconWrapper
                            onMouseEnter={() => handleMouseEnter('lands')}
                            onMouseLeave={() => handleMouseLeave('lands')}
                        >
                            <IconImage src={currentIcon.lands} alt="Lands" />
                            <IconDescription><IconText>Lands</IconText></IconDescription>
                        </IconWrapper>
                    </Link>
                </li>
                <li>
                    <Link to="/ComingSoon">
                        <IconWrapper
                            onMouseEnter={() => handleMouseEnter('trade')}
                            onMouseLeave={() => handleMouseLeave('trade')}
                        >
                            <IconImage src={currentIcon.trade} alt="Trade Station" />
                            <IconDescription><IconText>Trade Station</IconText></IconDescription>
                        </IconWrapper>
                    </Link>
                </li>
            </div>
        </Sidebar>
    );
}
