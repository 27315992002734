const GetName = (ref) => {
    return name[ref];
  };
  const name = {
    0: "Emily",
    1: "Sophia",
    2: "Olivia",
    3: "Emma",
    4: "Isabella",
    5: "Mia",
    6: "Ava",
    7: "Charlotte",
    8: "Amelia",
    9: "Evelyn",
    10: "Harper",
    11: "Liam",
    12: "Noah",
    13: "William",
    14: "James",
    15: "Oliver",
    16: "Benjamin",
    17: "Elijah",
    18: "Lucas",
    19: "Mason",
    20: "Alexander",
    21: "Ethan",
    22: "Michael",
    23: "Daniel",
    24: "Matthew",
    25: "Henry",
    26: "Sebastian",
    27: "Jack",
    28: "Jacob",
    29: "Aiden",
    30: "Alamex",
    31: "Grace",
    32: "Lily",
    33: "Hannah",
    34: "Ella",
    35: "Chloe",
    36: "Zoe",
    37: "Penelope",
    38: "Nora",
    39: "Eleanor",
    40: "Scarlett",
    41: "Ruby",
    42: "Nikolai",
    43: "Ivan",
    44: "Dmitri",
    45: "Svetlana",
    46: "Anastasia",
    47: "Yelena",
    48: "Vladimir",
    49: "Tatiana",
    50: "Yakov",
    51: "Elena",
    52: "Sasha",
    53: "Irina",
    54: "Nadia",
    55: "Yuri",
    56: "Mikhail",
    57: "Sergei",
    58: "Alexei",
    59: "Anna",
    60: "Katerina",
    61: "Olga",
    62: "Sofia",
    63: "Vera",
    64: "Ekaterina",
    65: "Pavel",
    66: "Maxim",
    67: "Ivan",
    68: "Alexandr",
    69: "Viktor",
    70: "Leonid",
    71: "Yevgeny",
    72: "Yakov",
    73: "Andrei",
    74: "Sergei",
    75: "Artyom",
    76: "Dmitri",
    77: "Igor",
    78: "Konstantin",
    79: "Nikita",
    80: "Aleksandr",
    81: "Ivan",
    82: "Mikhail",
    83: "Nikolai",
    84: "Yuri",
    85: "Oleg",
    86: "Anton",
    87: "Vladislav",
    88: "Boris",
    89: "Stanislav",
    90: "Anatoly",
    91: "Fyodor",
    92: "Vasily",
    93: "Pavel",
    94: "Nikita",
    95: "Yegor",
    96: "Sergei",
    97: "Andrei",
    98: "Ivar",
    99: "Bjorn",
    100: "Siggurd",
    101: "Kwame",
    102: "Aisha",
    103: "Musa",
    104: "Fatima",
    105: "Kwasi",
    106: "Zahara",
    107: "Sekou",
    108: "Jamila",
    109: "Mamadou",
    110: "Nala",
    111: "Moussa",
    112: "Amina",
    113: "Tariq",
    114: "Sanaa",
    115: "Osei",
    116: "Layla",
    117: "Kwesi",
    118: "Zuri",
    119: "Kofi",
    120: "Ayana",
    121: "Nuru",
    122: "Imani",
    123: "Malik",
    124: "Laila",
    125: "Jabari",
    126: "Nia",
    127: "Kwabena",
    128: "Zola",
    129: "Kwaku",
    130: "Kaya",
    131: "Erik",
    132: "Olaf",
    133: "Lars",
    134: "Sven",
    135: "Igrid",
    136: "Gunnar",
    137: "Magnus",
    138: "Nils",
    139: "Stefan",
    140: "Kjell",
    141: "Sigmund",
    142: "Ragnar",
    143: "Vidar",
    144: "Hans",
    145: "Anders",
    146: "Torsten",
    147: "Johan",
    148: "Oskar",
    149: "Gustav",
    150: "Julius",
    151: "Cornelia",
    152: "Marcus",
    153: "Livia",
    154: "Gaius",
    155: "Aurelia",
    156: "Lucius",
    157: "Octavia",
    158: "Quintus",
    159: "Claudia",
    160: "Titus",
    161: "Valeria",
    162: "Publius",
    163: "Pompeia",
    164: "Caius",
    165: "Fulvia",
    166: "Aulus",
    167: "Julia",
    168: "Nero",
    169: "Helena",
    170: "Maximus",
    171: "Agrippina",
    172: "Tiberius",
    173: "Camilla",
    174: "Decimus",
    175: "Lucretia",
    176: "Flavius",
    177: "Laelia",
    178: "Sextus",
    179: "Claudius",
    180: "Marcellus",
    181: "Sulpicia",
    182: "Gnaeus",
    183: "Aelia",
    184: "Cassius",
    185: "Antonia",
    186: "Caesar",
    187: "Jacob",
    188: "Michael",
    189: "Ethan",
    190: "Matthew",
    191: "Daniel",
    192: "Alexander",
    193: "William",
    194: "James",
    195: "Joseph",
    196: "David",
    197: "Benjamin",
    198: "John",
    199: "Ryan",
    200: "Christopher",
    201: "Andrew",
    202: "Anthony",
    203: "Nicholas",
    204: "Tyler",
    205: "Brandon",
    206: "Jonathan",
    207: "Samuel",
    208: "Christian",
    209: "Nathan",
    210: "Logan",
    211: "Zachary",
    212: "Kevin",
    213: "Austin",
    214: "Dylan",
    215: "Justin",
    216: "Cameron",
    217: "Jordan",
    218: "Jose",
    219: "Kyle",
    220: "Jason",
    221: "Black",
    222: "Caleb",
    223: "Liam",
    224: "Mason",
    225: "Aiden",
    226: "Jayden",
    227: "Connor",
    228: "Hunter",
    229: "Jackson",
    230: "Gavin",
    231: "Giovanny",
    232: "Carlos",
    233: "Nelson",
    234: "Manuel",
    235: "Luis",
    236: "Javier",
    237: "Miguel",
    238: "Francisco",
    239: "Natalio",
    240: "Pedro",
    241: "Alejandro",
    242: "Jorge",
    243: "Diego",
    244: "Alberto",
    245: "Ricardo",
    246: "Santiago",
    247: "Hugo",
    248: "Percy",
    249: "Pablo",
    250: "Raúl",
    251: "Rubén",
    252: "Robinson",
    253: "Gonzalo",
    254: "Adrián",
    255: "Víctor",
  };
  
  export { GetName, name };