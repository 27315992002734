import React, { useState, useEffect } from 'react';
import { useWeb3 } from '../../../../Handlers/ChainContext';
import { useLocation } from "react-router-dom";
import { GetName } from '../LegionariesNames/Names';
import { GetLastName } from '../LegionariesNames/LastNames';
import { useSelectedTokens } from '../../../../Handlers/NftsContext';
import { addToLegion } from '../../../../Handlers/NftsContractsHandler';
import { MintLegion_ }from '../../../Buttons/MintLegionButton';
import styled from 'styled-components';
import ContractsAddresses from '../../../../Handlers/ContractsAdresses';
import legionariesAbi from '../../../../ABI/ABILegionaries.json';
import spaceshipsAbi from '../../../../ABI/ABISpaceships.json';
import R1rank from '../../../../Assets/Images/NFT/Legionaries/R1rank.png';
import R2rank from '../../../../Assets/Images/NFT/Legionaries/R2rank.png';
import R3rank from '../../../../Assets/Images/NFT/Legionaries/R3rank.png';
import R4rank from '../../../../Assets/Images/NFT/Legionaries/R4rank.png';
import R5rank from '../../../../Assets/Images/NFT/Legionaries/R5rank.png';
import axionisLogo from '../../../../Assets/Images/NFT/Legions/Axionis/AxionisLogo.png';
import eternisLogo from '../../../../Assets/Images/NFT/Legions/Eternis/EternisLogo.png';
import primusLogo from '../../../../Assets/Images/NFT/Legions/PrimusVU/PrimusVULogo.png';

const contractLegionariesAdd = ContractsAddresses.legionary;
const contractSpaceshipsAdd = ContractsAddresses.spaceship;
const maxSpaceship = 10;

const AddingTroopsManager = ({ legion: propToken }) => {
  const location = useLocation();
  const { web3, account } = useWeb3(); 
  const { legion: stateToken } = location.state || {};
  const legion = stateToken || propToken;
  const [legionaryAttributes, setLegionaryAttributes] = useState([]);
  const [spaceshipAttributes, setSpaceshipAttributes] = useState([]);

  const { legionariesTokenIds, 
          spaceshipsTokenIds, 
          removeLegionaryToken, 
          removeSpaceshipToken} = useSelectedTokens();

  // LOAD TOKENS IN MANAGER CONTAINER ******************************************************************

  useEffect(() => {
    const loadLegionaryTokens = async () => {
      if (web3 && account) {
        const contract = new web3.eth.Contract(legionariesAbi, contractLegionariesAdd);
        try {
          const attributesL = [];
          for (let i = 0; i < legionariesTokenIds.length; i++) {
            const tokenId = legionariesTokenIds[i];
            const token = await contract.methods.getLegionaryAttributes(tokenId).call();
            attributesL.push({
              legionaryId_: token[0] !== undefined ? token[0] : "",
              legionaryName_: token[1] !== undefined ? token[1] : "",
              lastName_: token[2] !== undefined ? token[2] : "",
              rank_: token[3] !== undefined ? token[3] : "",
              miningPower_: token[4] !== undefined ? token[4] : "",
              combatPower_: token[5] !== undefined ? token[5] : "",
              stamina_: token[6] !== undefined ? token[6] : "",
              tokenId_: token[7] !== undefined ? token[7] : "",
            });
          }
          setLegionaryAttributes(attributesL);
        } catch (error) {
          console.error('Error fetching spaceship tokens:', error);
        }
      }
    };
    
    loadLegionaryTokens();
  }, [web3, account, legionariesTokenIds]);

  useEffect(() => {
    const loadSpaceshipTokens = async () => {
      if (web3 && account) {
        const contract = new web3.eth.Contract(spaceshipsAbi, contractSpaceshipsAdd);
        try {
          const attributesS = [];
          for (let i = 0; i < spaceshipsTokenIds.length; i++) {
            const tokenId = spaceshipsTokenIds[i];
            const token = await contract.methods.getSpaceshipAttributes(tokenId).call();
            attributesS.push({
              spaceshipId_: token[0] !== undefined ? token[0] : "",
              spaceshipName_: token[1] !== undefined ? token[1] : "",
              spaceshipReg_: token[2] !== undefined ? token[2] : "",
              capacity_: token[3] !== undefined ? token[3] : "",
              combatSPower_: token[4] !== undefined ? token[4] : "",
              operability_: token[5] !== undefined ? token[5] : "",
              tokenId_: token[6] !== undefined ? token[6] : "",
            });
          }
          setSpaceshipAttributes(attributesS);
        } catch (error) {
          console.error('Error fetching spaceship tokens:', error);
        }
      }
    };

    loadSpaceshipTokens();
  }, [web3, account, spaceshipsTokenIds]);

  // HANDLE REMOVE NFT ******************************************************
  
  const handleRemoveLegionary = (tokenId) => {
    removeLegionaryToken(tokenId);
  };

  const handleRemoveSpaceship = (tokenId) => {
    removeSpaceshipToken(tokenId);
  };

  // CALL TO "ADD TO LEGION" FUNCTION ****************************************

  const handleAddToLegion = async (legionId) => {
    try {
      const result = await addToLegion(legionId, legionariesTokenIds, spaceshipsTokenIds, web3);
  
      if (result.success) { 
        window.location.reload();
      } else {
        console.error('Failed to add to legion:', result.message);
      }
    } catch (error) {
      console.error('Error in the async function:', error);
    }
  };

  // CALCULATING ATTRIBUTES **************************************************

  const calculateTotalCapacity = () => {
    const activeSpaceshipCapacity = spaceshipAttributes.reduce((total, token) => total + parseInt(token.capacity_), 0);
    return activeSpaceshipCapacity;
  };

  const calculateTotalMiningPower = () => {
    const activeLegionaryMiningPower = legionaryAttributes.reduce((total, token) => total + parseInt(token.miningPower_), 0);
    return activeLegionaryMiningPower;
  };
  
  const calculateTotalCombatPower = () => {
    const activeLegionaryCombatPower = legionaryAttributes.reduce((total, token) => total + parseInt(token.combatPower_), 0);
    const activeSpaceshipCombatPower = spaceshipAttributes.reduce((total, token) => total + parseInt(token.combatSPower_), 0);
    return activeLegionaryCombatPower + activeSpaceshipCombatPower;
  };

  // RENDERING FUNCTIONS *****************************************************

  const renderRankName = (rankOrCapacity, isLegionary) => {
    const rankNames = {
      legionaries: { "1": "Hastatus", "2": "Opitionis", "3": "Decurian", "4": "Centurian", "5": "Praetorian" },
      spaceships: { "1": "Banshee", "2": "Fighter", "3": "Vessel", "4": "Hunter", "5": "Corvette" },
    };

    const rankName = isLegionary ? rankNames.legionaries[rankOrCapacity] : rankNames.spaceships[rankOrCapacity];

    return <Rank>{rankName}</Rank>;
  };

  const renderRankImage = (rankOrCapacity, isLegionary) => {
    const rankImages = { "1": R1rank, "2": R2rank, "3": R3rank, "4": R4rank, "5": R5rank };

    return isLegionary ? <RankImg src={rankImages[rankOrCapacity]} alt="Rank Image" /> : <RankImg src={rankImages[rankOrCapacity]} alt="Rank Image" />;
  };

  const renderFlag = (warCohort) => {
    const flagImages = { "0": axionisLogo, "2": eternisLogo, "1": primusLogo };
  
    return <LegionFlagImg src={flagImages[warCohort]} alt={`Flag ${warCohort}`} />;
  };

  const renderGradeName = (grade) => {
    const gradeName = { "1": "Incipiensis", "2": "Aquiliferis", "3": "Warsteel", "4": "Desolatum", "5": "Imperial Elite" };
    return <Grade>{gradeName[grade]}</Grade>;
  };
  
  const formatAddress = (id) => {
    if (!id) return '';
    const firstFive = id.slice(0, 5);
    const lastSix = id.slice(-6);
    return `${firstFive}...${lastSix}`;
  };

  const renderLegionariesFile = () => {
    return (
      <>
        {legionaryAttributes.map((token, index) => (
          legionariesTokenIds.includes(token.tokenId_) && (
            <LegionCreateFile key={index}>
              {renderRankName(token.rank_ ? token.rank_.toString() : "1", true)}
              <RankImgContainer>{renderRankImage(token.rank_ ? token.rank_.toString() : "1", true)}</RankImgContainer>
              <UniqId>ID: {token.legionaryId_ ? formatAddress(token.legionaryId_) : "N/A"} {"  "} </UniqId>
              <Name>{GetName(token.legionaryName_)} {GetLastName(token.lastName_)}</Name>
              <MiningPower>Mining Power: {token.miningPower_ ? token.miningPower_.toString() : "N/A"}</MiningPower>
              <CombatPower>Combat Power: {token.combatPower_ ? token.combatPower_.toString() : "N/A"}</CombatPower>
              <Stamina>HP: {token.stamina_ ? token.stamina_.toString() : "N/A"}%</Stamina>
              <BackButton onClick={() => {handleRemoveLegionary(token.tokenId_)}}>x</BackButton>
            </LegionCreateFile>
          )
        ))}
      </>
    );
  };

  const renderSpaceshipsFile = () => {
    return (
      <>
        {spaceshipAttributes.map((token, index) => (
          spaceshipsTokenIds.includes(token.tokenId_) && (
            <LegionCreateFile key={index}>
              {token && (
                <>
                  {renderRankName(token.capacity_ ? token.capacity_.toString() : "1", false)}
                  <RankImgContainer>{renderRankImage(token.capacity_ ? token.capacity_.toString() : "1", false)}</RankImgContainer>
                  <UniqId>ID: {token.spaceshipId_ ? formatAddress(token.spaceshipId_) : "N/A"} {"  "} </UniqId>
                  <Name>{GetName(token.spaceshipName_)} {GetLastName(token.spaceshipReg_)}</Name>
                  <MiningPower>Capacity: {token.capacity_ ? token.capacity_.toString() : "N/A"}</MiningPower>
                  <CombatPower>Combat Power: {token.combatSPower_ ? token.combatSPower_.toString() : "N/A"}</CombatPower>
                  <Stamina>OP: {token.operability_ ? token.operability_.toString() : "N/A"}%</Stamina>
                  <BackButton onClick={() => handleRemoveSpaceship(token.tokenId_)}>x</BackButton>
                </>
              )}
            </LegionCreateFile>
          )
        ))}
      </>
    );
  };


  return (
    <LegionCreateContainer>
      <Footer>
        {legion ? (
          <>
            <FooterName>{legion.legionName}</FooterName>
            <SpaceshipsSize>Spaceships: {legion.ships.length.toString()}</SpaceshipsSize>
            <LegionariesSize>Legionaries: {legion.legionaries.length.toString()}</LegionariesSize>
            {renderFlag(legion.warCohort)}
            {renderGradeName(legion.grade)}
            <LegionCapacity>Legion Capacity: <span style={{ fontSize: '1rem', color: '#8D51CF' }}>{legion.totalCapacity.toString()}</span></LegionCapacity>
            <VerticalDivider/>
            <LegionMiningPower>Mining Power: <span style={{ fontSize: '1rem', color: '#8D51CF' }}>{legion.totalMiningPower.toString()}</span></LegionMiningPower>
            <LegionCombatPower>Combat Power: <span style={{ fontSize: '1rem', color: '#8D51CF' }}>{legion.totalCombatPower.toString()}</span></LegionCombatPower>
          </>
        ) : (
          <FooterName>No Legion Selected</FooterName>
        )}
      </Footer>
      <LegionData><StaticText1>Capacity:</StaticText1><DinamicText1>{(Number(legion.totalCapacity) + calculateTotalCapacity()).toString()}</DinamicText1>
                  <StaticText2>MP: </StaticText2><DinamicText2>{(Number(legion.totalMiningPower) + calculateTotalMiningPower()).toString()}</DinamicText2> 
                  <StaticText3>CP: </StaticText3><DinamicText3>{(Number(legion.totalCombatPower) + Number(calculateTotalCombatPower())).toString()}</DinamicText3></LegionData>
      <LegionCreateSubTitle>Spaceships in Legion<SubTitleCounter>{(Number(legion.ships.length) + Number(spaceshipAttributes.length)).toString()}{" / "}</SubTitleCounter><SubTitleCounterLimit>{maxSpaceship}</SubTitleCounterLimit></LegionCreateSubTitle>
      <LegionCreateContent>
        {renderSpaceshipsFile()}
      </LegionCreateContent>
      <LegionCreateSubTitle>Capacity Available<SubTitleCounter>{legionaryAttributes.length}{" / "}</SubTitleCounter><SubTitleCounterLimit>{(Number(legion.totalCapacity) + Number(calculateTotalCapacity()) - (Number(legion.legionaries.length))).toString()}</SubTitleCounterLimit></LegionCreateSubTitle>
      <LegionCreateContent>
        {renderLegionariesFile()}
      </LegionCreateContent>
      <CreateLegionButtonContainer>
        <MintLegion_ onClick={() => handleAddToLegion(legion.legionId)} />
      </CreateLegionButtonContainer>





    </LegionCreateContainer>

    
  );
};

export default AddingTroopsManager;

// Panel Styles

const LegionCreateContainer = styled.div`
  border-radius: 8px;
  width: 80%;
  margin-left:10%;
  height: 73vh;
  margin-top: 40%;
  max-height: 80vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: ${(isOpen) => (isOpen ? '0 5px 10px #107BB3' : 'none')};
  /*Scrollbar*/
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;
  @media (min-width: 1200px) and (max-width: 1400px) {
    margin-top: 30%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 28%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 50%;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    margin-top: 35%;
  }

  @media (max-width: 479px) {
    height: 80vh;
    margin-top: 45%;
  }
`;

const LegionCreateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;  
  padding: 0.5rem;
  box-sizing: border-box;
  height: 41%;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;
`;

const LegionCreateTitle = styled.div`
  font-size: 1.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  vertical-align: middle;
  width: 60%;
  height: 5%;
  border-radius: 10px;
  margin-left: 20%;
  margin-top: 1%;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {
    font-size: 1rem;
  }
`;

const LegionData = styled.div`
  position: relative;
  font-size: 1rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background-color: rgba(27, 27, 27, 1);
  color: white;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 5%;
  margin-top: 1%;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {
    font-size: 0.8rem;
  }
`;

const DinamicText1 = styled.div`
  position: absolute;
  top: 20%;
  left: 23%;
  font-size: 1rem;
  color: #107BB3;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {
    font-size: 0.8rem;
  }
`;

const DinamicText2 = styled.div`
  position: absolute;
  top: 20%;
  left: 48%;
  font-size: 1rem;
  color: #107BB3;
  justify-content: left;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {
    font-size: 0.8rem;
  }
`;

const DinamicText3 = styled.div`
  position: absolute;
  top: 20%;
  left: 82%;
  font-size: 1rem;
  color: #107BB3;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {
    font-size: 0.8rem;
  }
`;

const StaticText1 = styled.div`
  position: absolute;
  top: 20%;
  left: 3%;
`;

const StaticText2 = styled.div`
  position: absolute;
  top: 20%;
  left: 38%;
`;

const StaticText3 = styled.div`
  position: absolute;
  top: 20%;
  left: 72%;
`;

const SubTitleCounter = styled.div`
  font-size: 0.8rem;
  color: #FFFFFF;
  position: absolute;
  right: 10%;
  top: 20%;
  
`;

const SubTitleCounterLimit = styled.div`
  font-size: 0.8rem;
  color: #FFFFFF;
  position: absolute;
  right: 6%;
  top: 20%;
`;

const LegionCreateSubTitle = styled.div`
  font-size: 1rem;
  color: #107BB3;
  position: sticky;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  margin-top: 3%;
  background-color: rgba(27, 27, 27, 0.8);
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const CreateLegionButtonContainer = styled.div`
  border-top: 1px solid #107BB3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
  height: auto;
  max-height: 15%;
  width: 99%;
`;

// Nft rendering

const LegionCreateFile = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  width: 100%;
  height: 65px;
  border-radius: 0px 10px 0px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; 
  padding: 25px;
  box-sizing: border-box;
`;

const BackButton = styled.button`
    position: absolute;
    top: 50%;
    right: 3%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 40px;
    height: 20px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

const Rank = styled.div`
  font-size: 10px;
  color: rgba(16, 123, 179, 1);
  position: absolute;
  top: 5%;
  left: 5%;
`;

const RankImgContainer = styled.div`
  position: relative;
  left: 35%;
  top: 100%;
  height: 1px;
  width: 30%;
  z-index: 1;
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: left;
  align-items: center; 
`;

const RankImg = styled.img`
  max-width: 80%; 
  max-height: 80%; 
  height: auto; 
  z-index: 3;
  position: absolute;
`;

const UniqId = styled.p`
  font-size: 9px;
  position: absolute;
  top: 50%;
  left: 5%;
  background-color: rgba(16, 123, 179, 0.5);
  color: white;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
`;

const Name = styled.p`
  font-size: 12px;
  color: white;
  position: absolute;
  left: 5%;
  top: 13%;
`;

const MiningPower = styled.div`
  font-size: 12px;
  top: 10%;
  left: 40%;
  position: absolute;
  color: white;
`;

const CombatPower = styled.div`
  font-size: 12px;
  top: 40%;
  left: 40%;
  position: absolute;
  color: white;
`;

const Stamina = styled.p`
  position: absolute;
  font-size: 13px;
  color: #8D51CF;
  right: 3%;
  top: 10%;
  margin: 0;
  position: absolute;
`;

// FOOTER ***********************

const Footer = styled.div`
    position: relative;
    height: 15%;
    width: 100%;
    z-index: 1;
    padding: 0.2rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px 10px 0 0;
    color: rgba(225, 225, 225, 0.8);
    display: flex;
    justify-content: center; 
    align-items: center;
`;

const FooterName = styled.div`
    position: absolute;
    top: 10%; 
    left: 2%; 
    padding: 1px 3px 1px 3px;
    color: white;
    font-size: 1.2rem;
    font-weight: bold; 
    border-radius: 0 5px 5px 0;
    z-index: 4; 
`;

const SpaceshipsSize = styled.div`
    position: absolute;
    font-size: 0.8rem;
    color: green;
    z-index: 10;
    bottom: 26%;
    left: 3%;
`;

const LegionariesSize = styled.div`
    position: absolute;
    font-size: 0.8rem;
    color: green;
    z-index: 10;
    bottom: 8%;
    left: 3%;
`;

const LegionFlagImg = styled.img`
    max-width: 65%;
    max-height: 65%;
    right: 3%;
    height: auto;
    z-index: 3;
    position: absolute;
`;

const Grade = styled.div`
    margin: 0;
    position: absolute;
    top: 25%; 
    left: 0%;
    color: #107BB3; 
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.8rem;
    font-weight: bold;
    font-size: 1rem;
    z-index: 4; 
`;

const LegionMiningPower = styled.div`
    position: absolute;
    top: 45%; 
    left: 45%; 
    white-space: pre-line;
    color: #FFFFFF; 
    font-weight: bold; 
    background-color: rgba(0, 0, 0, 0.1); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionCombatPower = styled.div`
    position: absolute;
    top: 70%; 
    left: 45%; 
    white-space: pre-line;
    color: #FFFFFF; 
    font-weight: bold; 
    background-color: rgba(0, 0, 0, 0.1); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionCapacity = styled.div`
    position: absolute;
    top: 20%; 
    left: 45%; 
    white-space: pre-line;
    color: #FFFFFF; 
    font-weight: bold; 
    background-color: rgba(0, 0, 0, 0.1); 
    border-radius: 5px; 
    z-index: 1; 
`;

const VerticalDivider = styled.div`
  position: absolute;
  height: 80%; 
  width: 1px;
  left: 35%;
  background: linear-gradient(
    to bottom, 
    transparent 0%, 
    #ffffff 30%, 
    #ffffff 70%, 
    transparent 100%
  );
  margin: 0 20px;

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        z-index: 20000000000;
        height: 100%;
        width: 1px;
        background: linear-gradient(transparent 0%, #ffffff 30%, #ffffff 70%, transparent 100%),
        linear-gradient(transparent 0%, transparent 40%, #ffffff 60%, transparent 100%);
        top: 10%;
        right: 50%;
    }

    @media (max-width: 630px) {
        &:not(:last-child)::after {
            right: -1rem;
        }
    }
`;