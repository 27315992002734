import React, { useState, useEffect } from 'react';
import { useWeb3 } from '../../../Handlers/ChainContext';
import styled from "styled-components";
import { mintingFusionPlant, buyingEnergy, getEnergyOwners } from '../../../Handlers/LandsContractHandler';
import { getCurrentPrimarisPrice } from '../../../Handlers/OracleHandler';
import legionsAbi from '../../../ABI/ABILegions.json';
import ContractsAddresses from '../../../Handlers/ContractsAdresses';
import energy from '../../../Assets/Images/Lands/Designer.jpeg';
import axionisLogo from '../../../Assets/Images/NFT/Legions/Axionis/AxionisLogo.png';
import eternisLogo from '../../../Assets/Images/NFT/Legions/Eternis/EternisLogo.png';
import primusLogo from '../../../Assets/Images/NFT/Legions/PrimusVU/PrimusVULogo.png';

/* global BigInt */

const contractLegionsAdd = ContractsAddresses.legion;

const EnergyPlant = () => {
  const { web3, account } = useWeb3(); 
  const [nftTokens, setNftTokens] = useState([]);
  const [currentTokenId, setCurrentTokenId] = useState(null);
  const [selectedLegion, setSelectedLegion] = useState(null); 
  const [selectedCampaignId, setSelectedCampaignId] = useState(":)");
  const [owners, setOwners] = useState([]);
  const [price, setPrice] = useState(null);
  const [selectedLegionOption, setSelectedLegionOption] = useState(
    "Select your legion..."
  );

  useEffect(() => {
    const loadNFTTokens = async () => {
      if (web3 && account) {
        const contract = new web3.eth.Contract(legionsAbi, contractLegionsAdd);
        const ownedTokens = await contract.methods.getLegion(account).call();
        
        console.log('ownedTokens:', ownedTokens);

        if (Array.isArray(ownedTokens)) {
          const tokens = ownedTokens.map((token) => ({
            legionId: token[0],
            legionaries: token[1],
            ships: token[2],
            legionName: web3.utils.hexToUtf8(token[3]),
            totalCapacity: token[4],
            totalCombatPower: token[5],
            totalMiningPower: token[6],
            warCohort: token[7],
            warTribute: token[8],
            energy: token[9],
            grade: token[10],
            glory: token[11],
            moral: token[12],
            experience: token[13],
            tokenHash: token[14]
          }));
          setNftTokens(tokens);
        } else {
          console.error('Unexpected structure of ownedTokens:', ownedTokens);
        }
      }
    };

    loadNFTTokens();
  }, [web3, account]);

  // MINTING FUSION PLANT ********************************************************************************

  const mintFusionPlant = () => {
    mintingFusionPlant(web3);
  };

  // PLANT OWNERS ***********************************************************************************

  useEffect(() => {
    const loadOwners = async () => {
      const ownersData = await getEnergyOwners(web3);
      if (ownersData && ownersData.length > 0) {
        setOwners(ownersData);
      } else {
        setOwners(["Waiting owners", "Waiting owners", "Waiting owners", "Waiting owners", "Waiting owners"]);
      }
    };

    loadOwners();
  }, [web3]);

  const formatAddress = (address) => {
    if (address === "Waiting owners") return address;
    return `${address.slice(0, 4)}...${address.slice(-6)}`;
  };

  // BUYING ENERGY ********************************************************************************

  const buyEnergy = () => {

    testLog(currentTokenId, selectedCampaignId)

  if (currentTokenId != null && selectedCampaignId != null) {

      buyingEnergy(currentTokenId, selectedCampaignId, web3);
    } else {
      console.error("No legion or campaign selected.");
    }

  };

  const testLog = (a, b, c) => {
    console.warn("EL LEGION ID ES: " + a + " Y LA CAMPAÑA ES: " + b + "EL ID DE LA PLANTA ES: " + c);
  }

  useEffect(() => {
    const fetchPrice = async () => {
      const currentPrimarisPrice = await getCurrentPrimarisPrice(web3);
      const priceBigInt = BigInt(currentPrimarisPrice);
      const adjustedPrice = Number(priceBigInt) / 1e18;
      const formattedPrice = adjustedPrice.toFixed(4);

      setPrice(formattedPrice);
    };

    fetchPrice();
  }, [web3]);

  // SELECTING LEGION

  const renderFlag = (warCohort) => {
    const flagImages = { "0": axionisLogo, "2": eternisLogo, "1": primusLogo };
  
    return <LegionFlagImg src={flagImages[warCohort]} alt={`Flag ${warCohort}`} />;
  };

  const LegionDropdown = ({ legions, onSelect, setCurrentTokenId }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleSelect = (legion) => {
      setSelectedLegionOption(legion.legionName);
      setCurrentTokenId(legion.legionId);
      setIsOpen(false);
      onSelect(legion); 
    };
  
    return (
      <DropdownLContainer>
        <DropdownButton onClick={() => setIsOpen(!isOpen)}>
          {selectedLegionOption}
        </DropdownButton>
        {isOpen && (
          <DropdownContent>
            {legions.map((legion, index) => (
              <DropdownItem key={index} onClick={() => handleSelect(legion)}>
                <DropdownText>{legion.legionName}</DropdownText>
              </DropdownItem>
            ))}
          </DropdownContent>
        )}
      </DropdownLContainer>
    );
  };

  // SELECTING CAMPAIGN ID

  const CampaignDropdown = ({ options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
      selectedCampaignId ? `${getPlanetName(selectedCampaignId)}` : "Choose your next campaign..."
    );
  
    const handleSelect = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
  
    const campaignId = parseInt(option.split(':')[0], 10);
      onSelect(campaignId);
    };
  
    return (
      <DropdownContainer>
        <DropdownButton onClick={() => setIsOpen(!isOpen)}>
          {selectedOption}
        </DropdownButton>
        {isOpen && (
          <DropdownContent>
            {options.map((option, index) => (
              <DropdownItem key={index} onClick={() => handleSelect(option)}>
                <DropdownText>{option}</DropdownText>
              </DropdownItem>
            ))}
          </DropdownContent>
        )}
      </DropdownContainer>
    );
  };

  function getPlanetName(planetId) {
    return planetNames[planetId] || "Choose your next campaign...";
  }
  
  const planetNames = {
    1: "1: Trion",
    2: "2: Zypher",
    3: "3: Galatus",
    4: "4: Aetheria",
    5: "5: Vortex",
    6: "6: Naiburis",
    7: "7: Hyperion",
    8: "8: Xanadú",
    9: "9: Lyra",
    10: "10: Druicon",
    11: "11: Draconis",
    12: "12: Roymus",
    13: "13: Elygov",
    14: "14: Krasnar",
    15: "15: Xeutlan",
    16: "16: Cux",
    17: "17: Vega",
    18: "18: Vanandri",
    19: "19: Altair",
    20: "20: Vexi 3",
    21: "21: Yizutar",
    22: "22: Oxilium",
    23: "23: Conemnis",
    24: "24: Astomia",
    25: "25: Novaris",
    26: "26: Julima",
    27: "27: Targhonia",
    28: "28: Proxima Avenis",
    29: "29: Afnor",
    30: "30: Priamus Singularity"
  };

  const sortedPlanets = Object.values(planetNames);

  // SELECTING FUSION PLANT ID

  return (
    <PrincipalContainer>
      <PanelContainer>
        <PanelCell> 

        <LegionNftContainer>
            {selectedLegion ? (
              <>
                <LegionName>{selectedLegion.legionName}</LegionName>
                <LegionCombatPower>Combat Power: {selectedLegion.totalCombatPower.toString()}</LegionCombatPower>
                <LegionMiningPower>Mining Power: {selectedLegion.totalMiningPower.toString()}</LegionMiningPower>
                <LegionEnergy>Energy: {selectedLegion.energy.toString()}</LegionEnergy>
                <LegionFlagImgContainer>{renderFlag(selectedLegion.warCohort.toString())}</LegionFlagImgContainer>
              </>
            ) : (
              <NameLegion>Choose your legion...</NameLegion>
            )}
          </LegionNftContainer>
        </PanelCell>
      </PanelContainer>
      
      {/* Scrollable NFT container */}
      <ScrollableContainer>
        <ScrollableContent>
          <Line /> {/* Línea vertical decorativa */}
        
          <PlanetContainer>
            <PlanetImg src={energy} alt={"Energy Plant"} />
            <Name>Fusion Plant</Name>
            <StyledButton onClick={() => buyEnergy()}>Buy Energy</StyledButton>
            <MintButton onClick={() => mintFusionPlant()}>Mint</MintButton>
            <CampaignDropdown options={sortedPlanets} onSelect={setSelectedCampaignId} />
            <LegionDropdown legions={nftTokens} onSelect={setSelectedLegion} setCurrentTokenId={setCurrentTokenId}  />

            <OwnersContainer>
              {owners.map((owner, index) => (
                <OwnerBox key={index}>{formatAddress(owner)}</OwnerBox>
              ))}
            </OwnersContainer>

            <TokenPriceCont>
              <TokenPriceTitle>Current $PRIMARIS Price:</TokenPriceTitle>
              {price !== null ? <CurrentTokenPrice>{price + " USDC"}</CurrentTokenPrice> : <CurrentTokenPrice>Loading...</CurrentTokenPrice>}
            </TokenPriceCont>

          </PlanetContainer>
        
        </ScrollableContent>
      </ScrollableContainer>
    </PrincipalContainer>
  );
};

export default EnergyPlant;

// DropDowns ********************************************

const DropdownContainer = styled.div`
  position: absolute;
  right: 0%;
  width: 30%;
`;

const DropdownLContainer = styled.div`
  position: absolute;
  left: 0%;
  width: 30%;
`;

const DropdownButton = styled.button`
  width: 100%;
  height: 30px;
  font-size: 12px;
  padding: 5px;
  background-color: #1b1b1b;
  color: #FFFFFF;
  border: 1px solid #8D51CF;
  border-radius: 5px;
  cursor: pointer;
    display: flex; /* Activar Flexbox */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
`;

const DropdownContent = styled.ul`
  position: absolute;
  z-index: 2;
  font-size: 10px;
  width: 100%;
  background-color: #1b1b1b;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;

  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #242124;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8D51CF;
    border-radius: 10px;
    border: 2px solid #242124;
  }
`;

const DropdownItem = styled.li`
  padding: 10px;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: #8D51CF;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const DropdownText = styled.span`
  flex: 1;
  cursor: pointer;
`;

// PRINCIPAL *****************************************************

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    margin-left: 1rem;
    height: 99vh;
    width: 99vw;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

// Mint And Counter Panels Styles

const PanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  place-items: center;
  min-height: 10rem;
  padding: 0;
  margin: 0 auto;
  margin-top: 15%;
  width: 70%;
  z-index: 2;
  @media (min-width: 1200px) and (max-width: 1400px) {
    margin-top: 35vw;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 30vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 90%;
    margin-top: 60vw;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    margin-top: 70vw;
    width: 100%;
  }

  @media (max-width: 479px) {
     margin-top: 85vw;
     width: 100%;
  }
  
  @media (max-width: 376px) {
     margin-top: 90vw;
     width: 100%;
  }

`;

const PanelCell = styled.div`
  background-color: #0c0d10;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8);
  height: 100%;
  width: 50%;
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 80%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 80%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    position: relative;
    width: 80%;
    
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 90%;
  }

  @media (max-width: 479px) {
    width: 100%;
  }

`;
// Scrollable NFT container styles

const ScrollableContainer = styled.div`
  margin-top: 5%;
  flex-direction: column;
  align-items: center; 
  gap: 1.5rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  justify-content: center;
  display: flex;
  padding-bottom: 5%;
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {

    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }

    @media screen and (max-width: 479px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }
  `;

  const ScrollableContent = styled.div`
  flex-direction: column;
  align-items: center; 
  gap: 1.5rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  justify-content: center;
  display: flex;
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {

    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }

    @media screen and (max-width: 479px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }
`;

const Line = styled.div`
  width: 4px; 
  height: 70%; 
  background: linear-gradient(to bottom, #8D51CF, #33c1ff);
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0; /* Asegura que esté detrás del contenido */
`;


// Energy Plant styles ******************************************************************

const PlanetContainer = styled.div`
    background-color: #101010;
    border: 1px solid rgba(255,255,255,1);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    filter: ${({ disabled }) => (disabled ? 'brightness(0.5)' : 'brightness(1)')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    height: 487px;
    width: 675px;
    margin-top: 1rem;
    position: relative;
    } 

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin-top: 3rem;
      border: 1px solid blue;

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      margin-top: 3rem;
      } 
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      margin-top: 3rem;
    }

    @media screen and (max-width: 479px) {
      margin-top: 3rem;
      } 
    }
`;

const PlanetImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 80%;
    z-index: 0;
`;

const Name = styled.p`
    position: absolute;
    top: 65%; 
    left: 0; 
    padding: 5px 20px 3px 8px;
    color: white;
    font-size: 1.7rem;
    font-weight: normal; 
    background-color: rgba(0,0,0,0.5); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const NameLegion = styled.div`
  position: absolute;
  top: 30%; 
  text-align: center;
  left: 0;
  width: 100%; 
  padding: 5px 20px 3px 8px;
  color: rgba(225,225,225,0.9);
  font-size: 1.8rem;
  font-weight: normal; 
  z-index: 1; 
`;

const StyledButton = styled.button`
    position: absolute;
    top:85%;
    left: 41%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    color: white;
    transition: background-color 0.3s ease;
    width: 18%;
    height: 8%;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

const MintButton = styled.button`
    position: absolute;
    display: none;
    top:85%;
    left: 41%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    color: white;
    transition: background-color 0.3s ease;
    width: 18%;
    height: 8%;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

const LegionNftContainer = styled.div`
    padding: 0.3rem;
    height: 7rem;
    width: 100%;
    margin: 1rem;
    position: relative;
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
    }
    @media screen and (max-width: 426px)
      margin:0;
    }
`;

const LegionFlagImgContainer = styled.div`
    position: absolute;
    right: 0;
    top: 3%;
    height: 70px;
    width: 30%;
    z-index: 1;
    display: flex;
    justify-content: center; 
    align-items: center; 
`;

const LegionFlagImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    height: auto;
    z-index: 3;
    position: absolute;
`;

const LegionName = styled.div`
    position: absolute;
    top: 5%; 
    left: 0; 
    padding: 3px 8px 1px 10px;
    color: white;
    font-size: 30px;
    font-weight: bold; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const LegionMiningPower = styled.div`
    position: absolute;
    white-space: pre-line;
    bottom: 3%; 
    left: 3%; 
    color: rgba(16, 123, 179, 1);
    font-size: 15px;
    font-weight: normal; 
    background-color: rgba(0, 0, 0, 0.2); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionCombatPower = styled.div`
    position: absolute;
    bottom: 25%; 
    left: 3%; 
    color: rgba(16, 123, 179, 1);
    font-size: 15px;
    font-weight: normal; 
    background-color: rgba(0, 0, 0, 0.2); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionEnergy = styled.div`
    position: absolute;
    bottom: 3%; 
    right: 0; 
    padding-right: 8px;
    padding-left: 6px;
    padding-top: 5px;
    color: #F5F5DC; 
    font-weight: bold; 
    font-size: 18px;
    background-color: #8D51CF; 
    border-radius: 5px;
    z-index: 1; 
`;

// TOKEN PRICE 

const TokenPriceCont = styled.div`
    position: absolute;
    bottom: 4%;
    left: 3%;
    z-index: 4;
    width: 20%;
    height: 10%;
    font-size: 12px;
`;

const TokenPriceTitle = styled.div`
    position: absolute;
    color: rgba(225,225,225,1);
    top 0%;
    left: 0%;
    font-size: 0.8rem;
    width: 100%;
`;

const CurrentTokenPrice = styled.div`
  position: absolute;
  justify-content: left;
  align-items: left;
  height: auto;
  width: 100%;
  bottom: 0%;
  color: rgba(16, 123, 179, 1);
  border-radius: 5px;
  font-size: 1rem;
  text-align: left;
`;

// Owners Container

const OwnersContainer = styled.div`
  position: absolute;
  bottom: 1%;
  right: 1%;
  z-index: 4;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 3px;
  padding: 5px;
  border: 1px solid rgba(16, 123, 179, 1);
  border-radius: 10px;
  width: 20%;
  height:auto;
  background-color: #242624;
`;

const OwnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  color: rgba(16, 123, 179, 1);
  background-color: #1b1b1b;
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
`;