import React, { useState, useEffect } from "react";
import { useWeb3 } from '../Handlers/ChainContext';
import { getTokenBalance } from '../Handlers/retriveBalance';
import styled from "styled-components";
import Primaris from '../Assets/primaris.png';

export default function Button({ text }) {
  const { web3, account } = useWeb3(); 
  const [primarisBalance, setPrimarisBalance] = useState(null); 

  useEffect(() => {
    if (account) {

      getTokenBalance(account).then(balance => {
        setPrimarisBalance(balance);
      }).catch(error => {
        console.error('Error getting token balance:', error);
      });
    }
  }, [account]); 

  const connectMetamask = async () => {
    try {
      if (web3) {

        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          console.log("Connected account:", accounts[0]);
        }
      } else {
        alert('Please install MetaMask to use this application.');
      }
    } catch (error) {
      console.error('Error connecting with MetaMask:', error);
    }
  };

  const formatAddress = (address) => {
    if (!address) return '';
    const firstFour = address.slice(0, 4);
    const lastSix = address.slice(-6);
    return `${firstFour}...${lastSix}`;
  };

  const formatBalance = (balance) => {
    if (!balance) return '';
    const balanceNumber = parseFloat(balance); 
    const roundedBalance = balanceNumber.toFixed(2); 
    const [integerPart, decimalPart] = roundedBalance.split('.');
    return `${integerPart}.${decimalPart.slice(0, 2)}`; 
  };

  return (
    <StyledButton onClick={connectMetamask} connected={account}>
      {account ? 
        (<div style={{ display: 'flex', alignItems: 'center' }}>
          <PrimarisToken src={Primaris} alt={"Primaris Token"} />
          <div>
            <AccData>{formatAddress(account)}</AccData>
            <AccData>PRIMARIS: {primarisBalance !== null ? formatBalance(primarisBalance) : 'Loading...'}</AccData>
          </div>
        </div>)
        : ('Connect')
      }
    </StyledButton>
  );
}


const StyledButton = styled.button`
  display: flex;
  margin-right: 10%;
  padding: ${props => props.connected ? '10px 20px' : '15px 25px'};
  font-size: ${props => props.connected ? '15px' : '18px'};
  border-radius: ${props => props.connected ? '10px':'10px'};
  cursor: pointer;
  text-align: ${props => props.connected ? 'left':'center'};
  border: ${props => props.connected ? '1px solid rgba(141, 81, 207, 1)':'3px solid #218838'}; 
  background-color: ${props => props.connected ? 'rgba(0, 0, 0, 0.5)':'rgba(141, 81, 207, 0)'}; 
  color: ${props => props.connected ? 'rgba(255, 255, 255, 0.8)' : '#218838'};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${props => props.connected ? 'rgba(141, 81, 207, 0.8)' : '#218838'};
    color: ${props => props.connected ? '#FFFFFF' : '#FFFFFF'};
  }
    @media (min-width: 1200px) and (max-width: 1400px) {
      margin-right: 15%;
    
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: 40%;
      margin-right: 10%;
    }

    @media (min-width: 480px) and (max-width: 767px) {
      width: 40%;
      margin-right: 10%;
    }

    @media (max-width: 479px) {
      padding: ${props => props.connected ? '5px 10px' : '7.5px 12.5px'};
    }
`;

const AccData = styled.div`
  margin: 0;
  font-size: 1rem;
    @media (min-width: 1200px) and (max-width: 1400px) {
      font-size:0.8rem;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size:0.8rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 1rem;
    }

    @media (min-width: 480px) and (max-width: 767px) {
      font-size: 0.6rem;
    }

    @media (max-width: 479px) {
      font-size: 0.7rem;
    }
`;

const PrimarisToken = styled.img`
  width: 18%;
  height: 18%;
  margin-right: 8%;
`;