import SpaceStationAbi from '../ABI/ABISpaceStation.json';
import FusionPlantAbi from '../ABI/ABIFusionPlant.json';
import { handlePermissions } from './PrimarisTokenPermissions';
import ContractsAddresses from './ContractsAdresses';

const spaceStationAddress = ContractsAddresses.spaceStation;
const fusionPlantAddress = ContractsAddresses.fusionPlant;
const approvalAmount = ContractsAddresses.approvalAmount;

//Space Station ********************************************************

export async function mintingSpaceStation(web3) {
    
  try {

    if (!web3) {
      console.error('Web3 not provided');
      return;
    }

    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      console.error('No accounts found');
      return;
    }

    const account = accounts[0];
    const isApproved = await handlePermissions(spaceStationAddress, approvalAmount, web3);
    if (!isApproved) {
      console.error('Approval not granted');
      return;
    }
    console.log('Approval granted, proceeding to mint');

    const contract = new web3.eth.Contract(SpaceStationAbi, spaceStationAddress);

    const gasEstimate = await contract.methods.mintSpaceLand().estimateGas({ from: account });
    const gasLimit = Math.floor(Number(gasEstimate) * 1.2);

    const gasPrice = await web3.eth.getGasPrice();
    const increasedGasPrice = Math.floor(Number(gasPrice) * 1.5);

    console.log(`Gas Estimate: ${gasEstimate}`);
    console.log(`Gas Limit: ${gasLimit}`);
    console.log(`Gas Price: ${gasPrice}`);
    console.log(`Increased Gas Price: ${increasedGasPrice}`);

    await contract.methods.mintSpaceLand().send({
      from: account,
      gas: gasLimit,
      gasPrice: increasedGasPrice
    })
      .on('transactionHash', function(hash){
        console.log('Transaction Hash:', hash);
      })
      .on('confirmation', function(confirmationNumber, receipt){
        console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
      })
      .on('receipt', function(receipt){
        console.log('Receipt:', receipt);
      })
      .on('error', function(error, receipt) {
        console.error('Error in transaction:', error, 'Receipt:', receipt);
      });
    console.log('Spacial Station Minted');
    } catch (error) {
      console.error('Error minting token:', error);
    }
  }

  export async function buyingHealth(legionId, web3) {

    try {
  
      if (!web3) {
        console.error('Web3 not provided');
        return;
      }
  
      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];

      const isApproved = await handlePermissions(spaceStationAddress, approvalAmount, web3);
      if (!isApproved) {
        console.error('Approval not granted');
        return;
      }
      console.log('Approval granted, proceeding buy health');
  
      const spaceStationContract = new web3.eth.Contract(SpaceStationAbi, spaceStationAddress);
  
      // Estimate gas
      const gasEstimate = await spaceStationContract.methods.buyHealth(legionId).estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
      
      // Obtener el gas price y aumentar en un 10%
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);
  
      // Send transaction
      const transaction = spaceStationContract.methods.buyHealth(legionId)
        .send({ 
          from: account,
          gas: gasLimit,
          gasPrice: increasedGasPrice,
        });
  
      transaction
        .on('transactionHash', function(hash) {
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt) {
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
  
      const receipt = await transaction;
      console.log('Legion its healtly', receipt);
      return receipt;
    } catch (error) {
        if (error.message.includes("Your troops are not damaged")) {
            } 
        console.error("Error en la transacción:", error);
      throw error; 
    }
  }

  export async function getSpaceLandAttributes(web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(SpaceStationAbi, spaceStationAddress);
        const ids = await contract.methods.getSpaceStationAttributes().call();
        return ids;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting SPACE STATION IDs:', error);
      return [];
    }
  }

  export async function getSpaceOwners(web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(SpaceStationAbi, spaceStationAddress);
        const ids = await contract.methods.getSpaceStationsOwners([0, 1, 2, 3, 4]).call();
        return ids;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting Space Station Owners:', error);
      return [];
    }
  }

  // FUSION PLANT *****************************************************************************************************

  export async function mintingFusionPlant(web3) {
    try {
  
      if (!window.ethereum) {
        console.error('MetaMask is not installed');
        return;
      }

      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];

      const isApproved = await handlePermissions(fusionPlantAddress, approvalAmount, web3);
      if (!isApproved) {
        console.error('Approval not granted');
        return;
      }
      console.log('Approval granted, proceeding to mint');
  
      const contract = new web3.eth.Contract(FusionPlantAbi, fusionPlantAddress);
  
      const gasEstimate = await contract.methods.mintEnergyLand().estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
  
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.5);
  
      console.log(`Gas Estimate: ${gasEstimate}`);
      console.log(`Gas Limit: ${gasLimit}`);
      console.log(`Gas Price: ${gasPrice}`);
      console.log(`Increased Gas Price: ${increasedGasPrice}`);
  
      await contract.methods.mintEnergyLand().send({
        from: account,
        gas: gasLimit,
        gasPrice: increasedGasPrice
      })
        .on('transactionHash', function(hash){
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt){
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt){
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
      console.log('Spacial Station Minted');
    } catch (error) {
      console.error('Error minting token:', error);
    }
  }

  export async function buyingEnergy(legionId, campaignId, web3) {
    try {

      if (!web3) {
        console.error('Web3 not provided');
        return;
      }
  
      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];

      const isApproved = await handlePermissions(fusionPlantAddress, approvalAmount, web3);
      if (!isApproved) {
        console.error('Approval not granted');
        return;
      }
      console.log('Approval granted, proceeding to add tokens');
  
      const fusionPlantContract = new web3.eth.Contract(FusionPlantAbi, fusionPlantAddress);
  
      // Estimate gas
      const gasEstimate = await fusionPlantContract.methods.buyEnergy(legionId, campaignId).estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
      
      // Obtener el gas price y aumentar en un 10%
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);
  
      // Send transaction
      const transaction = fusionPlantContract.methods.buyEnergy(legionId, campaignId)
        .send({ 
          from: account,
          gas: gasLimit,
          gasPrice: increasedGasPrice,
        });
  
      transaction
        .on('transactionHash', function(hash) {
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt) {
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
  
      const receipt = await transaction;
      console.log('Energy successfully to Legion', receipt);
      return receipt;
    } catch (error) {
      console.error('Error adding energy to legion:', error);
      throw error; 
    }
  }

  export async function getEnergyLandAttributes(fusionPlantId, web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(FusionPlantAbi, fusionPlantAddress);
        const ids = await contract.methods.getEnergyPlantAttributes(fusionPlantId).call();
        return ids;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting Energy Plant IDs:', error);
      return [];
    }
  }

  export async function getEnergyOwners(web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(FusionPlantAbi, fusionPlantAddress);
        const ids = await contract.methods.getEnergyPlantsOwners([0, 1, 2, 3, 4]).call();
        return ids;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting Energy Plant Owners:', error);
      return [];
    }
  }