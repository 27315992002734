import React, { useState, useEffect } from 'react';
import { checkSpaceshipUsed } from '../../../Handlers/NftsContractsHandler';
import { GetName } from './LegionariesNames/Names'
import { GetLastName } from './LegionariesNames/LastNames';
import Web3 from 'web3';
import SpaceshipsAbi from '../../../ABI/ABISpaceships.json';
import styled from "styled-components";
import R1 from '../../../Assets/Images/NFT/Ships/R1.png';
import R2 from '../../../Assets/Images/NFT/Ships/R2.png';
import R3 from '../../../Assets/Images/NFT/Ships/R3.png';
import R4 from '../../../Assets/Images/NFT/Ships/R4.png';
import R5 from '../../../Assets/Images/NFT/Ships/R5.png';
import R1rank from '../../../Assets/Images/NFT/Legionaries/R1rank.png';
import R2rank from '../../../Assets/Images/NFT/Legionaries/R2rank.png';
import R3rank from '../../../Assets/Images/NFT/Legionaries/R3rank.png';
import R4rank from '../../../Assets/Images/NFT/Legionaries/R4rank.png';
import R5rank from '../../../Assets/Images/NFT/Legionaries/R5rank.png';
import R1video from '../../../Assets/Video/Ships/R1shipVideo.mp4';
import R2video from '../../../Assets/Video/Ships/R2shipVideo.mp4';
import R3video from '../../../Assets/Video/Ships/R3shipVideo.mp4';
import R4video from '../../../Assets/Video/Ships/R4shipVideo.mp4';
import R5video from '../../../Assets/Video/Ships/R5shipVideo.mp4';
import ContractsAddresses from '../../../Handlers/ContractsAdresses';

//Web3 Manager Functions

const contractSpaceshipsAdd = ContractsAddresses.spaceship;


const SpaceshipsMarket = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [nftTokens, setNftTokens] = useState([]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        try {
          // Request account access if needed
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          // Acccounts now exposed
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
        } catch (error) {
          console.error('User denied account access');
        }
      } else {
        console.error('No Ethereum interface detected');
      }
    };

    loadWeb3();
  }, []); 

  const renderNftImage = (capacity_) => {
    switch (capacity_) {
      case "1":
        return <NftImg src={R1} alt="Banshee" />;
      case "2":
        return <NftImg src={R2} alt="Fighter" />;
      case "3":
        return <NftImg src={R3} alt="Vessel" />;
      case "4":
        return <NftImg src={R4} alt="Hunter" />;
      case "5":
        return <NftImg src={R5} alt="Corvette" />;
      default:
        return null;
    }
  };

  const getVideoSrc = (capacity_) => {
    switch (capacity_) {
      case "1":
        return R1video;
      case "2":
        return R2video;
      case "3":
        return R3video;
      case "4":
        return R4video;
      case "5":
        return R5video;
      default:
        return null;
    }
  };

  const renderRankImage = (capacity_) => {
    switch (capacity_) {
      case "1":
        return <RankImg src={R1rank} alt="Hastati" />;
      case "2":
        return <RankImg src={R2rank} alt="Opitionis" />;
      case "3":
        return <RankImg src={R3rank} alt="Decurian" />;
      case "4":
        return <RankImg src={R4rank} alt="Centurian" />;
      case "5":
        return <RankImg src={R5rank} alt="Praetorian" />;
      default:
        return null;
    }
  };

  const handleButtonClick = (capacity_) => {
    const videoSource = getVideoSrc(capacity_.toString());
    setVideoSrc(videoSource);
    setIsVideoVisible(true);
  };
  
  const renderRankName = (capacity_) => {
    let rankName = "";
    switch (capacity_) {
      case "1":
        rankName = "Banshee";
        break;
      case "2":
        rankName = "Fighter";
        break;
      case "3":
        rankName = "Vessel";
        break;
      case "4":
        rankName = "Hunter";
        break;
      case "5":
        rankName = "Corvette";
        break;
    default:
      rankName = "Unknown";
      break;
    }
    return <Rank>{rankName}</Rank>;
  };

  function formatAddress(spaceshipId_) {
    if (!spaceshipId_) return '';
    const firstFive = spaceshipId_.slice(0, 5);
    const lastSix = spaceshipId_.slice(-6);
    return `${firstFive}...${lastSix}`;
  };

  const calculateTotalCombatPower = () => {
    return nftTokens.filter(token => token.spaceship.spaceshipId_ !== null ).reduce((total, token) => total + parseInt(token.spaceship.combatSPower_), 0);
  };

  useEffect(() => {
    const loadNFTTokens = async () => {
        if (web3 && account) {
            const contract = new web3.eth.Contract(SpaceshipsAbi, contractSpaceshipsAdd);
            const ownedTokens = await contract.methods.getShip(account).call();

            const checkedTokens = await Promise.all(ownedTokens.map(async (token, index) => {
              const isUsed = await checkSpaceshipUsed(token.tokenId_);
              return {
                id: token.tokenId_,
                spaceship: {
                  spaceshipId_: token[0] || "",
                  spaceshipName_: token[1] || "",
                  spaceshipReg_: token[2] || "",
                  capacity_: parseInt(token[3]) || "",
                  combatSPower_: token[4] || "",
                  operability_: token[5] || "",
                  tokenId_: token[6], 
                  isUsed: isUsed,
                }
              };
            }));
    
            console.log(checkedTokens);
            setNftTokens(checkedTokens);
          }
        };
    
        loadNFTTokens();
      }, [web3, account]);
  
  return (

    <PrincipalContainer>
    <PanelContainer>
        <PanelCell></PanelCell>
        <PanelCell>Total: <br/><center>{nftTokens.filter(token => token.spaceship.spaceshipId_ !== null).length}</center></PanelCell>
        <PanelCell>Total CP: {calculateTotalCombatPower()}</PanelCell>
    </PanelContainer>
    
    {/* Scrollable NFT cointainer */}
    
    <ScrollableContainer>
      <ScrollableContent>
            {nftTokens
            .filter(token => !token.spaceship.isUsed)
            .filter(token => token.spaceship.spaceshipId_ !== null).map(token => (
              <NftContainer key={token.id}> 
                  <p>Capacity: {token.spaceship.capacity_.toString()}</p>
                  {renderNftImage(token.spaceship.capacity_.toString())}
                  {renderRankName(token.spaceship.capacity_.toString())}
                  <RankImgContainer>{renderRankImage(token.spaceship.capacity_.toString())}</RankImgContainer>
                  <UniqId>ID: {formatAddress(token.spaceship.spaceshipId_)} {"  "} </UniqId>
                  <Name>{GetName(token.spaceship.spaceshipName_)} {GetLastName(token.spaceship.spaceshipReg_)}</Name>
                  <MiningPower>Capacity<br/><center>{token.spaceship.capacity_.toString()}</center></MiningPower>
                  <CombatPower>Combat P<br/><center>{token.spaceship.combatSPower_.toString()}</center></CombatPower>
                  <Stamina>OP: {token.spaceship.operability_.toString()}%</Stamina>
                  <StyledButton onClick={() => handleButtonClick(token.spaceship.capacity_)}>View</StyledButton>
              </NftContainer>
            ))}
      </ScrollableContent>
    </ScrollableContainer>

      {/* Superimposed Video */}
      {isVideoVisible && (
      <VideoOverlay>
            <VideoTop>
          <div className="links">
            <SellButton>SELL</SellButton>
            <BurnButton>BURN</BurnButton>
            <CloseButton onClick={() => setIsVideoVisible(false)}>X</CloseButton>
          </div>
        </VideoTop>
        <CloseButtonMobile onClick={() => setIsVideoVisible(false)}>X</CloseButtonMobile>
        <NftVideo src={videoSrc} controls autoPlay />
      </VideoOverlay>
    )}

</PrincipalContainer>

  );
};

export default SpaceshipsMarket;

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    margin-left: 1rem;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

// Mint And Counter Panels Styles

const PanelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  place-items: center;
  min-height: 6rem;
  padding: 1rem;
  margin: 0 auto;
  width: 70%;
  @media screen and (max-width: 1280px) {
    transform: translateY(18%);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const PanelCell = styled.div`
  background-color: #242124;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8);
  transform: translateY(8%);
  height: 55%;
  max-height: 60%;
  width: 50%;
  @media screen and (max-width: 1280px) {
    height: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: 15%;
    padding: 1rem;
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    width: 50%;
    height: 15%;
    padding: 1rem;
    font-size: 10px;
  }
`;

// Scrollable NFT container styles

const ScrollableContainer = styled.div`
    overflow-y: auto;
    margin-top: 0.5%;
    width: 100%;
    height: 70vh;
    max-height: 74vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*Scrollbar*/
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
    @media screen and (max-width: 1280px) {
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      width: 100%;
      margin-top: 13.5vh;
      max-height: 86.5vh;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 17vh;
      max-height: 83vh;
    }
    @media screen and (max-width: 426px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      max-height: 95vh;
      transform: translateY(11%);
      margin-top: 15vw;
    }
  `;

const ScrollableContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 1.5rem;
    padding: 2rem;
    box-sizing: border-box;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: 1280px) {
      justify-items: center;
    }
    @media screen and (max-width: 1024px){
      gap: 3vh;
      height: 100%;
      width: 86%;
      padding-top: 5%;
      justify-items: center;
    }
    @media screen and (max-width: 768px) {
      height: 100%;
      width: 86%;
      padding-top: 5%;
      justify-items: center;
      gap: 0rem;
    }
    @media screen and (max-width: 426px) {
      height: 100%;
      width: 86%;
      padding-top: 5%;
      padding-left: 0rem;
      padding-right: 0rem;
      justify-items: center;
      gap: 0rem;
    }
`;

// NFT styles

const NftContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 17rem;
    width: 25rem;
    margin: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0 4px 6px rgba(16, 123, 179, 0.8);
      transform: scale(1.1);
    } 
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (max-width: 426px)
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const NftImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
  
`;

const RankImgContainer = styled.div`
    position: relative;
    left: 5%;
    top: 55.5%;
    height: 7px;
    width: 83%;
    z-index: 1;
    clip-path: polygon(0 100%, 100% 100%, 80% 0, 20% 0);
    padding: 0.5rem 0.8rem;
    background-color: #242124;
    display: flex;
    justify-content: center; /* Alinear horizontalmente al centro */
    align-items: center; /* Alinear verticalmente al centro */
`;

const RankImg = styled.img`
    max-width: 80%; /* Ajustado el ancho máximo */
    max-height: 80%; /* Ajustado el alto máximo */
    height: auto; /* Para mantener la relación de aspecto */
    z-index: 3;
    position: absolute;
`;

const Rank = styled.p`
    margin: 0;
    position: absolute;
    top: 0; 
    left: 5%;
    color: #107BB3; 
    background-color: #242124;
    height: 7px;
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    box-shadow:  5px 10px rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0.8rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
    z-index: 4; 
`;

const UniqId = styled.p`
    position: absolute;
    top: 60%; 
    left: 1px; 
    padding-right: 3px;
    padding-left: 1px;
    color: #F5F5DC;
    font-weight: bold; 
    font-size: 14px;
    background-color: rgba(141, 81, 207, 0.8); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const Name = styled.p`
    position: absolute;
    top: 2.3%; 
    left: 0; 
    padding: 1px 3px 1px 3px;
    color: white;
    font-size: 15px;
    font-weight: normal; 
    background-color: #1b1b1b; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
    
`;

const MiningPower = styled.p`
    position: absolute;
    white-space: pre-line;
    top: 79%; /* Ajusta según sea necesario */
    left: 14%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const CombatPower = styled.p`
    position: absolute;
    top: 79%; /* Ajusta según sea necesario */
    left: 68%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const Stamina = styled.p`
    position: absolute;
    top: 60%; /* Ajusta según sea necesario */
    right: 1px; /* Ajusta según sea necesario */
    padding-right: 1px;
    padding-left: 3px;
    color: #F5F5DC; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    font-size: 14px;
    background-color: rgba(16, 123, 179, 0.7); /* Color de fondo con transparencia */
    border-radius: 5px 0 0 5px;/* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const StyledButton = styled.button`
    position: absolute;
    top:86%;
    left: 40%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 80px;
    height: 30px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

/*NFT Video */

const VideoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const NftVideo = styled.video`
  position: relative;
  width: 80%;
  height: auto;
  z-index: 5000;
  border-radius: 50px; 
  @media screen and (max-width: 1280px) {
    
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    border-radius: 10px;
    width: 90%;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    border-radius: 10px;
    width: 90%;
  }
  @media (max-width: 426px) {
    border-radius: 0;
    width: 100%;
  }
`;

const CloseButton = styled.button`
  position: relative;
  background: none;
  font-size: 1.5vh;
  left: 25%;
  cursor: pointer;
  color: white;
  border-radius: 50px;
  transition: background-color 0.3s;
  &:hover {
    border: 0.8px solid rgba(16, 123, 179, 1);
    color: rgba(16, 123, 179, 1);
  }
  @media screen and (max-width: 1280px) {
    left: 20%;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: none;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    display: none;
  }
  @media (max-width: 426px) {
    display: none;
  }
`;

const CloseButtonMobile = styled.button`
display: flex;
position: absolute;
background: none;
font-size: 3vh;
left: 47%;
top: 80%;
z-index: -5;
cursor: pointer;
color: white;
border-radius: 50px;
transition: background-color 0.3s;
&:hover {
  border: 0.8px solid rgba(16, 123, 179, 1);
  color: rgba(16, 123, 179, 1);
}
@media screen and (max-width: 1280px) {
  display: none;
}
@media screen and (min-width: 769px) and (max-width: 1024px){
  display: inherit;
  top: 93%;
  z-index: 999999;
}
@media screen and (min-width: 427px) and (max-width: 769px) {
  display: inherit;
}
  @media (max-width: 426px) {
  display: inherit;
  }
`;

const SellButton = styled.button`
  position: relative;
  padding: 1.5vh 2.8vh;
  left: 1%;
  font-size: 100%;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: 2px solid rgba(16, 123, 179, 0.8); 
  background-color: rgba(0, 0, 0, 0.5); 
  color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(16, 123, 179, 0.8);
    color: #FFFFFF;
  }
  @media screen and (max-width: 1280px) {
    
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: inherit;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    display: inherit;
  }
    @media (max-width: 426px) {
    left: 2%;
    }
  `;


const BurnButton = styled.button`
  position: relative;
  padding: 1.5vh 2.8vh;
  left: 3%;
  font-size: 100%;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: 2px solid rgba(141, 81, 207, 1); 
  background-color: rgba(0, 0, 0, 0.5); 
  color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(141, 81, 207, 0.8);
    color: #FFFFFF;
  }
  @media screen and (max-width: 1280px) {
    
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: inherit;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    left: 2%;
  }
    @media (max-width: 426px) {
    left: 2%;
    }
  `;

const VideoTop = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -1%;
  left: 10%;
  width: 80%;
  padding: 0.5rem;
  z-index: 5001;
  
  .links {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #252425;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    padding: 1.5rem 1rem;
    font-size: 0.9rem;
    width: 100%;
  }

  @media (max-width: 426px) {
    width: 100%;
    left: -3%;
    top: -2%;
    .links {
      padding: 1rem 0.5rem;
      font-size: 0.8rem;
    }
  }
`;



