import React, { useState, useEffect } from 'react';
import { useWeb3 } from '../../../../Handlers/ChainContext';
import styled from 'styled-components';
import { GetName } from '../LegionariesNames/Names';
import { GetLastName } from '../LegionariesNames/LastNames';
import { useSelectedTokens } from '../../../../Handlers/NftsContext';
import { useNavigate } from "react-router-dom";
import { checkLegionaryUsed, checkSpaceshipUsed } from '../../../../Handlers/NftsContractsHandler';
import { BackButton, SpaceshipsButton, LegionariesButton } from '../../../Buttons/SecundaryButton';
import CreateLegionManager from './CreateLegionManager';
import ContractsAddresses from '../../../../Handlers/ContractsAdresses';
import legionariesAbi from '../../../../ABI/ABILegionaries.json';
import spaceshipsAbi from '../../../../ABI/ABISpaceships.json';
import R1 from '../../../../Assets/Images/NFT/Legionaries/R1.png';
import R2 from '../../../../Assets/Images/NFT/Legionaries/R2.png';
import R3 from '../../../../Assets/Images/NFT/Legionaries/R3.png';
import R4 from '../../../../Assets/Images/NFT/Legionaries/R4.png';
import R5 from '../../../../Assets/Images/NFT/Legionaries/R5.png';
import R1rank from '../../../../Assets/Images/NFT/Legionaries/R1rank.png';
import R2rank from '../../../../Assets/Images/NFT/Legionaries/R2rank.png';
import R3rank from '../../../../Assets/Images/NFT/Legionaries/R3rank.png';
import R4rank from '../../../../Assets/Images/NFT/Legionaries/R4rank.png';
import R5rank from '../../../../Assets/Images/NFT/Legionaries/R5rank.png';
import S1 from '../../../../Assets/Images/NFT/Ships/R1.png';
import S2 from '../../../../Assets/Images/NFT/Ships/R2.png';
import S3 from '../../../../Assets/Images/NFT/Ships/R3.png';
import S4 from '../../../../Assets/Images/NFT/Ships/R4.png';
import S5 from '../../../../Assets/Images/NFT/Ships/R5.png';

const contractLegionariesAdd = ContractsAddresses.legionary;
const contractSpaceshipsAdd = ContractsAddresses.spaceship;
const maxSpaceship = 10;

const CreateLegionsContainer = () => {
  const { web3, account } = useWeb3(); 
  const [nftTokens, setNftTokens] = useState([]);
  const [showLegionaries, setShowLegionaries] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { addLegionaryToken, 
          addSpaceshipToken, 
          addCapacities, 
          legionariesTokenIds, 
          spaceshipsTokenIds, 
          spaceshipsCapacities,
          resetState } = useSelectedTokens();
          

  // LOAD TOKENS MAIN CONTAINER ************************************************************

  useEffect(() => {
    const loadNFTTokens = async () => {
      if (web3 && account) {
        const contract = new web3.eth.Contract(
          showLegionaries ? legionariesAbi : spaceshipsAbi,
          showLegionaries ? contractLegionariesAdd : contractSpaceshipsAdd
        );

        const methodName = showLegionaries ? 'getLegionary' : 'getShip';
        try {
          const ownedTokens = await contract.methods[methodName](account).call();
          const checkedTokens = await Promise.all(ownedTokens.map(async (token, index) => {
            if (showLegionaries) {
              const isUsed = await checkLegionaryUsed(token.tokenId_, web3);
              return {
                id: token[7],
                nft: {
                  legionaryId_: token[0] || "",
                  legionaryName_: token[1] || "",
                  lastName_: token[2] || "",
                  rank_: token[3] || "",
                  miningPower_: token[4] || "",
                  combatPower_: token[5] || "",
                  stamina_: token[6] || "",
                  tokenId_: token[7],
                  isUsed: isUsed,
                }
              };
            } else {
              const isUsed = await checkSpaceshipUsed(token.tokenId_, web3);
              return {
                id: token[6],
                nft: {
                  spaceshipId_: token[0] || "",
                  spaceshipName_: token[1] || "",
                  spaceshipReg_: token[2] || "",
                  capacity_: parseInt(token[3]) || "",
                  combatSPower_: token[4] || "",
                  operability_: token[5] || "",
                  tokenId_: token[6],
                  isUsed: isUsed,
                }
              };
            }
          }));
          console.log(checkedTokens);
          setNftTokens(checkedTokens);
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      }
    };

    loadNFTTokens();
  }, [web3, account, showLegionaries, spaceshipsCapacities]);

 // HANDLE SELECT TOKENS  ************************************************************
  
 const handleSelectSpaceship = (tokenId, capacity) => {
    if (spaceshipsTokenIds.length < maxSpaceship) {
      addSpaceshipToken(tokenId);
      addCapacities(capacity);
      console.log("Spaceship selected with capacity:", capacity);
      
    } else {
      console.warn("Maximum number of spaceships reached");
      alert("Maximum number of spaceships reached");
    }
  };
  
  const handleSelectLegionary = (tokenId) => {
    const totalCapacity = spaceshipsCapacities.reduce((total, capacity) => total + capacity, 0);

    if (legionariesTokenIds.length < totalCapacity) {
      addLegionaryToken(tokenId);
    } else {
      console.warn("Maximun Capacity in your legion reached.");
      alert("Maximun Capacity in your legion reached.");
    }
  };

  // CLEANUP FUNCTION ****************************************************************

    useEffect(() => {
      return () => {
        resetState();
      };
    }, []);

  // RENDERING FUNCTIONS ******************************************************

  const renderNftImage = (rankOrCapacity, isLegionary) => {
    const legionaryImages = { "1": R1, "2": R2, "3": R3, "4": R4, "5": R5 };
    const spaceshipImages = { "1": S1, "2": S2, "3": S3, "4": S4, "5": S5 };

    const imageSrc = isLegionary ? legionaryImages[rankOrCapacity] : spaceshipImages[rankOrCapacity];

    return isLegionary ? <LegionaryNftImg src={imageSrc} alt="NFT Image" /> : <SpaceshipNftImg src={imageSrc} alt="NFT Image" />;
  };

  const renderRankImage = (rankOrCapacity, isLegionary) => {
    const rankImages = { "1": R1rank, "2": R2rank, "3": R3rank, "4": R4rank, "5": R5rank };

    return isLegionary ? <LegionaryRankImg src={rankImages[rankOrCapacity]} alt="Rank Image" /> : <SpaceshipRankImg src={rankImages[rankOrCapacity]} alt="Rank Image" />;
  };

  const renderRankName = (rankOrCapacity, isLegionary) => {
    const rankNames = {
      legionaries: { "1": "Hastatus", "2": "Opitionis", "3": "Decurian", "4": "Centurian", "5": "Praetorian" },
      spaceships: { "1": "Banshee", "2": "Fighter", "3": "Vessel", "4": "Hunter", "5": "Corvette" },
    };

    const rankName = isLegionary ? rankNames.legionaries[rankOrCapacity] : rankNames.spaceships[rankOrCapacity];

    return <Rank>{rankName}</Rank>;
  };

  const formatAddress = (id) => {
    if (!id) return '';
    const firstFive = id.slice(0, 5);
    const lastSix = id.slice(-6);
    return `${firstFive}...${lastSix}`;
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleBack = () => {
    navigate('/Legions');
  };
  
  const renderLegionaries = () => {
    return (
      <ScrollableLegionaries>
      <>
        {nftTokens
        .filter(token => !token.nft.isUsed)
        .filter(token => token.nft.legionaryId_ !== "0x0000000000000000000000000000000000000000000000000000000000000000" && !legionariesTokenIds.includes(token.id)).map(token => (
          <LegionaryNftContainer key={token.id}>
            <p>Rank: {token.nft.rank_ ? token.nft.rank_.toString() : "N/A"}</p>
            {renderNftImage(token.nft.rank_ ? token.nft.rank_.toString() : "0", true)}
            {renderRankName(token.nft.rank_ ? token.nft.rank_.toString() : "0", true)}
            <LegionaryRankImgContainer>{renderRankImage(token.nft.rank_ ? token.nft.rank_.toString() : "0", true)}</LegionaryRankImgContainer>
            <LegionaryUniqId>ID: {token.nft.legionaryId_ ? formatAddress(token.nft.legionaryId_) : "N/A"} {"  "} </LegionaryUniqId>
            <LegionaryName>{GetName(token.nft.legionaryName_)} {GetLastName(token.nft.lastName_)}</LegionaryName>
            <LegionaryMiningPower>Mining P<br /><center>{token.nft.miningPower_ ? token.nft.miningPower_.toString() : "N/A"}</center></LegionaryMiningPower>
            <LegionaryCombatPower>Combat P<br /><center>{token.nft.combatPower_ ? token.nft.combatPower_.toString() : "N/A"}</center></LegionaryCombatPower>
            <LegionaryStamina>HP: {token.nft.stamina_ ? token.nft.stamina_.toString() : "N/A"}%</LegionaryStamina>
            <StyledButton onClick={() => handleSelectLegionary(token.id)}>Enlist</StyledButton>
          </LegionaryNftContainer>
        ))}
      </>
      </ScrollableLegionaries>
    );
  };
  
  const renderSpaceships = () => {
    return (
      <ScrollableShips>
      <>
        {nftTokens
        .filter(token => !token.nft.isUsed)
        .filter(token => token.nft.spaceshipId_ !== "0x0000000000000000000000000000000000000000000000000000000000000000" && !spaceshipsTokenIds.includes(token.id)).map(token => (
          <SpaceshipNftContainer key={token.id}>
            <p>Capacity: {token.nft.capacity_ ? token.nft.capacity_.toString() : "N/A"}</p>
            {renderNftImage(token.nft.capacity_ ? token.nft.capacity_.toString() : "0", false)}
            {renderRankName(token.nft.capacity_ ? token.nft.capacity_.toString() : "0", false)}
            <SpaceshipRankImgContainer>{renderRankImage(token.nft.capacity_ ? token.nft.capacity_.toString() : "0", false)}</SpaceshipRankImgContainer>
            <SpaceshipUniqId>ID: {token.nft.spaceshipId_ ? formatAddress(token.nft.spaceshipId_) : "N/A"} {"  "} </SpaceshipUniqId>
            <SpaceshipName>{GetName(token.nft.spaceshipName_)} {GetLastName(token.nft.spaceshipReg_)}</SpaceshipName>
            <SpaceshipMiningPower>Capacity<br/><center>{token.nft.capacity_ ? token.nft.capacity_.toString() : "N/A"}</center></SpaceshipMiningPower>
            <SpaceshipCombatPower>Combat P<br/><center>{token.nft.combatSPower_ ? token.nft.combatSPower_.toString() : "N/A"}</center></SpaceshipCombatPower>
            <SpaceshipStamina>OP: {token.nft.operability_ ? token.nft.operability_.toString() : "N/A"}%</SpaceshipStamina>
            <SpaceshipStyledButton onClick={() => handleSelectSpaceship(token.nft.tokenId_, token.nft.capacity_)}>Provide</SpaceshipStyledButton>
          </SpaceshipNftContainer>
        ))}
      </>
      </ScrollableShips>
    );
  };
  
  
  return (
    <PrincipalContainer>
      <PanelContainer>
        <PanelCell>
        <BackButton onClick={handleBack}/>
        </PanelCell>
        <PanelCell>
          <SpaceshipsButton onClick={() => setShowLegionaries(false)} />
        </PanelCell>
        <PanelCell>
          <LegionariesButton onClick={() => setShowLegionaries(true)} />
        </PanelCell>
      </PanelContainer>
  
      <SecundaryContainer>
        <ScrollableContainer showLegionaries={showLegionaries}>
            {showLegionaries ? renderLegionaries() : renderSpaceships()}
        </ScrollableContainer>

        <LegionManagerContainer isOpen={isOpen}>
          <ToggleButton isOpen={isOpen} onClick={toggleSidebar}>
            {isOpen ? '>>' : '<<'}
          </ToggleButton>
          <Content isOpen={isOpen}>
          <CreateLegionManager isOpen={isOpen} />
          </Content>
        </LegionManagerContainer>

      </SecundaryContainer>
    </PrincipalContainer>
  );
};

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

// Toggle Buttons Panel

const PanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 2fr));
  place-items: center;
  min-height: 10rem;
  padding: 0;
  margin: 0 auto;
  margin-top: 10vw;
  width: 70%;
  z-index: 2;

  @media (min-width: 1200px) and (max-width: 1400px) {
    
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 70%;
    margin-top: 10vw;
    gap: 0.5rem;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 96%;
    margin-top: 30vw;
    gap: 0.5rem;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    & > :nth-child(1) {
      display: none; /* Oculta el tercer PanelCell */
    }
    grid-template-columns: repeat(2, minmax(150px, 2fr));
    width: 96%;
    margin-top: 45vw;
    gap: 0.5rem;

  }

  @media (max-width: 479px) {
    & > :nth-child(1) {
      display: none; /* Oculta el tercer PanelCell */
    }
    grid-template-columns: repeat(2, minmax(150px, 2fr));
    width: 96%;
    margin-top: 50vw;
    gap: 0.5rem;
  }
`;

const PanelCell = styled.div`
  background-color: #0c0d10;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(141, 81, 207, 0.8);
  height: 55%;
  width: 60%;
  @media (min-width: 1200px) and (max-width: 1400px) {
    
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 70%;
  }

  @media (max-width: 479px) {
    width: 80%;
    height: 35%;
  }
`;

// Scrollables NFT container styles

// Legion Creator Space

const LegionManagerContainer = styled.div`
  width: ${(props) => (props.isOpen ? '500px' : '30px')};
  transition: width 0.3s ease; 
  background-color: #0c0d10;
  color: white;
  height: 100vh; 
  position: fixed; 
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;

  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${(props) => (props.isOpen ? '500px' : '0px')};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${(props) => (props.isOpen ? '500px' : '0px')};
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: ${(props) => (props.isOpen ? '99%' : '0px')};
  }

  @media (max-width: 479px) { 
    height: 100%;
    width: ${(props) => (props.isOpen ? '99%' : '0px')}; 
  }
`;

const ToggleButton = styled.button`
  width: 28px;
  left: 0%;
  position: absolute;
  top: 45%;
  height: 100px;
  background-color: #0c0d10;
  color: #107BB3;
  border: 1px solid #107BB3;
  transform: ${(props) => (props.isOpen ? 'translateX(0px)' : 'translateX(-30px)')};
  border-radius: ${(props) => (props.isOpen ? '0 10px 10px 0' : '10px 0 0 10px')};
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const Content = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  width: 98%;
  @media (min-width: 1200px) and (max-width: 1400px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {
    height: 100%
  }
`;


const SecundaryContainer = styled.div`

  width: 98%;
  height: 74vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
`;

// NFT Viewport

const ScrollableContainer = styled.div`
    
    
    overflow-x: hidden;
    width: 100%;
    height: 70vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*Scrollbar*/
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      position: relative;
      height: 80vh;

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      position: relative;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      overflow-x: auto;
    }
  `;

  const ScrollableLegionaries = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 2rem;
  margin-left: 7%;
  margin-right: 7%;
  width: 86%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    justify-items: center;
  }
   @media screen and (min-width: 992px) and (max-width: 1199px){
    margin-left: 0%;
    margin-right: 0%;
    height: 100%;
    width: 90%;
    padding-top: 5%;
    padding-right: 12%;
    padding-left: 12%;
    gap: 2rem;
    justify-items: center;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-left: 0%;
    margin-right: 0%;
    height: 100%;
    width: 100%;
    padding-top: 5%;
    gap: 1rem;
  }
  @media screen and (min-width: 480px) and (max-width: 767px) {
    height: 100%;
    width: 86%;
    padding-top: 5%;
    padding-left: 0rem;
    padding-right: 0rem;
    justify-items: center;
    gap: 0rem;
  }
   @media screen and (max-width: 479px) {
    height: 100%;
    width: 100%;
    padding-top: 5%;
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 0rem;
    padding-right: 0rem;
    justify-items: center;
    gap: 0rem;
  }
`;

const ScrollableShips = styled.div`
position: relative;
display: grid;
grid-template-columns: repeat(3, minmax(250px, 1fr));
gap: 1.5rem;
padding: 2rem;
margin-left: 7%;
margin-right: 5%;
width: 88%;
overflow-x: auto;
overflow-y: auto;
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  justify-items: center;
  grid-template-columns: repeat(2, minmax(400px, 1fr));
  gap: 1rem;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 1fr));
  grid-auto-rows: 300px;
  margin-left: 0%;
  margin-right: 0%;
  width: 80%;
  height: 100%;
  padding-right: 8%;
  padding-left: 16%;
  justify-items: center;
  align-items: center; /* Alinear también verticalmente */
  gap: 3rem; /* Espacio entre filas y columnas */
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  margin-left: 0%;
  margin-right: 0%;
  justify-items: center;
  grid-template-columns: repeat(2, minmax(400px, 1fr));
  grid-auto-rows: 300px;
  height: 100%;
  width: 100%;
  padding-top: 5%;
  gap: 2rem;
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  grid-template-columns: repeat(1, minmax(500px, 1fr));
  height: 100%;
  width: 86%;
  padding-top: 5%;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-items: center;
  gap: 0rem;
}
 @media screen and (max-width: 479px) {
  grid-template-columns: repeat(1, minmax(500px, 1fr));
  height: 100%;
  width: 500%;
  margin-left: 0%;
  margin-right: 0%;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-items: center;
  gap: 0rem;
}
`;

// Legionaries Styles

const LegionaryNftContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 29rem;
    width: 13.5rem;
    margin: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0 4px 6px rgba(16, 123, 179, 0.8);
      transform: scale(1.1);
    } 
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (max-width: 426px)
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const LegionaryNftImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
`;

const LegionaryRankImgContainer = styled.div`
    position: relative;
    left: 3%;
    top: 59%;
    height: 7px;
    width: 83%;
    z-index: 1;
    clip-path: polygon(0 100%, 100% 100%, 80% 0, 20% 0);
    padding: 0.5rem 0.8rem;
    background-color: #242124;
    display: flex;
    justify-content: center; /* Alinear horizontalmente al centro */
    align-items: center; /* Alinear verticalmente al centro */
`;

const LegionaryRankImg = styled.img`
    max-width: 80%; 
    max-height: 80%; 
    height: auto; 
    top: 1%;
    z-index: 3;
    position: absolute;
`;

const Rank = styled.p`
    margin: 0;
    position: absolute;
    top: 0; 
    left: 3%;
    color: #107BB3; 
    background-color: #242124;
    height: 7px;
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    box-shadow:  5px 10px rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0.8rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
    z-index: 3; 
`;

const LegionaryUniqId = styled.p`
    position: absolute;
    top: 71.1%; 
    left: 1px; 
    padding-right: 3px;
    padding-left: 1px;
    color: #F5F5DC;
    font-weight: bold; 
    font-size: 14px;
    background-color: rgba(141, 81, 207, 0.8); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const LegionaryName = styled.p`
    position: absolute;
    top: 2.3%; 
    left: 0; 
    padding: 1px 3px 1px 3px;
    color: white;
    font-size: 15px;
    font-weight: normal; 
    background-color: #1b1b1b; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
    
`;

const LegionaryMiningPower = styled.p`
    position: absolute;
    white-space: pre-line;
    top: 77%; /* Ajusta según sea necesario */
    left: 14%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const LegionaryCombatPower = styled.p`
    position: absolute;
    top: 77%; /* Ajusta según sea necesario */
    left: 55%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const LegionaryStamina = styled.p`
    position: absolute;
    top: 71.1%; /* Ajusta según sea necesario */
    right: 1px; /* Ajusta según sea necesario */
    padding-right: 1px;
    padding-left: 3px;
    color: #F5F5DC; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    font-size: 14px;
    background-color: rgba(16, 123, 179, 0.7); /* Color de fondo con transparencia */
    border-radius: 5px 0 0 5px;/* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const StyledButton = styled.button`
    position: absolute;
    top:90%;
    left: 32%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 80px;
    height: 30px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

// Spaceships NFT Styles

const SpaceshipNftContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 17rem;
    width: 25rem;
    margin: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0 4px 6px rgba(16, 123, 179, 0.8);
      transform: scale(1.1);
    } 
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (max-width: 426px)
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const SpaceshipNftImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
  
`;

const SpaceshipRankImgContainer = styled.div`
    position: relative;
    left: 5%;
    top: 55.5%;
    height: 7px;
    width: 83%;
    z-index: 1;
    clip-path: polygon(0 100%, 100% 100%, 80% 0, 20% 0);
    padding: 0.5rem 0.8rem;
    background-color: #242124;
    display: flex;
    justify-content: center; /* Alinear horizontalmente al centro */
    align-items: center; /* Alinear verticalmente al centro */
`;

const SpaceshipRankImg = styled.img`
    max-width: 80%; /* Ajustado el ancho máximo */
    max-height: 80%; /* Ajustado el alto máximo */
    height: auto; /* Para mantener la relación de aspecto */
    z-index: 3;
    position: absolute;
`;

const SpaceshipUniqId = styled.p`
    position: absolute;
    top: 60%; 
    left: 1px; 
    padding-right: 3px;
    padding-left: 1px;
    color: #F5F5DC;
    font-weight: bold; 
    font-size: 14px;
    background-color: rgba(141, 81, 207, 0.8); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const SpaceshipName = styled.p`
    position: absolute;
    top: 2.3%; 
    left: 0; 
    padding: 1px 3px 1px 3px;
    color: white;
    font-size: 15px;
    font-weight: normal; 
    background-color: #1b1b1b; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
    
`;

const SpaceshipMiningPower = styled.p`
    position: absolute;
    white-space: pre-line;
    top: 79%; /* Ajusta según sea necesario */
    left: 14%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const SpaceshipCombatPower = styled.p`
    position: absolute;
    top: 79%; /* Ajusta según sea necesario */
    left: 68%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const SpaceshipStamina = styled.p`
    position: absolute;
    top: 60%; /* Ajusta según sea necesario */
    right: 1px; /* Ajusta según sea necesario */
    padding-right: 1px;
    padding-left: 3px;
    color: #F5F5DC; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    font-size: 14px;
    background-color: rgba(16, 123, 179, 0.7); /* Color de fondo con transparencia */
    border-radius: 5px 0 0 5px;/* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const SpaceshipStyledButton = styled.button`
    position: absolute;
    top:86%;
    left: 40%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 80px;
    height: 30px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

export default CreateLegionsContainer;




