function importPlanets(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }
  
  const planets = importPlanets(require.context('../Assets/Planets', false, /\.png$/));
  
  export default planets;