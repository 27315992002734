import styled, { keyframes } from 'styled-components';
import left from '../../Assets/Decorative/Buttons/left.png';
import right from '../../Assets/Decorative/Buttons/right.png';
import back from '../../Assets/Decorative/Buttons/back.png';
import create from '../../Assets/Decorative/Buttons/create.png';
import createLegion from '../../Assets/Decorative/Buttons/createlegion.png';

const slideLeft = keyframes`
  0% {
    transform: translateX(30%); 
  }
  100% {
    transform: translateX(-180%); 
  }
`;

const slideRight = keyframes`
  0% {
    transform: translateX(-30%); 
  }
  100% {
    transform: translateX(185%); 
  }
`;

const slideRightBack = keyframes`
  0% {
    transform: translateX(200%); 
  }
  100% {
    transform: translateX(-30%); 
  }
`;

const slideLeftBack = keyframes`
  0% {
    transform: translateX(-195%); 
  }
  100% {
    transform: translateX(30%);
  }
`;

const flickerIn = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const HologramButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 220px; /* Ajusta el tamaño del botón según el tamaño de las imágenes */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .left-icon {
    animation: ${slideLeft} 0.5s forwards;
  }

  &:hover .right-icon {
    animation: ${slideRight} 0.5s forwards;
  }

  &:hover .back-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 0.9;
  }

  &:hover .create-icon {
    visibility: hidden;
    opacity: 0;
  }

  &:hover .createlegion-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 1;
  }

  &:not(:hover) .create-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 1;
  }

  &:not(:hover) .left-icon {
    animation: ${slideLeftBack} 0.5s forwards;
  }

  &:not(:hover) .right-icon {
    animation: ${slideRightBack} 0.5s forwards;
  }
`;

const HologramButton_ = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 200px; /* Ajusta el tamaño del botón según el tamaño de las imágenes */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .left-icon {
    animation: ${slideLeft} 0.5s forwards;
  }

  &:hover .right-icon {
    animation: ${slideRight} 0.5s forwards;
  }

  &:hover .back-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 0.9;
  }

  &:hover .create-icon {
    visibility: hidden;
    opacity: 0;
  }

  &:hover .createlegion-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 1;
  }

  &:not(:hover) .create-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 1;
  }

  &:not(:hover) .left-icon {
    animation: ${slideLeftBack} 0.5s forwards;
  }

  &:not(:hover) .right-icon {
    animation: ${slideRightBack} 0.5s forwards;
  }
`;

const LeftIcon = styled.img.attrs({
    src: left
  })`
    position: absolute;
    left: 25%;
    width: 13%;
    height: auto;
    z-index: 2;
  `;
  
  const RightIcon = styled.img.attrs({
    src: right
  })`
    position: absolute;
    right: 25%;
    width: 13%;
    height: auto;
    z-index: 2; 
  `;
  
  const BackIcon = styled.img.attrs({
      src: back
    })`
    position: absolute;
    width: 95%;
    height: auto;
    z-index: 1; 
    visibility: hidden; 
    opacity: 0;
  `;

  const CreateIcon = styled.img.attrs({
      src: create
    })`
      position: absolute;
      width: 95%;
      height: auto;
      z-index: 1; 
      visibility: visible; 
      opacity: 1;
    `;

    const CreateLegionIcon = styled.img.attrs({
      src: createLegion
    })`
      position: absolute;
      width: 95%;
      height: auto;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
    `;
  
  const LeftIcon_ = styled.img.attrs({
    src: left
  })`
    position: absolute;
    left: 33%;
    width: 10%;
    height: auto;
    z-index: 2;
  `;
  
  const RightIcon_ = styled.img.attrs({
    src: right
  })`
    position: absolute;
    right: 33%;
    width: 10%;
    height: auto;
    z-index: 2; 
  `;
  
  const BackIcon_ = styled.img.attrs({
      src: back
    })`
      position: absolute;
      width: 70%;
      height: auto;
      z-index: 1; 
      visibility: hidden; 
      opacity: 0;
    `;

  const CreateIcon_ = styled.img.attrs({
      src: create
    })`
      position: absolute;
      width: 70%;
      height: auto;
      z-index: 1; 
      visibility: visible; 
      opacity: 1;
    `;

  const CreateLegionIcon_ = styled.img.attrs({
    src: createLegion
  })`
    position: absolute;
    width: 70%;
    height: auto;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
  `;
  
    export const MintLegion = ({ onClick }) => {
        return (
          <HologramButton onClick={onClick}>
            <BackIcon className="back-icon" />
            <LeftIcon className="left-icon" />
            <RightIcon className="right-icon" />
            <CreateIcon className="create-icon" />
            <CreateLegionIcon className="createlegion-icon" />
          </HologramButton>
        );
    };

    export const MintLegion_ = ({ onClick }) => {
      return (
          <HologramButton_ onClick={onClick}>
            <BackIcon_ className="back-icon" />
            <LeftIcon_ className="left-icon" />
            <RightIcon_ className="right-icon" />
            <CreateIcon_ className="create-icon" />
            <CreateLegionIcon_ className="createlegion-icon" />
          </HologramButton_>
      );
  };
