const ContractsAddresses = {
    primarisToken: "0xA60f5db0bB6beBBDA0de4BcB477A1d3A09125d77",
    primarisOracle: "0x6f5747223C05C6D570Afb90949acA848DA099831",
    randomness: "",
    automation: "0x623B92733979567F6076B7259134E38E1B8fbBe9",
    rewardPool: "0xCb09924d06e494ae7B0dbfA706526b0ab7DA81ae",
    legionary: "0xA56191b7E63B3c5Df715d9069d0c65f4fa129902",
    spaceship: "0x59B05F19c774057b5357f626C0C704F70227c263",
    legion: "0x661A0E1514837D98D8cc86eE88120A972203ea62",
    legionUtils: "0x5054DAFe2F6324e483A5Dfe8393BCe332823dcBd",
    legionManagement: "0xb4209ab10Ed7729f17137d5DF5c972d981dfA627",
    warTribute: "0xA5C02b3009E7036C4d2Daf0D8159e2aF11D9f279",
    campaigns: "0x893A8b7dce0843F01e9080ab1814FCC40d45e6Ee",
    fusionPlant: "0xAFccFB84122f92E56402C1eAef2856796448f5ef",
    spaceStation: "0x425d98812aFa483e6BF442ae24743Fe727896d9A",
    faucet: "0x9123a16718b052D2C59247eF28bd99Bcb31E558B",
    approvalAmount: 9999999999999999999999999999999999999999999999999,
  };
  
  export default ContractsAddresses;