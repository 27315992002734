import styled, { keyframes } from 'styled-components';
import left from '../../Assets/Decorative/Buttons/left_.png';
import right from '../../Assets/Decorative/Buttons/right_.png';
import back from '../../Assets/Decorative/Buttons/back_.png';
import backButton from '../../Assets/Decorative/Buttons/backbutton.png';
import spaceships from '../../Assets/Decorative/Buttons/spaceships.png';
import legionaries from '../../Assets/Decorative/Buttons/legionaries.png';
import get from '../../Assets/Decorative/Words/get.png';
import getPrimaris from '../../Assets/Decorative/Words/getprimaris.png';

const slideLeft = keyframes`
  0% {
    transform: translateX(30%); 
  }
  100% {
    transform: translateX(-150%); 
  }
`;

const slideRight = keyframes`
  0% {
    transform: translateX(-30%); 
  }
  100% {
    transform: translateX(155%); 
  }
`;

const slideRightBack = keyframes`
  0% {
    transform: translateX(200%); 
  }
  100% {
    transform: translateX(-30%); 
  }
`;

const slideLeftBack = keyframes`
  0% {
    transform: translateX(-195%); 
  }
  100% {
    transform: translateX(30%);
  }
`;

const flickerIn = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const HologramButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 250px; /* Ajusta el tamaño del botón según el tamaño de las imágenes */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .left-icon {
    animation: ${slideLeft} 0.5s forwards;
  }

  &:hover .right-icon {
    animation: ${slideRight} 0.5s forwards;
  }

  &:not(:hover) .left-icon {
    animation: ${slideLeftBack} 0.5s forwards;
  }

  &:not(:hover) .right-icon {
    animation: ${slideRightBack} 0.5s forwards;
  }

  &:hover .back-icon {
    animation: ${flickerIn} 0.5s forwards;
    visibility: visible;
    opacity: 0.9;
  }

  &:hover .backbutton-icon {
    opacity: 1;
  }

  &:not(:hover) .backbutton-icon {
    animation: ${flickerIn} 0.5s forwards;
  }

  &:hover .spabutton-icon {
    opacity: 1;
  }

  &:not(:hover) .spabutton-icon {
    animation: ${flickerIn} 0.5s forwards;
    opacity: 0.8;
  }

  &:hover .legbutton-icon {
    opacity: 1;
  }

  &:not(:hover) .legbutton-icon {
    animation: ${flickerIn} 0.5s forwards;
    opacity: 0.8;
  }
  
  &:hover .get-icon {
    opacity: 0;
  }

  &:not(:hover) .get-icon {
    animation: ${flickerIn} 0.5s forwards;
    opacity: 0.9;
  }
  
  &:hover .getprimaris-icon {
    animation: ${flickerIn} 0.5s forwards;
    opacity: 1;
  }

  &:not(:hover) .getprimaris-icon {
    
    opacity: 0;
  }
`;

const LeftIcon = styled.img.attrs({
    src: left
  })`
    position: absolute;
    left: 25%;
    width: 14%;
    height: auto;
    z-index: 2;
  `;
  
  const RightIcon = styled.img.attrs({
    src: right
  })`
    position: absolute;
    right: 25%;
    width: 14%;
    height: auto;
    z-index: 2; 
  `;
  
  const BackIcon = styled.img.attrs({
      src: back
    })`
      position: absolute;
      width: 90%;
      height: auto;
      z-index: 1; 
      visibility: hidden; 
      opacity: 0;
    `;

    const BackButtonIcon = styled.img.attrs({
        src: backButton
      })`
        position: absolute;
        width: 95%;
        height: auto;
        z-index: 1;
      `;

    const LegionariesIcon = styled.img.attrs({
    src: legionaries
    })`
        position: absolute;
        width: 95%;
        height: auto;
        z-index: 1;
    `;

    const SpaceshipsIcon = styled.img.attrs({
    src: spaceships
    })`
        position: absolute;
        width: 95%;
        height: auto;
        z-index: 1;
    `;

    const GetIcon = styled.img.attrs({
      src: get
      })`
          position: absolute;
          width: 95%;
          height: auto;
          z-index: 1;
      `;

      const GetPrimarisIcon = styled.img.attrs({
        src: getPrimaris
        })`
            position: absolute;
            width: 95%;
            height: auto;
            z-index: 1;
        `;
  
    export const BackButton = ({ onClick }) => {
        return (
            <HologramButton onClick={onClick}>
                <BackIcon className="back-icon" />
                <LeftIcon className="left-icon" />
                <RightIcon className="right-icon" />
                <BackButtonIcon className="backbutton-icon" />
            </HologramButton>
        );
    };

    export const SpaceshipsButton = ({ onClick }) => {
        return (
            <HologramButton onClick={onClick}>
                <BackIcon className="back-icon" />
                <LeftIcon className="left-icon" />
                <RightIcon className="right-icon" />
                <SpaceshipsIcon className="spabutton-icon" />
            </HologramButton>
        );
    };

    export const LegionariesButton = ({ onClick }) => {
        return (
            <HologramButton onClick={onClick}>
                <BackIcon className="back-icon" />
                <LeftIcon className="left-icon" />
                <RightIcon className="right-icon" />
                <LegionariesIcon className="legbutton-icon" />
            </HologramButton>
        );
    };

    export const FaucetButton = ({ onClick }) => {
      return (
          <HologramButton onClick={onClick}>
              <BackIcon className="back-icon" />
              <LeftIcon className="left-icon" />
              <RightIcon className="right-icon" />
              <GetIcon className="get-icon" />
              <GetPrimarisIcon className="getprimaris-icon" />
          </HologramButton>
      );
  };

