import React, { useState, useEffect } from 'react';
import { sendLegionToCampaign, getReward, getEnergyRequired, getSuccessChance, getDamage } from '../../../Handlers/CampaignsHandler';
import { useWeb3 } from '../../../Handlers/ChainContext';
import styled from "styled-components";
import planets from '../../../Handlers/ImportingImages';
import legionsAbi from '../../../ABI/ABILegions.json';
import ContractsAddresses from '../../../Handlers/ContractsAdresses';
import axionisLogo from '../../../Assets/Images/NFT/Legions/Axionis/AxionisLogo.png';
import eternisLogo from '../../../Assets/Images/NFT/Legions/Eternis/EternisLogo.png';
import primusLogo from '../../../Assets/Images/NFT/Legions/PrimusVU/PrimusVULogo.png';
import bless from '../Forces/LegionsSupport/omnibless.png';
import energy from '../Forces/LegionsSupport/energyn.png';

/* global BigInt */

const contractLegionsAdd = ContractsAddresses.legion;

const Campaings = () => {
    const { web3, account } = useWeb3(); 
    const [rewards, setRewards] = useState({});
    const [energyReq, setEnergyReq] = useState({});
    const [successChance, setSuccessChance] = useState({});
    const [damage, setDamage] = useState({});
    const [nftTokens, setNftTokens] = useState([]);
    const [currentTokenId, setCurrentTokenId] = useState(null);
    const [selectedLegion, setSelectedLegion] = useState(null);
    const [miningPowerReq, setMiningPowerReq] = useState({});
    const [combatPowerReq, setCombatPowerReq] = useState({}); 
    const [selectedLegionOption, setSelectedLegionOption] = useState("Select your legion...");

    useEffect(() => {
        const loadNFTTokens = async () => {
            if (web3 && account) {
                const contract = new web3.eth.Contract(legionsAbi, contractLegionsAdd);
                const ownedTokens = await contract.methods.getLegion(account).call();
                
                if (Array.isArray(ownedTokens)) {
                    const tokens = ownedTokens.map((token) => ({
                        legionId: token[0],
                        legionaries: token[1],
                        ships: token[2],
                        legionName: web3.utils.hexToUtf8(token[3]),
                        totalCapacity: token[4],
                        totalCombatPower: token[5],
                        totalMiningPower: token[6],
                        warCohort: token[7],
                        warTribute: token[8],
                        energy: token[9],
                        grade: token[10],
                        glory: token[11],
                        moral: token[12],
                        experience: token[13],
                        tokenHash: token[14]
                    }));
                    setNftTokens(tokens);
                } else {
                    console.error('Unexpected structure of ownedTokens:', ownedTokens);
                }
            }
        };

        loadNFTTokens();
    }, [web3, account]);

    const renderFlag = (warCohort) => {
        const flagImages = { "0": axionisLogo, "2": eternisLogo, "1": primusLogo };
        return <LegionFlagImg src={flagImages[warCohort]} alt={`Flag ${warCohort}`} />;
    };

    const LegionDropdown = ({ legions, onSelect, setCurrentTokenId }) => {
        const [isOpen, setIsOpen] = useState(false);
      
        const handleSelect = (legion) => {
            setSelectedLegionOption(legion.legionName);
            setCurrentTokenId(legion.legionId);
            setIsOpen(false);
            onSelect(legion); 
        };
      
        return (
            <DropdownLContainer>
                <DropdownButton onClick={() => setIsOpen(!isOpen)}>
                    {selectedLegionOption}
                </DropdownButton>
                {isOpen && (
                    <DropdownContent>
                        {legions.map((legion, index) => (
                            <DropdownItem key={index} onClick={() => handleSelect(legion)}>
                                <DropdownText>{legion.legionName}</DropdownText>
                            </DropdownItem>
                        ))}
                    </DropdownContent>
                )}
            </DropdownLContainer>
        );
    };

    const handleSendLegionToCampaign = (campaignId) => {
        const legionId = currentTokenId;
        if (campaignId != null && legionId != null) {
            sendLegionToCampaign(campaignId, legionId, web3);
        } else {
            console.log("CAMPAIGN OR LEGION IS NOT SELECTED");
        }
    };

    // Calcula el poder de minería y combate requerido fuera del renderizado
    const calculateRequirements = () => {
        const minMiningPowerReq = {};
        const minCombatPowerReq = {};

        for (let i = 1; i <= 30; i++) {
            minMiningPowerReq[i] = i * 150;

            if (i >= 1 && i <= 10) {
                minCombatPowerReq[i] = 30 + (i - 1) * 5;
            } else if (i >= 11 && i <= 20) {
                minCombatPowerReq[i] = 100 + (i - 11) * 10;
            } else if (i >= 21 && i <= 30) {
                minCombatPowerReq[i] = 220 + (i - 21) * 20;
            }
        }

        setMiningPowerReq(minMiningPowerReq);
        setCombatPowerReq(minCombatPowerReq);
    };

    useEffect(() => {
        calculateRequirements();
    }, []);

    const sortedImages = Object.keys(planets)
        .sort((a, b) => Number(a.replace('.png', '')) - Number(b.replace('.png', '')));

    const getPlanetName = (number) => {
        const planetNames = {
            1: "Trion",
            2: "Zypher",
            3: "Galatus",
            4: "Aetheria",
            5: "Vortex",
            6: "Naiburis",
            7: "Hyperion",
            8: "Xanadú",
            9: "Lyra",
            10: "Druicon",
            11: "Draconis",
            12: "Roymus",
            13: "Elygov",
            14: "Krasnar",
            15: "Xeutlan",
            16: "Cux",
            17: "Vega",
            18: "Vanandri",
            19: "Altair",
            20: "Vexi 3",
            21: "Yizutar",
            22: "Oxilium",
            23: "Conemnis",
            24: "Astomia",
            25: "Novaris",
            26: "Julima",
            27: "Targhonia",
            28: "Proxima Avenis",
            29: "Afnor",
            30: "Priamus Singularity"
        };
        return planetNames[number] || "Unknown Planet";
    };

    useEffect(() => {
        const loadRewards = async () => {
            const rewardsData = {};
            const energyData = {};
            const successData = {};
            const damageData = {};

            for (let i = 1; i <= 30; i++) {
                try {
                    const reward = await getReward(i, web3);
                    const priceBigInt = BigInt(reward);
                    const adjustedPrice = Number(priceBigInt) / 1e18;
                    const formattedPrice = adjustedPrice.toFixed(2);
                    rewardsData[i] = formattedPrice;


                    const energyRequired = await getEnergyRequired(i, web3);
                    energyData[i] = energyRequired;

                    const minCombatPower = combatPowerReq[i];
                    const minMiningPower = miningPowerReq[i];

                    if (selectedLegion) {
                        if (selectedLegion.totalCombatPower < minCombatPower || selectedLegion.totalMiningPower < minMiningPower) {
                            damageData[i] = 'Lethal';
                            successData[i] = 'Insufficient power';
                        } else {
                            const success = await getSuccessChance(i, selectedLegion.grade, selectedLegion.moral, selectedLegion.glory, web3);
                            successData[i] = success + '%';
                    
                            const damageValue = await getDamage(i, selectedLegion.totalCombatPower, web3);
                            damageData[i] = '- ' + damageValue + ' pts';
                        }
                    } else {
                        successData[i] = 'Loading...';
                        damageData[i] = 'Loading...';
                    }

                } catch (error) {
                    console.error(`Error fetching data for campaign ${i}:`, error);
                }
            }

            setRewards(rewardsData);
            setEnergyReq(energyData);
            setSuccessChance(successData);
            setDamage(damageData);
        };

        if (combatPowerReq && miningPowerReq) {
            loadRewards();
        }
    }, [web3, account, selectedLegion, combatPowerReq, miningPowerReq]);

    return (
        <PrincipalContainer>
            <PanelContainer>
                <PanelCell>          
                    <LegionNftContainer>
                        {selectedLegion ? (
                            <>
                                <LegionName>{selectedLegion.legionName}</LegionName>
                                <LegionCombatPower>Combat Power: {selectedLegion.totalCombatPower.toString()}</LegionCombatPower>
                                <LegionMiningPower>Mining Power: {selectedLegion.totalMiningPower.toString()}</LegionMiningPower>
                                <LegionMoral>Morale: {selectedLegion.moral.toString()} %</LegionMoral>
                                <LegionFlagImgContainer>{renderFlag(selectedLegion.warCohort.toString())}</LegionFlagImgContainer>
                                <LegionEnergyBlessContainer>
                                    <LegionBlessImg src= {bless} alt="WarTribute" />
                                    <CounterBless>{selectedLegion.warTribute.toString()}</CounterBless>
                                    <LegionEnergyImg src={energy} alt="Energy"/>
                                    <CounterEnergy>{(selectedLegion.energy.toString())}</CounterEnergy>
                                </LegionEnergyBlessContainer>
                            </>
                        ) : (
                            <NameLegion>Select a legion to start a campaign...</NameLegion>
                        )}
                        <LegionDropdown legions={nftTokens} onSelect={setSelectedLegion} setCurrentTokenId={setCurrentTokenId}  />
                    </LegionNftContainer>
                </PanelCell>
            </PanelContainer>

            <ScrollableContainer>
                <ScrollableContent>
                    <Line />
                    {sortedImages.map((image, index) => {
                        const imageNumber = Number(image.replace('.png', ''));
                        const minMiningPower = miningPowerReq[imageNumber];
                        const minCombatPower = combatPowerReq[imageNumber];

                        const isLegionEligible = selectedLegion && (
                            selectedLegion.totalMiningPower >= minMiningPower &&
                            selectedLegion.totalCombatPower >= minCombatPower
                        );

                        return (
                            <PlanetContainer key={index} disabled={!isLegionEligible}>
                                <PlanetImg src={planets[image]} alt={getPlanetName(imageNumber)}/>
                                <PlanetName >{getPlanetName(imageNumber)}</PlanetName>
                                <RewardTitle>Estimated Reward</RewardTitle>
                                <Reward>{rewards[imageNumber] !== undefined ? `${rewards[imageNumber]} $PRIMARIS` : 'Loading...'}</Reward>
                                <SuccessTitle>Success Rate</SuccessTitle>
                                <SuccessChanceRender disabled={!isLegionEligible}>{successChance[imageNumber] !== undefined ? `${successChance[imageNumber]}` : 'Loading...'}</SuccessChanceRender>
                                <DamageTitle>Imminent Damage</DamageTitle>
                                <DamageRender>{damage[imageNumber] !== undefined ? `${damage[imageNumber]}` : 'Loading...'}</DamageRender>
                                <EnergyTitle>Energy Required</EnergyTitle>
                                <EnergyReqRender>{energyReq[imageNumber] !== undefined ? `${energyReq[imageNumber]}` : 'Loading...'}</EnergyReqRender>
                                <MiningPowerRender><MiningPowerTitle>Mining Power Required: {' '} </MiningPowerTitle> {minMiningPower} </MiningPowerRender>
                                <CombatPowerRender><CombatPowerTitle>Combat Power Required: {' '} </CombatPowerTitle> {minCombatPower }</CombatPowerRender>
                                <StyledButton                                     
                                  onClick={() => handleSendLegionToCampaign(imageNumber)} 
                                  disabled={!isLegionEligible}
                                >
                                  {isLegionEligible ? 'Send Legion' : 'Get more power'}</StyledButton>
                                
                            </PlanetContainer>
                        );
                    })}
                </ScrollableContent>
            </ScrollableContainer>
        </PrincipalContainer>
    );
};

export default Campaings;


// DROPDOWN STYLES

const DropdownLContainer = styled.div`
  position: absolute;
  left: 30%;
  top: -17%;
  width: 40%;
`;

const DropdownButton = styled.button`
  width: 100%;
  height: 20px;
  font-size: 12px;
  padding: 5px;
  background-color: #1b1b1b;
  color: #FFFFFF;
  border: 1px solid #8D51CF;
  border-radius: 5px;
  cursor: pointer;
    display: flex; /* Activar Flexbox */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
`;

const DropdownContent = styled.ul`
  position: absolute;
  z-index: 2;
  font-size: 10px;
  width: 100%;
  background-color: #1b1b1b;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;

  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #242124;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8D51CF;
    border-radius: 10px;
    border: 2px solid #242124;
  }
`;

const DropdownItem = styled.li`
  padding: 10px;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: #8D51CF;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const DropdownText = styled.span`
  flex: 1;
  cursor: pointer;
`;

// PRINCIPAL CONTAINER

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }
`;

// Mint And Counter Panels Styles

const PanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  place-items: center;
  min-height: 10rem;
  padding: 0;
  margin: 0 auto;
  margin-top: 10%;
  width: 70%;
  z-index: 2;
  @media (min-width: 1200px) and (max-width: 1400px) {
    margin-top: 15%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 25%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 90%;
    margin-top: 35%;

  }

  @media (min-width: 480px) and (max-width: 767px) {
    margin-top: 40%;
    width: 100%;
  }

  @media (max-width: 479px) {
     margin-top: 50%;
     width: 100%;
  }

`;

const PanelCell = styled.div`
  background-color: #0c0d10;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8);
  height: 100%;
  width: 50%;
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 80%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 80%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    position: relative;
    width: 80%;
    
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 90%;
  }

  @media (max-width: 479px) {
    width: 100%;
  }

`;

// Scrollable NFT container styles

const ScrollableContainer = styled.div`
  position: relative;
  align-items: flex-center;
  justify-content: center;
  overflow-y: auto;
  width: 99vw;
  height: 70vh;
  box-sizing: border-box;
  justify-content: center;
  
  /*Scrollbar*/
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      position: relative;
      height: 60vh;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      position: relative;
      height: 80vh;

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      position: relative;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      overflow-x: auto;
    }
  `;

  const ScrollableContent = styled.div`
  padding-top: 5%;
  flex-direction: column;
  align-items: center; 
  gap: 1.5rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  justify-content: center;
  display: flex;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {

    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }

    @media screen and (max-width: 479px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      grid-template-columns: repeat(1, minmax(1500px, 3000px));
    }
`;

const Line = styled.div`
  width: 4px; 
  height: 70%; 
  background: linear-gradient(to bottom, #8D51CF, #33c1ff);
  position: fixed;
  top: 5%;
  left: 49.8%;
  transform: translateX(-50%);
  z-index: 0; /* Asegura que esté detrás del contenido */
`;


// planets styles

const PlanetContainer = styled.div`
    background-color: #101010;
    border: 0.01px solid rgba(255,255,255,1);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    filter: ${({ disabled }) => (disabled ? 'brightness(0.5)' : 'brightness(1)')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    height: 450px;
    width: 600px;
    margin-top: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0 4px 6px rgba(16, 123, 179, 0.8);
      transform: scale(1.1);
    } 

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin-top: 3rem;

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      margin-top: 3rem;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      margin-top: 3rem;
    }

    @media screen and (max-width: 479px) {
      margin-top: 3rem;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const PlanetImg = styled.img`
  border-radius: 8px;
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: auto;
  z-index: 0;
`;

const PlanetName = styled.p`
  position: absolute;
  top: 0%; 
  left: 0; 
  padding: 5px 20px 3px 8px;
  color: white;
  font-size: 2rem;
  font-weight: normal; 
  background-color: rgba(0,0,0,0.5); 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const Reward = styled.p`
  position: absolute;
  bottom: 7%; 
  left: 0; 
  padding: 5px 20px 3px 15px;
  color: rgba(255,255,255,0.9);
  font-size: 1.3rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const RewardTitle = styled.p`
  position: absolute;
  bottom: 15%; 
  left: 4.5%; 
  padding: 5px 20px 3px 15px;
  color: rgba(255,255,255,0.9);
  font-size: 0.8rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const SuccessChanceRender = styled.p`
  position: absolute;
  bottom: ${({ disabled }) => (disabled ? '-1%' : '-4%')}; 
  right: 0; 
  padding: 5px 20px 3px 15px;
  color: #107BB3;
  font-size: ${({ disabled }) => (disabled ? '1.5rem' : '1.8rem')};
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const SuccessTitle = styled.p`
  position: absolute;
  bottom: 7%; 
  right: 0%; 
  padding: 5px 20px 3px 15px;
  color: rgba(255,255,255,0.9);
  font-size: 0.8rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const DamageRender = styled.p`
  position: absolute;
  bottom: 9%; 
  right: 0; 
  padding: 5px 20px 3px 15px;
  color: red;
  font-size: 1.2rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const DamageTitle = styled.p`
  position: absolute;
  bottom: 15%; 
  right: 0; 
  padding: 5px 20px 3px 15px;
  color: rgba(255,255,255,0.9);
  font-size: 0.8rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const EnergyReqRender = styled.p`
  position: absolute;
  top: 3%; 
  right: 0; 
  padding: 5px 20px 3px 15px;
  color: #8D51CF;
  font-size: 1rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const EnergyTitle = styled.p`
  position: absolute;
  top: 0%; 
  right: 0%; 
  padding: 5px 20px 3px 15px;
  color: rgba(255,255,255,0.9);
  font-size: 0.8rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const MiningPowerRender = styled.div`
  position: absolute;
  width: 40%;
  display: flex;
  bottom: 7%; 
  left: 2%; 
  color: #8D51CF;
  font-size: 0.8rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1; 
`;

const MiningPowerTitle = styled.div`
  position: relative;
  top: 0; 
  right: 0%; 
  color: rgba(255,255,255,0.9);
  font-size: 0.8rem;
  font-weight: normal; 
  z-index: 1; 
`;

const CombatPowerRender = styled.div`
  position: absolute;
  width: 40%;
  display: flex;
  bottom: 3%; 
  left: 2%; 
  color: #8D51CF;
  font-size: 0.8rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 1;
`;

const CombatPowerTitle = styled.div`
  position: relative;
  top: 0; 
  right: 0%; 
  color: rgba(255,255,255,0.9);
  font-size: 0.8rem;
  font-weight: normal; 
  z-index: 1; 
`;

const StyledButton = styled.button`
  position: absolute;
  top:85%;
  left: 41%;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #218838;
  color: white;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: background-color 0.3s ease;
  width: 18%;
  height: 8%;
  &:hover {
    color: #218838;
    background-color: #FFFFFF;
    transform: scale(1.1);
  } 
`;

// LEGION STYLES ************************************

const NameLegion = styled.p`
  position: absolute;
  top: 0%; 
  left: 0; 
  padding: 5px 20px 3px 8px;
  color: rgba(225, 225, 225, 0.7);
  font-size: 1rem;
  font-weight: normal; 
  border-radius: 0 5px 5px 0;
  z-index: 0; 
`;

const LegionNftContainer = styled.div`
  padding: 0.3rem;
  height: 6rem;
  width: 100%;
  margin: 1rem;
  position: relative;
  @media screen and (min-width: 769px) and (max-width: 1024px){
    margin:0;
  }
  @media screen and (max-width: 426px)
    margin:0;
  }
`;

const LegionFlagImgContainer = styled.div`
    position: absolute;
    right: 0;
    top: 20%;
    height: 43px;
    width: 15%;
    z-index: 1;
    display: flex;
    justify-content: center; 
    align-items: center; 
`;

const LegionFlagImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    height: auto;
    z-index: 3;
    position: absolute;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
 
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      max-width: 80%;
      max-height: 80%;
    }
`;

const LegionName = styled.div`
    position: absolute;
    top: 5%; 
    left: 0; 
    padding: 3px 8px 1px 10px;
    color: white;
    font-size: 30px;
    font-weight: bold; 
    border-radius: 0 5px 5px 0;
    z-index: 1;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 20px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      font-size: 20px;
      top: 10%;
    } 
`;

const LegionMiningPower = styled.div`
    position: absolute;
    white-space: pre-line;
    bottom: 3%; 
    left: 3%; 
    color: #8D51CF; 
    font-size: 15px;
    font-weight: normal; 
    background-color: rgba(0, 0, 0, 0.2); 
    border-radius: 5px; 
    z-index: 1; 
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 15px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      font-size: 13px;
      bottom: 40%; 
      left: 0%; 
    }
`;

const LegionCombatPower = styled.div`
    position: absolute;
    bottom: 25%; 
    left: 3%; 
    color: #8D51CF; 
    font-size: 15px;
    font-weight: normal; 
    background-color: rgba(0, 0, 0, 0.2); 
    border-radius: 5px; 
    z-index: 1; 
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      font-size: 13px;
      bottom: 25%; 
      left: 0%; 
    }
`;

const LegionMoral = styled.div`
    position: absolute;
    bottom: 3%; 
    right: 0; 
    padding-right: 8px;
    padding-left: 6px;
    padding-top: 5px;
    color: #F5F5DC; 
    font-weight: bold; 
    font-size: 18px;
    background-color: rgba(16, 123, 179, 0.5); 
    border-radius: 5px;
    z-index: 1; 
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 15px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      font-size: 12px
    }
`;

const LegionEnergyBlessContainer = styled.div`
  position: relative;
  left: 37%;
  border-radius: 2px;
  top: 35%;
  height: 70%;
  width: 25%;
  z-index: 1;
  display: flex;
  justify-content: center; 
  align-items: center; 
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    height: 45%;
    width: 25%;
    top: 30%;
    left: 45%;
  }
  @media screen and (min-width: 768px) and (max-width: 991px){
    height: 50%;
    width: 25%;
    top: 15%;
  }
  @media screen and (min-width: 480px) and (max-width: 767px) {

  }

  @media screen and (max-width: 479px) {
    height: 40%;
    width: 25%;
    bottom: 0%;
    top: 90%;
    left: 0%;
  }
`;

const LegionEnergyImg = styled.img`
    max-width: 30%;
    max-height: 40%;
    height: auto;
    z-index: 3;
    position: absolute;
    left: 0;
    bottom: 5%;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      max-width: 25%;
      max-height: 35%;

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      max-width: 20%;
      max-height: 35%;
    }
`;

const LegionBlessImg = styled.img`
    max-width: 30%;
    max-height: 40%;
    height: auto;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 5%;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      max-width: 25%;
      max-height: 35%;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      max-width: 20%;
      max-height: 35%;
    }
`;


const CounterBless = styled.div`
    color: white;
    font-size: 16px;
    position: absolute;
    z-indez: 3;
    top: 5%;
    left: 30%;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 15px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      font-size: 15px;
      top: 5%;
      left: 30%;
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      font-size: 13px;
      top: 5%;
      left: 30%;
    }
`;

const CounterEnergy = styled.div`
    color: white;
    font-size: 16px;
    position: absolute;
    z-indez: 3;
    bottom: 5%;
    left: 30%;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 15px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      font-size: 15px;
      bottom: 0%;
      left: 30%;
      
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      font-size: 13px;
      bottom: 10%;
      left: 30%;
    }
`;