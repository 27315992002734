import { useState, useContext, createContext } from 'react';

const SelectedTokensContext = createContext();

export const useSelectedTokens = () => {
  return useContext(SelectedTokensContext);
};

export const SelectedTokensProvider = ({ children }) => {
  const [inLegionSpaceships, setInLegionSpaceships] = useState([]);
  const [inLegionLegionaries, setInLegionLegionaries] = useState([]);
  // CREATING LEGIONS
  const [legionariesTokenIds, setLegionariesTokenIds] = useState([]);
  const [spaceshipsTokenIds, setSpaceshipsTokenIds] = useState([]);
  const [spaceshipsCapacities, setTotalCapacity] = useState([]);
  const [legionTotalCapacity, setLegionTotalCapacity] = useState(0);
  //  ADDING TROOPS TO LEGIONS
  const [legionariesTokenIds_, setLegionariesTokenIds_] = useState([]);
  const [spaceshipsTokenIds_, setSpaceshipsTokenIds_] = useState([]);
  const [spaceshipsCapacities_, setTotalCapacity_] = useState([]);
  const [legionTotalCapacity_, setLegionTotalCapacity_] = useState(0); 

  const shareInLegionTotalCapacity = (capacity) => {
    setLegionTotalCapacity(capacity); 
  };

  const shareInLegionLegionaries = (legionaries) => {
    setInLegionLegionaries(legionaries);
  };

  const shareInLegionSpaceships = (spaceships) => {
    setInLegionSpaceships(spaceships);
  };

  // CREATING LEGIONS ******************************************************

  const addLegionaryToken = (tokenId) => {
    setLegionariesTokenIds([...legionariesTokenIds, tokenId]);
  };

  const addSpaceshipToken = (tokenId) => {
    setSpaceshipsTokenIds([...spaceshipsTokenIds, tokenId]);
  };

  const removeLegionaryToken = (tokenId) => {
    setLegionariesTokenIds(legionariesTokenIds.filter(id => id !== tokenId));
  };

  const removeSpaceshipToken = (tokenId) => {
    const index = spaceshipsTokenIds.indexOf(tokenId);
    if (index > -1) {
      setSpaceshipsTokenIds(spaceshipsTokenIds.filter(id => id !== tokenId));
      setTotalCapacity((prevCapacities) => {
        const newCapacities = prevCapacities.filter((_, i) => i !== index);
        console.log("Current spaceship capacities after removal:", newCapacities);
        return newCapacities;
      });
    }
  };
  
  const addCapacities = (capacity) => {
    setTotalCapacity((prevCapacities) => {
      const newCapacities = [...prevCapacities, capacity];
      console.log("Current spaceship capacities:", newCapacities);
      return newCapacities;
    });
  };

  const resetState = () => {
    setLegionariesTokenIds([]);
    setSpaceshipsTokenIds([]);
    setTotalCapacity([]);
    setLegionTotalCapacity(0);
    setInLegionSpaceships([]);
    setInLegionLegionaries([]);
  };

  // Adding Troops ******************************************************

  const addLegionaryToken_ = (tokenId) => {
    setLegionariesTokenIds([...legionariesTokenIds, tokenId]);
  };

  const addSpaceshipToken_ = (tokenId) => {
    setSpaceshipsTokenIds([...spaceshipsTokenIds, tokenId]);
  };

  const removeLegionaryToken_ = (tokenId) => {
    setLegionariesTokenIds(legionariesTokenIds.filter(id => id !== tokenId));
  };

  const removeSpaceshipToken_ = (tokenId) => {
    const index = spaceshipsTokenIds.indexOf(tokenId);
    if (index > -1) {
      setSpaceshipsTokenIds(spaceshipsTokenIds.filter(id => id !== tokenId));
      setTotalCapacity((prevCapacities) => {
        const newCapacities = prevCapacities.filter((_, i) => i !== index);
        console.log("Current spaceship capacities after removal:", newCapacities);
        return newCapacities;
      });
    }
  };
  
  const addCapacities_ = (capacity) => {
    setTotalCapacity((prevCapacities) => {
      const newCapacities = [...prevCapacities, capacity];
      console.log("Current spaceship capacities:", newCapacities);
      return newCapacities;
    });
  };

  const resetState_ = () => {
    setLegionariesTokenIds_([]);
    setSpaceshipsTokenIds_([]);
    setTotalCapacity_([]);
    setLegionTotalCapacity_(0);
  };

  return (
    <SelectedTokensContext.Provider value={{ 
      inLegionSpaceships,
      inLegionLegionaries,
      legionTotalCapacity,
      legionariesTokenIds, 
      spaceshipsTokenIds, 
      spaceshipsCapacities,
      legionTotalCapacity_,
      legionariesTokenIds_, 
      spaceshipsTokenIds_, 
      spaceshipsCapacities_,
      shareInLegionTotalCapacity,
      shareInLegionLegionaries,
      shareInLegionSpaceships,
      addLegionaryToken, 
      addSpaceshipToken, 
      removeLegionaryToken, 
      removeSpaceshipToken,
      addCapacities,
      resetState,
      addLegionaryToken_, 
      addSpaceshipToken_, 
      removeLegionaryToken_, 
      removeSpaceshipToken_,
      addCapacities_,
      resetState_ }}>
      {children}
    </SelectedTokensContext.Provider>
  );
};
