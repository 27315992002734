import React from 'react';
import styled from 'styled-components';
import SummoningImage from '../Assets/summoning.png';
import Banner from '../Assets/banner_.png';


export default function ComingSoon() {

    return (
        <Modal>
            <ImageContainer>
                <SummoningStyle src={SummoningImage} alt="Summoning"/>
                <BannerStyle src={Banner} alt="Banner"/>
            </ImageContainer>  
        </Modal>
    );
}

const Modal = styled.div`
    position: fixed;
    z-index: 6;
    top: 0%;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.7);
`;

const ImageContainer = styled.div`
    position: relative;
    width: 30%; 
    height: auto;  
`;

const SummoningStyle = styled.img`
    width: 100%;
    height: auto;
`;

const BannerStyle = styled.img`
    width: 100%;
    height: auto;
`;
