import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Campaing from "../Assets/Images/MissionsScreen/campaing_.jpeg";
import Ritual from "../Assets/Images/MissionsScreen/ritual_.jpeg";
import WarCohort from "../Assets/Images/MissionsScreen/warCohort_.png";

export default function Forces() {
    const [hovered, setHovered] = useState({
        campaing: false,
        ritual: false,
        warCohort: false,
    });

    const handleMouseEnter = (image) => {
        setHovered((prevState) => ({ ...prevState, [image]: true }));
    };

    const handleMouseLeave = (image) => {
        setHovered((prevState) => ({ ...prevState, [image]: false }));
    };

    return (
            <TriangleGrid>
                <DiagonalLine />
                <DiagonalLine_1 />
                <HorizontalLine />
                <TopContainer>

                    <CampaignsImageContainer 
                        onMouseEnter={() => handleMouseEnter('campaing')} 
                        onMouseLeave={() => handleMouseLeave('campaing')}
                    >
                        <Link to="/Campaings">
                        <InteractiveImage 
                            src={Campaing} 
                            alt="Campaing" 
                            hovered={hovered.campaing} 
                        />
                        <HoverText className={hovered.campaing ? 'hovered' : ''}>
                            CAMPAINGS
                        </HoverText>
                        </Link>
                    </CampaignsImageContainer >
                            
                </TopContainer>

                <BottomContainer>


                    <AstromancersImageContainer 
                        onMouseEnter={() => handleMouseEnter('ritual')} 
                        onMouseLeave={() => handleMouseLeave('ritual')}
                    > 
                        <Link to="/ComingSoon">
                        <InteractiveImage 
                            src={Ritual} 
                            alt="Ritual" 
                            hovered={hovered.ritual} 
                        />
                        <HoverText className={hovered.ritual ? 'hovered' : ''}>
                            ASTROMANCERS RITUAL
                        </HoverText>
                        </Link>
                    </AstromancersImageContainer>
                            
                    <WarCohortImageContainer 
                        onMouseEnter={() => handleMouseEnter('warCohort')} 
                        onMouseLeave={() => handleMouseLeave('warCohort')}
                    > 
                        <Link to="/ComingSoon">
                        <InteractiveImage 
                            src={WarCohort} 
                            alt="WarCohort" 
                            hovered={hovered.warCohort} 
                        />
                        <HoverText className={hovered.warCohort ? 'hovered' : ''}>
                            WAR COHORT
                        </HoverText> 
                        </Link>
                    </WarCohortImageContainer>
                            
                </BottomContainer>
            </TriangleGrid>
        );

}

const TriangleGrid = styled.div`
    display: flex;
    flex-direction: column; /* Asegura que los contenedores sigan el flujo natural de la página */
    align-items: center;
    width: 100%;
    height: 60%
    margin-left: 10%;
    
    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 20% 0 0 0;
    }
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: center; /* Centra el contenido de Row2 */
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        justify-content: center; /* Centra el contenido en pantallas pequeñas */
        margin-top: 20%;
    }
`;

const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between; /* Distribuye Row1 y Row3 en los extremos */
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }
`;

const AstromancersImageContainer = styled.div`
    position: relative;
    width: 30%;
    height: auto;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 90%;
        justify-content: center;
    }
`;

const CampaignsImageContainer = styled.div`
    position: relative;
    width: 30%;
    height: auto;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 90%;
        justify-content: center;
    }
`;

const WarCohortImageContainer = styled.div`
    position: relative;
    width: 30%;
    height: auto;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 90%;
        justify-content: center;
    }
`;

const InteractiveImage = styled.img`
    width: 100%;
    height: auto;
    opacity: 0.5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    z-index: 3;

    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transform: ${props => props.hovered ? 'scale(1.1)' : 'scale(1)'};
    opacity: ${props => props.hovered ? '1' : '0.5'};
`;

const HoverText = styled.div`
    position: absolute;
    width: auto;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 6;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
    display: inline-block; /* Ajustar al ancho del texto */
    &.hovered {
        opacity: 1;
        bottom: 8px;
    }
`;

const DiagonalLine = styled.div`
    position: absolute;
    grid-column: 3;
    grid-row: 1;
    top: 38%;
    right: 20%;
    width: 20%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(45deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
`;

const DiagonalLine_1 = styled.div`
    position: absolute;
    grid-column: 1;
    grid-row: 1;
    top: 38%;
    left: 20%;
    width: 20%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(135deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
`;

const HorizontalLine = styled.div`
    position: absolute;
    grid-column: 2;
    grid-row: 2;
    bottom: 30%;
    left: 40%;
    width: 20%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(180deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
`;
