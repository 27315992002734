import Web3 from 'web3';
import legionsAbi from '../../../ABI/ABILegions.json';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axionis1 from '../../../Assets/Images/NFT/Legions/Axionis/1.png';
import axionis2 from '../../../Assets/Images/NFT/Legions/Axionis/2.png';
import axionis3 from '../../../Assets/Images/NFT/Legions/Axionis/3.png';
import axionis4 from '../../../Assets/Images/NFT/Legions/Axionis/4.png';
import axionis5 from '../../../Assets/Images/NFT/Legions/Axionis/5.png';
import eternis1 from '../../../Assets/Images/NFT/Legions/Eternis/1.png';
import eternis2 from '../../../Assets/Images/NFT/Legions/Eternis/2.png';
import eternis3 from '../../../Assets/Images/NFT/Legions/Eternis/3.png';
import eternis4 from '../../../Assets/Images/NFT/Legions/Eternis/4.png';
import eternis5 from '../../../Assets/Images/NFT/Legions/Eternis/5.png';
import pvu1 from '../../../Assets/Images/NFT/Legions/PrimusVU/1.png';
import pvu2 from '../../../Assets/Images/NFT/Legions/PrimusVU/2.png';
import pvu3 from '../../../Assets/Images/NFT/Legions/PrimusVU/3.png';
import pvu4 from '../../../Assets/Images/NFT/Legions/PrimusVU/4.png';
import pvu5 from '../../../Assets/Images/NFT/Legions/PrimusVU/5.png';
import axionisLogo from '../../../Assets/Images/NFT/Legions/Axionis/AxionisLogo.png';
import eternisLogo from '../../../Assets/Images/NFT/Legions/Eternis/EternisLogo.png';
import primusLogo from '../../../Assets/Images/NFT/Legions/PrimusVU/PrimusVULogo.png';
import bless from './LegionsSupport/omnibless.png'
import energy from './LegionsSupport/energyn.png'
import styled from 'styled-components';
import ContractsAddresses from '../../../Handlers/ContractsAdresses';

const contractLegionsAdd = ContractsAddresses.legion;

const LegionsMarket = () => {
  const [web3Instance, setWeb3Instance] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [nftTokens, setNftTokens] = useState([]);

  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3Instance(web3Instance);

        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await web3Instance.eth.getAccounts();
          setUserAccount(accounts[0]);
        } catch (error) {
          console.error('User denied account access');
        }
      } else {
        console.error('No Ethereum interface detected');
      }
    };

    loadWeb3();
  }, []);

  useEffect(() => {
    const loadNFTTokens = async () => {
      if (web3Instance && userAccount) {
        const contract = new web3Instance.eth.Contract(legionsAbi, contractLegionsAdd);
        const ownedTokens = await contract.methods.getLegion(userAccount).call();
        
        console.log('ownedTokens:', ownedTokens);

        if (Array.isArray(ownedTokens)) {
          const tokens = ownedTokens.map((token) => ({
            legionId: token[0],
            legionaries: token[1],
            ships: token[2],
            legionName: Web3.utils.hexToUtf8(token[3]),
            totalCapacity: token[4],
            totalCombatPower: token[5],
            totalMiningPower: token[6],
            warCohort: token[7],
            warTribute: token[8],
            energy: token[9],
            grade: token[10],
            glory: token[11],
            moral: token[12],
            experience: token[13],
            tokenHash: token[14]
          }));
          setNftTokens(tokens);
        } else {
          console.error('Unexpected structure of ownedTokens:', ownedTokens);
        }
      }
    };

    loadNFTTokens();
  }, [web3Instance, userAccount]);

  const renderNftImage = (warCohort, grade) => {
    const axionis = { "1": axionis1, "2": axionis2, "3": axionis3, "4": axionis4, "5": axionis5 };
    const eternis = { "1": eternis1, "2": eternis2, "3": eternis3, "4": eternis4, "5": eternis5 };
    const pvu = { "1": pvu1, "2": pvu2, "3": pvu3, "4": pvu4, "5": pvu5 };
  
    switch (warCohort) {
      case "1":
        return <LegionNftImg src={axionis[grade]} alt="Axionis" />;
      case "2":
        return <LegionNftImg src={eternis[grade]} alt="Eternis" />;
      case "3":
        return <LegionNftImg src={pvu[grade]} alt="Primus VU" />;
      default:
        return null;
    }
  };

  const renderFlag = (warCohort) => {
    const flagImages = { "1": axionisLogo, "2": eternisLogo, "3": primusLogo };
  
    return <LegionFlagImg src={flagImages[warCohort]} alt={`Flag ${warCohort}`} />;
  };

  const renderGradeName = (grade) => {
    const gradeName = { "1": "Incipiensis", "2": "Aquiliferis", "3": "Warsteel", "4": "Desolatum", "5": "Imperial Elite" };
    return <Grade>{gradeName[grade]}</Grade>;
  };

  const renderGlory = (glory) => {
    let grade;
    switch (true) {
      case (glory >= 0 && glory <= 100):
        grade = 0;
        break;
      case (glory >= 101 && glory <= 200):
        grade = 1;
        break;
      case (glory >= 201 && glory <= 300):
        grade = 2;
        break;
      case (glory >= 301 && glory <= 400):
        grade = 3;
        break;
      case (glory >= 401 && glory <= 500):
        grade = 4;
        break;
      default:
        grade = 5;
        break;
    }
  
    const gloryName = { "0": "Recruits", "1": "Experts", "2": "Veterans", "3": "Heroics", "4": "Legends", "5": "War Masters" };
    return <LegionGlory>{gloryName[grade]}</LegionGlory>;
  };

  function formatAddress(legionHash_) {
    if (!legionHash_) return '';
    const firstFour = legionHash_.slice(0, 4);
    const lastFour = legionHash_.slice(-4);
    return `${firstFour}...${lastFour}`;
  };

  const renderLegions = () => {
    return (
      <>
        {nftTokens.filter(token => token.legionId !== null).map((token, index) => (
          <LegionNftContainer key={index}>
            {renderNftImage(token.warCohort ? token.warCohort.toString() : "0", token.grade ? token.grade.toString() : "0")}
            {renderGradeName(token.grade ? token.grade.toString() : "0")}
            <LegionFlagImgContainer>{renderFlag(token.warCohort ? token.warCohort.toString() : "0")}</LegionFlagImgContainer>
            <LegionHash>ID: {formatAddress(token.tokenHash)} {"  "} </LegionHash>
            <LegionName>{token.legionName}</LegionName>
            <LegionEnergyBlessContainer><LegionBlessImg src= {bless} alt="WarTribute" /><CounterBless>{token.warTribute.toString()}</CounterBless>
            <LegionEnergyImg src={energy} alt="Energy"/><CounterEnergy>{(token.energy.toString())}</CounterEnergy></LegionEnergyBlessContainer>
            <LegionMiningPower>Mining P<br /><center>{token.totalMiningPower ? token.totalMiningPower.toString() : "N/A"}</center></LegionMiningPower>
            <LegionCombatPower>Combat P<br /><center>{token.totalCombatPower ? token.totalCombatPower.toString() : "N/A"}</center></LegionCombatPower>
            <LegionMoral>Moral: {token.moral ? token.moral.toString() : "N/A"}%</LegionMoral>
            <LegionExperience>Exp: {token.experience.toString()}</LegionExperience>
            {renderGlory(token.glory)}
            <StyledButton>Add Troops</StyledButton>
          </LegionNftContainer>
        ))}
      </>
    );
  };

  return (
    <PrincipalContainer>
      <PanelContainer>
        <PanelCell>
          <Link to="/CreateLegionsContainer"><PanelButton>Create Legion</PanelButton></Link>
        </PanelCell>
        <PanelCell>
          <PanelButton>Go to Mission</PanelButton>
        </PanelCell>
      </PanelContainer>
      <SecundaryContainer>
        <ScrollableContainer>
          <ScrollableContent>
            {renderLegions()}
          </ScrollableContent>
        </ScrollableContainer>
      </SecundaryContainer>
    </PrincipalContainer>
  );
};

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

// Toggle Buttons Panel

const PanelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  place-items: center;
  min-height: 6rem;
  padding: 1.3rem;
  margin: 0 auto;
  width: 70%;
  @media screen and (max-width: 1280px) {
    transform: translateY(18%);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const PanelCell = styled.div`
  background-color: #242124;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8);
  transform: translateY(8%);
  height: 55%;
  max-height: 60%;
  width: 50%;
  @media screen and (max-width: 1280px) {
    height: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: 15%;
    padding: 1rem;
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    width: 50%;
    height: 15%;
    padding: 1rem;
    font-size: 10px;
  }
`;

const PanelButton = styled.button`
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  width: 18vh;
  cursor: pointer;
  background-color: #107BB3;
  opacity: 0.9;
  color: white;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #FFFFFF;
    color: #107BB3;
    transform: scale(1.1);
  }
`;

// Scrollables NFT container styles

// Legion Creator Space

const SecundaryContainer = styled.div`
  overflow-y: auto;
  margin-top: 0.5%;
  width: 100%;
  height: 74vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

// NFT Viewport

const ScrollableContainer = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 73vh;
    max-height: 74vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*Scrollbar*/
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
    @media screen and (max-width: 1280px) {
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      width: 100%;
      margin-top: 13.5vh;
      max-height: 86.5vh;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 17vh;
      max-height: 83vh;
    }
    @media screen and (max-width: 426px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 70%;
      max-height: 95vh;
      transform: translateY(11%);
      margin-top: 15vw;
    }
  `;

const ScrollableContent = styled.div`
    display: grid;
    grid-template-columns: ${props => props.showLegionaries ? 'repeat(auto-fill, minmax(25vh, 1fr))' : 'repeat(auto-fill, minmax(40vh, 1fr))'};
    gap: 1.5rem;
    padding: 2rem;
    box-sizing: border-box;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: 1280px) {
      justify-items: center;
    }
    @media screen and (max-width: 1024px){
      gap: 3vh;
      height: 100%;
      width: 86%;
      padding-top: 5%;
      justify-items: center;
    }
    @media screen and (max-width: 768px) {
      height: 100%;
      width: 86%;
      padding-top: 5%;
      justify-items: center;
      gap: 0rem;
    }
    @media screen and (max-width: 426px) {
      height: 100%;
      width: 86%;
      padding-top: 5%;
      padding-left: 0rem;
      padding-right: 0rem;
      justify-items: center;
      gap: 0rem;
    }
`;

// Legion Styles

const LegionNftContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 18rem;
    width: 25rem;
    margin: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0 4px 6px rgba(16, 123, 179, 0.8);
      transform: scale(1.1);
    } 
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (max-width: 426px)
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const LegionNftImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
`;

const LegionFlagImgContainer = styled.div`
    position: relative;
    left: 91%;
    top: 5%;
    height: 40px;
    width: 10%;
    z-index: 1;
    background-color: rgba(36, 33, 36, 0.4);
    display: flex;
    justify-content: center; 
    align-items: center; 
`;

const LegionFlagImg = styled.img`
    max-width: 80%;
    max-height: 80%;
    height: auto;
    z-index: 3;
    position: absolute;
`;

const Grade = styled.div`
    margin: 0;
    position: absolute;
    top: 0%; 
    left: 35%;
    color: #107BB3; 
    border-radius: 5px;
    background-color: rgba(36, 33, 36, 1);
    height: 8px;
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.8rem;
    font-weight: bold;
    font-size: 0.8rem;
    z-index: 4; 
`;

const LegionHash = styled.div`
    position: absolute;
    top: 75%; 
    left: 1px; 
    padding-right: 3px;
    padding-left: 1px;
    padding-top: 3px;
    color: #F5F5DC;
    font-weight: bold; 
    font-size: 12px;
    background-color: rgba(141, 81, 207, 0.6); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const LegionName = styled.div`
    position: absolute;
    top: 10%; 
    left: 0; 
    padding: 3px 8px 1px 10px;
    color: white;
    font-size: 19px;
    font-weight: normal; 
    background-color: #1b1b1b; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const LegionGlory = styled.div`
    margin: 0;
    position: absolute;
    top: 20%; 
    left: 0%;
    color: rgba(225, 225, 225, 0.8);
    border-radius: 0px 5px 5px 0px;
    background-color: rgba(36, 33, 36, 0.8);
    height: 5px;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    font-size: 10px;
    z-index: 4; 
`;

const LegionExperience = styled.div`
    margin: 0;
    position: absolute;
    top: 65%; 
    right: 0%;
    color: rgba(225, 225, 225, 0.8);
    border-radius: 5px 0px 0px 5px;
    background-color: rgba(36, 33, 36, 0.8);
    height: 5px;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    font-size: 10px;
    z-index: 4; 
`;

const LegionMiningPower = styled.div`
    position: absolute;
    white-space: pre-line;
    top: 83%; 
    left: 14%; 
    color: #8D51CF; 
    font-weight: bold; 
    /* background-color: rgba(0, 0, 0, 0.8); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionCombatPower = styled.div`
    position: absolute;
    top: 83%; 
    left: 68%; 
    color: #8D51CF; 
    font-weight: bold; 
    /* background-color: rgba(0, 0, 0, 0.8); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionEnergyBlessContainer = styled.div`
    position: relative;
    left: 27%;
    border-radius: 2px;
    top: 60%;
    height: 25px;
    width: 45%;
    z-index: 1;
    background-color: rgba(36, 33, 36, 1);
    display: flex;
    justify-content: center; 
    align-items: center; 
`;

const LegionEnergyImg = styled.img`
    max-width: 85%;
    max-height: 85%;
    height: auto;
    z-index: 3;
    position: absolute;
    right: 35%;
`;

const LegionBlessImg = styled.img`
    max-width: 85%;
    max-height: 85%;
    height: auto;
    z-index: 3;
    position: absolute;
    left: 15%;
`;


const CounterBless = styled.div`
    color: white;
    font-size: 16px;
    position: absolute;
    z-indez: 3;
    top: 15%;
    left: 32%;
`;

const CounterEnergy = styled.div`
    color: white;
    font-size: 16px;
    position: absolute;
    z-indez: 3;
    top: 15%;
    left: 70%;
`;

const LegionMoral = styled.div`
    position: absolute;
    top: 75%; 
    right: 1px; 
    padding-right: 3px;
    padding-left: 4px;
    padding-top: 3px;
    color: #F5F5DC; 
    font-weight: bold; 
    font-size: 12px;
    background-color: rgba(16, 123, 179, 0.5); 
    border-radius: 5px 0 0 5px;
    z-index: 1; 
`;

const StyledButton = styled.button`
    position: absolute;
    top:85%;
    left: 38%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 90px;
    height: 40px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;


export default LegionsMarket;



