import RewardPoolAbi from '../ABI/ABIRewardPool.json';
import ContractsAddresses from './ContractsAdresses';

const rewardPoolAddress = ContractsAddresses.rewardPool;

export async function getCurrentRewardBalance(web3, account) {
    try {
      if (web3) {
        const contract = new web3.eth.Contract(RewardPoolAbi, rewardPoolAddress);
        const reward = await contract.methods.getCurrentUserReward(account).call();
        return reward;
      } else {
        console.error('Web3 instance not provided');
        return [];
      }
    } catch (error) {
      console.error('Error getting CURRENT REWARD BALANCE:', error);
      return [];
    }
}

export async function getCurrentPenalty(web3, account) {
  try {
    if (web3) {
      const contract = new web3.eth.Contract(RewardPoolAbi, rewardPoolAddress);
      const penalty = await contract.methods.getCurrentPenalty(account).call();
      return penalty;
    } else {
      console.error('Web3 instance not provided');
      return [];
    }
  } catch (error) {
    console.error('Error getting CURRENT PENALTY:', error);
    return [];
  }
}

export async function claimFunction(web3) {
    try {
      if (!web3) {
        console.error('Web3 instance not provided');
        return;
      }

      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];
      const rewardPoolContract = new web3.eth.Contract(RewardPoolAbi, rewardPoolAddress);
  
      // Estimate gas
      const gasEstimate = await rewardPoolContract.methods.claimRewards(account).estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.1);
      
      // Obtener el gas price y aumentar en un 10%
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);
  
      // Send transaction
      const transaction = rewardPoolContract.methods.claimRewards(account)
        .send({ 
          from: account,
          gas: gasLimit,
          gasPrice: increasedGasPrice,
        });
  
      transaction
        .on('transactionHash', function(hash) {
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt) {
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
  
      const receipt = await transaction;
      alert('$PRIMARIS CLAIMED!');
      return receipt;
    } catch (error) {
      console.error('Error claiming rewards:', error);
      throw error; 
    }
  }
