import PrimarisTokenABI from '../ABI/PrimarisTokenABI.json';
import ContractsAddresses from './ContractsAdresses';

const primarisToken = ContractsAddresses.primarisToken; 

// CHECKING APPROVAL **************************************************************************

const checkApproval = async (spender, web3) => {

  if (!web3) {
    console.error('ERROR GETTING WEB3 INSTANCE');
    return false;
  }

  const accounts = await web3.eth.getAccounts();
  if (!accounts.length) {
    console.error('No accounts found');
    return false;
  }

  const account = accounts[0];
  
  if (!account) {
    console.error('ERROR GETTING USER ACCOUNT');
    return false;
  }

  const tokenContract = new web3.eth.Contract(PrimarisTokenABI, primarisToken);

  try {
    const allowance = await tokenContract.methods.allowance(account, spender).call();
    console.log(`Allowance for ${spender}: ${allowance}: ${account}`);
    return parseInt(allowance, 10) >= 10;
  } catch (error) {
    console.error('Error checking allowance', error);
    return false;
  }
};


// REQUESTING APPROVAL ********************************************************************

const requestApproval = async (spender, amount, web3) => {

  if (!web3) {
    console.error('Web3 instance is not available');
    return false;
  }

  const accounts = await web3.eth.getAccounts();
  if (!accounts.length) {
    console.error('No accounts found');
    return false;
  }

  const account = accounts[0];
  
  if (!account) {
    console.error('ERROR GETTING USER ACCOUNT');
    return false;
  }

  const tokenContract = new web3.eth.Contract(PrimarisTokenABI, primarisToken);

  try {
    const gasEstimate = await tokenContract.methods.approve(spender, amount).estimateGas({ from: account });
    const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
    const gasPrice = await web3.eth.getGasPrice();
    const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);

    await tokenContract.methods.approve(spender, amount).send({ from: account, gas: gasLimit, gasPrice: increasedGasPrice });
    console.log('Approval granted!');
    return true;
  } catch (error) {
    console.error('Approval request failed', error.message, error);
    alert('Approval request failed: ' + error.message);
    return false;
  }
};


// HANDLER PRIMARIS TOKEN PERMISSIONS, FIRST CHECK APPROVAL ***************************************************************

const handlePermissions = async (spender, amount, web3) => {
  const isApproved = await checkApproval(spender, web3);
  if (!isApproved) {
    console.log('Requesting approval...');
    const approvalResult = await requestApproval(spender, amount, web3);
    return approvalResult;
  }
  return isApproved;
};

export { handlePermissions };
