const GetLastName = (ref) => {
    return lastName[ref];
  };

const lastName = {
    0: "Smith",
    1: "Johnson",
    2: "Williams",
    3: "Brown",
    4: "Jones",
    5: "Miller",
    6: "Davis",
    7: "Garcia",
    8: "Rodriguez",
    9: "Martinez",
    10: "Hernandez",
    11: "Lopez",
    12: "Gonzalez",
    13: "Wilson",
    14: "Anderson",
    15: "Taylor",
    16: "Thomas",
    17: "Moore",
    18: "Jackson",
    19: "Martin",
    20: "Lee",
    21: "Perez",
    22: "Thompson",
    23: "White",
    24: "Harris",
    25: "Sanchez",
    26: "Clark",
    27: "Ramirez",
    28: "Lewis",
    29: "Robinson",
    30: "Walker",
    31: "Young",
    32: "Allen",
    33: "King",
    34: "Wright",
    35: "Scott",
    36: "Torres",
    37: "Nguyen",
    38: "Hill",
    39: "Flores",
    40: "Green",
    41: "Adams",
    42: "Nelson",
    43: "Baker",
    44: "Hall",
    45: "Rivera",
    46: "Campbell",
    47: "Mitchell",
    48: "Carter",
    49: "Roberts",
    50: "Khan",
    51: "Kovalev",
    52: "Hansen",
    53: "van der Merwe",
    54: "Vaaldru",
    55: "Andreev",
    56: "Petersen",
    57: "Chen",
    58: "Nelson",
    59: "Abdel-Malik",
    60: "Eriksson",
    61: "Ali",
    62: "Sokolov",
    63: "Ibrahim",
    64: "Petrov",
    65: "Møller",
    66: "Baloyi",
    67: "Meyer",
    68: "Kang",
    69: "Kabongo",
    70: "Berg",
    71: "Król",
    72: "Musa",
    73: "O'Connor",
    74: "Gibson",
    75: "Piotrowski",
    76: "Jansson",
    77: "Nkosi",
    78: "Muamba",
    79: "Ahmedov",
    80: "Russo",
    81: "Herrera",
    82: "Sato",
    83: "Yilmaz",
    84: "Hassan",
    85: "Hansen",
    86: "O'Reilly",
    87: "Vasiliev",
    88: "Carlsen",
    89: "Bachir",
    90: "Krüger",
    91: "Choi",
    92: "Kovács",
    93: "Ogunlade",
    94: "López",
    95: "Ekpo",
    96: "Lund",
    97: "Bashir",
    98: "Svensson",
    99: "Zhang",
    100: "Nguyen",
    101: "Ivanov",
    102: "Smirnov",
    103: "Kuznetsov",
    104: "Popov",
    105: "Vasilyev",
    106: "Petrov",
    107: "Sokolov",
    108: "Mikhailov",
    109: "Fedorov",
    110: "Morozov",
    111: "Volkov",
    112: "Alexeev",
    113: "Lebedev",
    114: "Semenov",
    115: "Egorov",
    116: "Pavlov",
    117: "Kozlov",
    118: "Stepanov",
    119: "Nikitin",
    120: "Solovyov",
    121: "Bogdanov",
    122: "Vorobiev",
    123: "Zaitsev",
    124: "Kovalenko",
    125: "Golubev",
    126: "Vinogradov",
    127: "Kozlov",
    128: "Belyaev",
    129: "Tarasov",
    130: "Belov",
    131: "Andreev",
    132: "Bogdanov",
    133: "Dmitriev",
    134: "Kuzmin",
    135: "Orlov",
    136: "Korolev",
    137: "Grigoriev",
    138: "Ponomarev",
    139: "Karpov",
    140: "Kalinin",
    141: "Levchenko",
    142: "Sergeev",
    143: "Tikhonov",
    144: "Kazakov",
    145: "Frolov",
    146: "Vorobyov",
    147: "Streltsov",
    148: "Zhukov",
    149: "Orlov",
    150: "Dupont",
    151: "Dubois",
    152: "Moreau",
    153: "Lefevre",
    154: "Leroy",
    155: "Roux",
    156: "Durand",
    157: "Petit",
    158: "Bonnet",
    159: "Martin",
    160: "Bernard",
    161: "Thomas",
    162: "Robert",
    163: "Richard",
    164: "Simon",
    165: "Michel",
    166: "Lefebvre",
    167: "Rousseau",
    168: "Garcia",
    169: "Fournier",
    170: "Martinez",
    171: "Legrand",
    172: "Guerin",
    173: "Girard",
    174: "Andre",
    175: "Lefort",
    176: "Mercier",
    177: "Marchand",
    178: "Meyer",
    179: "Blanc",
    180: "Garnier",
    181: "Müller",
    182: "Schmidt",
    183: "Schneider",
    184: "Fischer",
    185: "Weber",
    186: "Meyer",
    187: "Lee",
    188: "Becker",
    189: "Schulz",
    190: "Hoffmann",
    191: "Schäfer",
    192: "Koch",
    193: "Bauer",
    194: "Richter",
    195: "Klein",
    196: "Wolf",
    197: "Schröder",
    198: "Neumann",
    199: "Schwarz",
    200: "Ahmed",
    201: "Ali",
    202: "Khan",
    203: "Hassan",
    204: "Hussein",
    205: "Abbas",
    206: "Rahman",
    207: "Saleh",
    208: "Bakr",
    209: "Nasir",
    210: "Malik",
    211: "Sultan",
    212: "Khalil",
    213: "Hamid",
    214: "Farah",
    215: "Zaid",
    216: "Karim",
    217: "Sharif",
    218: "Aziz",
    219: "Saad",
    220: "Ghani",
    221: "Zhang",
    222: "Ibrahim",
    223: "Choi",
    224: "Andersson",
    225: "Berg",
    226: "Dahl",
    227: "Eriksson",
    228: "Gustafsson",
    229: "Hansen",
    230: "Isaksen",
    231: "Jensen",
    232: "Kristensen",
    233: "Larsen",
    234: "Magnusson",
    235: "Nielsen",
    236: "Olsen",
    237: "Pedersen",
    238: "Rasmussen",
    239: "Sørensen",
    240: "Thomsen",
    241: "Ulrich",
    242: "Vik",
    243: "Werner",
    244: "Anderssen",
    245: "Bergman",
    246: "Dahlström",
    247: "Eriksson",
    248: "Gundersen",
    249: "Hansen",
    250: "Isaksson",
    251: "Johansson",
    252: "Karlsson",
    253: "Larsson",
    254: "Mikkelsen",
    255: "Nordström",
  };

  export { GetLastName, lastName };
      