import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Energy from "../Assets/Images/Lands/energy1.jpeg";
import Health from "../Assets/Images/Lands/health1.jpeg";

export default function Forces() {
    const [hovered, setHovered] = useState({
        campaing: false,
        health: false,
        energy: false,
    });

    const handleMouseEnter = (image) => {
        setHovered((prevState) => ({ ...prevState, [image]: true }));
    };

    const handleMouseLeave = (image) => {
        setHovered((prevState) => ({ ...prevState, [image]: false }));
    };

    return (
        <TriangleGrid>
            <DiagonalLine />
            <DiagonalLine_1 />
                    
                        <EnergyImageContainer 
                            onMouseEnter={() => handleMouseEnter('energy')} 
                            onMouseLeave={() => handleMouseLeave('energy')}
                        >
                            <Link to="/Energy">
                            <InteractiveImage 
                                src={Energy} 
                                alt="Energy" 
                                hovered={hovered.energy} 
                            />
                            <HoverText className={hovered.energy ? 'hovered' : ''}>
                                LOAD ENERGY
                            </HoverText>
                            </Link>
                        </EnergyImageContainer>
                    

                    
                        <HealthImageContainer 
                            onMouseEnter={() => handleMouseEnter('health')} 
                            onMouseLeave={() => handleMouseLeave('health')}
                        >
                            <Link to="/Health">
                            <InteractiveImage 
                                src={Health} 
                                alt="Health" 
                                hovered={hovered.health} 
                            />
                            <HoverText className={hovered.health ? 'hovered' : ''}>
                                HEALTH TROOPS
                            </HoverText>
                            </Link>
                        </HealthImageContainer>
                   
        </TriangleGrid>
    );
}

const TriangleGrid = styled.div`
    display: flex;
    justify-content: space-between; /* Distribuye Row1 y Row2 */
    position: relative;
    height: auto;
    width: 60%;
    margin: 5% 20% 0 20%;
  
    @media screen and (max-width: 768px) {
        flex-direction: column; 
        align-items: center;   
        width: 90%;
        margin: 60% 0 0 4%;
    }
`;

const EnergyImageContainer = styled.div`
    position: relative;
    justify-content: flex-start;  /* Alinea a la izquierda */
    width: 30%; 
    max-width: auto; 
    height: 60%; 
    display: flex;
    align-items: center;

    @media (min-width: 768px) and (max-width: 991px) {
        width: 40%;
    }

    @media screen and (max-width: 767px) {
        width: 50%;
        justify-content: center;
    }
`;


const HealthImageContainer = styled.div`
    position: relative;
    justify-content: flex-end; 
    width: 30%;  
    height: auto; 
    display: flex;
    align-items: center;

    @media (min-width: 768px) and (max-width: 991px) {
        width: 40%;
    }

    @media screen and (max-width: 767px) {
        width: 50%;  
        justify-content: center;  
    }
`;


const InteractiveImage = styled.img`
  width: 100%;
  height: auto; /* Mantiene la proporción de la imagen */
  opacity: 0.5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 3;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: ${props => props.hovered ? 'scale(1.1)' : 'scale(1)'};
  opacity: ${props => props.hovered ? '1' : '0.5'};
`;

const HoverText = styled.div`
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
    &.hovered {
        opacity: 1;
        bottom: 8px;
    }
`;

const DiagonalLine = styled.div`
    position: absolute;
    grid-column: 3;
    grid-row: 1;
    top: 30%;
    right: 38%;
    width: 50%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(15deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
`;

const DiagonalLine_1 = styled.div`
    position: absolute;
    grid-column: 1;
    grid-row: 1;
    top: 30%;
    left: 40%;
    width: 40%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(345deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        flex-direction: column; 
        align-items: center;
    }

    @media (min-width: 480px) and (max-width: 767px) {
        display: none;
    }

    @media (max-width: 479px) {
        display: none;
    }
`;
