import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import legionaries from "../Assets/Images/ForcesScreen/legionaries.png";
import spaceships from "../Assets/Images/ForcesScreen/spaceships.png";
import legions from "../Assets/Images/ForcesScreen/legions.png";
import legionaries_ from "../Assets/Images/ForcesScreen/legionaries_.png";
import spaceships_ from "../Assets/Images/ForcesScreen/spaceships_.png";
import legions_ from "../Assets/Images/ForcesScreen/legions_.png";

export default function Forces() {
    const [hovered, setHovered] = useState({
        legionaries: false,
        spaceships: false,
        legions: false,
    });

    const handleMouseEnter = (image) => {
        setHovered((prevState) => ({ ...prevState, [image]: true }));
    };

    const handleMouseLeave = (image) => {
        setHovered((prevState) => ({ ...prevState, [image]: false }));
    };

    return (
        <TriangleGrid>
            <DiagonalLine />
            <DiagonalLine_1 />
            <HorizontalLine />
            <Row1>
                <li>
                    <Link to="/Ships">
                        <InteractiveImageContainer 
                            onMouseEnter={() => handleMouseEnter('spaceships')} 
                            onMouseLeave={() => handleMouseLeave('spaceships')}
                        >
                            <InteractiveImage 
                                src={hovered.spaceships ? spaceships_ : spaceships} 
                                alt="Spaceships" 
                                hovered={hovered.spaceships} 
                            />
                            <HoverText className={hovered.spaceships ? 'hovered' : ''}>
                                SPACESHIPS
                            </HoverText>
                        </InteractiveImageContainer>
                    </Link>
                </li>
            </Row1>
            <Row2>
                <li>
                    <Link to="/Legionaries">
                        <InteractiveImageContainer 
                            onMouseEnter={() => handleMouseEnter('legionaries')} 
                            onMouseLeave={() => handleMouseLeave('legionaries')}
                        >
                            <InteractiveImage 
                                src={hovered.legionaries ? legionaries_ : legionaries} 
                                alt="Legionaries" 
                                hovered={hovered.legionaries} 
                            />
                            <HoverText className={hovered.legionaries ? 'hovered' : ''}>
                                LEGIONARIES
                            </HoverText>
                        </InteractiveImageContainer>
                    </Link>
                </li>
            </Row2>
            <Row3>
                <li>
                    <Link to="/Legions">
                        <InteractiveImageContainer 
                            onMouseEnter={() => handleMouseEnter('legions')} 
                            onMouseLeave={() => handleMouseLeave('legions')}
                        >
                            <InteractiveImage 
                                src={hovered.legions ? legions_ : legions} 
                                alt="Legions" 
                                hovered={hovered.legions} 
                            />
                            <HoverText className={hovered.legions ? 'hovered' : ''}>
                                LEGIONS
                            </HoverText>
                        </InteractiveImageContainer>
                    </Link>
                </li>
            </Row3>
        </TriangleGrid>
    );
}

const TriangleGrid = styled.div`
    display: grid;
    height: auto;
    width: 50%;
    margin: 5% 25% 0 25%;
    align-content: center;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr 1fr;
    @media screen and (max-width: 768px) {
        padding-top: 0%;
        grid-template-columns: 1fr;
        grid-template-rows: 3fr;
        margin: 20% 0% 0 25%; 
        width: 50%;
    }
`;

const Row1 = styled.div`
    grid-column: 3;
    grid-row: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    @media screen and (max-width: 768px) {
        grid-column: 1;
        margin: 0;
        grid-row: 1;
    }
`;

const Row2 = styled.div`
    grid-column: 1;
    grid-row: 2; 
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding-right: 10%;
    @media screen and (max-width: 768px) {
        grid-column: 1;
        margin: 0;
        grid-row: 2; 
    }
`;

const Row3 = styled.div`
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    @media screen and (max-width: 768px) {
        grid-column: 1; 
        grid-row: 3; 
    }
`;

const InteractiveImageContainer = styled.div`
    position: relative;
    width: 100%; 
    height: auto; 
`;

const InteractiveImage = styled.img`
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transform: ${props => props.hovered ? 'scale(1.1)' : 'scale(1)'};
`;

const HoverText = styled.div`
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
    ${InteractiveImageContainer}:hover & {
        opacity: 1;
        bottom: 8px;
    }
`;

const DiagonalLine = styled.div`
    position: absolute;
    grid-column: 3;
    grid-row: 1;
    top: 38%;
    right: 25%;
    width: 20%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(45deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }

    @media screen and (max-width: 768px) {
       display: none;
    }
`;

const DiagonalLine_1 = styled.div`
    position: absolute;
    grid-column: 1;
    grid-row: 1;
    top: 38%;
    left: 25%;
    width: 20%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(135deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
    @media screen and (max-width: 768px) {
       display: none;
    }
`;

const HorizontalLine = styled.div`
    position: absolute;
    grid-column: 2;
    grid-row: 2;
    bottom: 30%;
    left: 40%;
    width: 20%;
    border-top: 2px solid rgba(16, 123, 179, 0.4);
    z-index: 0;
    transform: rotate(180deg);

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: rgba(16, 123, 179, 1);
        border-radius: 50%;
    }

    &::before {
        top: -6px;
        left: -1px;
    }

    &::after {
        top: -6px;
        right: -9px;
    }
    @media screen and (max-width: 768px) {
       display: none;
    }
`;


