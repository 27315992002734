import React, { useState, useEffect } from 'react';
import { useWeb3 } from '../../../Handlers/ChainContext';
import { Link, useNavigate } from 'react-router-dom';
import { buyWarTribute } from '../../../Handlers/WarTributeHandler';
import legionsAbi from '../../../ABI/ABILegions.json';
import axionis1 from '../../../Assets/Images/NFT/Legions/Axionis/1.png';
import axionis2 from '../../../Assets/Images/NFT/Legions/Axionis/2.png';
import axionis3 from '../../../Assets/Images/NFT/Legions/Axionis/3.png';
import axionis4 from '../../../Assets/Images/NFT/Legions/Axionis/4.png';
import axionis5 from '../../../Assets/Images/NFT/Legions/Axionis/5.png';
import eternis1 from '../../../Assets/Images/NFT/Legions/Eternis/1.png';
import eternis2 from '../../../Assets/Images/NFT/Legions/Eternis/2.png';
import eternis3 from '../../../Assets/Images/NFT/Legions/Eternis/3.png';
import eternis4 from '../../../Assets/Images/NFT/Legions/Eternis/4.png';
import eternis5 from '../../../Assets/Images/NFT/Legions/Eternis/5.png';
import pvu1 from '../../../Assets/Images/NFT/Legions/PrimusVU/1.png';
import pvu2 from '../../../Assets/Images/NFT/Legions/PrimusVU/2.png';
import pvu3 from '../../../Assets/Images/NFT/Legions/PrimusVU/3.png';
import pvu4 from '../../../Assets/Images/NFT/Legions/PrimusVU/4.png';
import pvu5 from '../../../Assets/Images/NFT/Legions/PrimusVU/5.png';
import axionisLogo from '../../../Assets/Images/NFT/Legions/Axionis/AxionisLogo.png';
import eternisLogo from '../../../Assets/Images/NFT/Legions/Eternis/EternisLogo.png';
import primusLogo from '../../../Assets/Images/NFT/Legions/PrimusVU/PrimusVULogo.png';
import bless from './LegionsSupport/omnibless.png'
import energy from './LegionsSupport/energyn.png'
import styled from 'styled-components';
import ContractsAddresses from '../../../Handlers/ContractsAdresses';
import { MintLegion } from '../../Buttons/MintLegionButton';
import WarTribute from '../../Buttons/WarTributeButton';

const contractLegionsAdd = ContractsAddresses.legion;

const ShowLegions = () => {
  const { web3, account } = useWeb3(); 
  const [nftTokens, setNftTokens] = useState([]);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLegion, setSelectedLegion] = useState(null);
  const [currentTokenId, setCurrentTokenId] = useState(null);
  const [selectedLegionOption, setSelectedLegionOption] = useState("Select your legion...");
  
  const navigate = useNavigate();

  useEffect(() => {
    const loadNFTTokens = async () => {
      if (web3 && account) {
        const contract = new web3.eth.Contract(legionsAbi, contractLegionsAdd);
        const ownedTokens = await contract.methods.getLegion(account).call();
        
        console.log('ownedTokens:', ownedTokens);

        if (Array.isArray(ownedTokens)) {
          const tokens = ownedTokens.map((token) => ({
            legionId: token[0],
            legionaries: token[1],
            ships: token[2],
            legionName: web3.utils.hexToUtf8(token[3]),
            totalCapacity: token[4],
            totalCombatPower: token[5],
            totalMiningPower: token[6],
            warCohort: token[7],
            warTribute: token[8],
            energy: token[9],
            grade: token[10],
            glory: token[11],
            moral: token[12],
            experience: token[13],
            tokenHash: token[14]
          }));
          setNftTokens(tokens);
        } else {
          console.error('Unexpected structure of ownedTokens:', ownedTokens);
        }
      }
    };

    loadNFTTokens();
  }, [web3, account]);

  const renderNftImage = (warCohort, grade) => {
    const axionis = { "1": axionis1, "2": axionis2, "3": axionis3, "4": axionis4, "5": axionis5 };
    const eternis = { "1": eternis1, "2": eternis2, "3": eternis3, "4": eternis4, "5": eternis5 };
    const pvu = { "1": pvu1, "2": pvu2, "3": pvu3, "4": pvu4, "5": pvu5 };
  
    switch (warCohort) {
      case "0":
        return <LegionNftImg src={axionis[grade]} alt="Axionis" />;
      case "2":
        return <LegionNftImg src={eternis[grade]} alt="Eternis" />;
      case "1":
        return <LegionNftImg src={pvu[grade]} alt="Primus VU" />;
      default:
        return null;
    }
  };

  const renderFlag = (warCohort) => {
    const flagImages = { "0": axionisLogo, "2": eternisLogo, "1": primusLogo };
  
    return <LegionFlagImg src={flagImages[warCohort]} alt={`Flag ${warCohort}`} />;
  };

  const renderGradeName = (grade) => {
    const gradeName = { "1": "Incipiensis", "2": "Aquiliferis", "3": "Warsteel", "4": "Desolatum", "5": "Imperial Elite" };
    return <Grade>{gradeName[grade]}</Grade>;
  };

  const renderGlory = (exp) => {
    let glory;
    switch (true) {
      case (exp >= 0 && exp <= 50):
        glory = 0;
        break;
      case (exp > 5 && exp < 150):
        glory = 1;
        break;
      case (exp >= 150 && exp < 350):
        glory = 2;
        break;
      case (exp >= 350 && exp < 600):          
        glory = 3;
        break;
      case (exp >= 600 && exp < 900):
        glory = 4;
        break;
      case (exp >= 900 && exp < 1000):          
        glory = 5;
        break;
      case (exp >= 1000):          
        glory = 6;
        break;
    }
  
    const gloryName = { "0": "Recruits", "1": "Recruits", "2": "Experts", "3": "Veterans", "4": "Heroics", "5": "Legends", "6": "War Masters" };
    return <LegionGlory>{gloryName[glory]}</LegionGlory>;
  };

  const handleAddTroops = (legion) => {
    setSelectedLegion(legion);
    navigate('/AddingTroopsContainer', { state: { legion } });
  };

  const toggleWarTributeModal = () => {
    setShowModal(!showModal);
  };

  const handleBuyTribute = (option) => {
    if (currentTokenId != null && option != null) {
      buyWarTribute(option, currentTokenId,web3);
      setMessage('The Omnilex has blessed you');
    } else if (currentTokenId == null || option == null) {
      setMessage('Por favor, asegúrate de seleccionar una opción válida y tener un token ID válido.');
    }
  };

  const handleCreate = () => {
    navigate('/CreateLegionsContainer');
  };
    
  const LegionDropdown = ({ legions, onSelect, setCurrentTokenId }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleSelect = (legion) => {
        setSelectedLegionOption(legion.legionName);
        setCurrentTokenId(legion.legionId);
        setIsOpen(false);
        onSelect(legion); 
    };
  
    return (
        <DropdownLContainer>
            <DropdownButton onClick={() => setIsOpen(!isOpen)}>
                {selectedLegionOption}
            </DropdownButton>
            {isOpen && (
                <DropdownContent>
                    {legions.map((legion, index) => (
                        <DropdownItem key={index} onClick={() => handleSelect(legion)}>
                            <DropdownText>{legion.legionName}</DropdownText>
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}
        </DropdownLContainer>
    );
  };

  const renderLegions = () => {
    return (
      <>
        {nftTokens.filter(token => token.legionId !== null).map((token, index) => (
          <LegionNftContainer key={index}>
            {renderNftImage(token.warCohort ? token.warCohort.toString() : "0", token.grade ? token.grade.toString() : "0")}
            {renderGradeName(token.grade ? token.grade.toString() : "0")}
            <LegionFlagImgContainer>{renderFlag(token.warCohort ? token.warCohort.toString() : "0")}</LegionFlagImgContainer>
            <LegionName>{token.legionName}</LegionName>
            <LegionEnergyBlessContainer><LegionBlessImg src= {bless} alt="WarTribute" /><CounterBless>{token.warTribute.toString()}</CounterBless>
            <LegionEnergyImg src={energy} alt="Energy"/><CounterEnergy>{(token.energy.toString())}</CounterEnergy></LegionEnergyBlessContainer>
            <LegionMiningPower>Mining P<br /><center>{token.totalMiningPower ? token.totalMiningPower.toString() : "N/A"}</center></LegionMiningPower>
            <LegionCombatPower>Combat P<br /><center>{token.totalCombatPower ? token.totalCombatPower.toString() : "N/A"}</center></LegionCombatPower>
            <LegionMoral>Morale: {token.moral ? token.moral.toString() : "N/A"}%</LegionMoral>
            <LegionExperience>Exp: {token.experience.toString()}</LegionExperience>
            {renderGlory(token.experience)}
            <StyledButton onClick={() => handleAddTroops(token)}>Add Troops</StyledButton>
          </LegionNftContainer>
        ))}
      </>
    );
  };

  return (
    <PrincipalContainer>
      <PanelContainer>
        <PanelCell>
          <MintLegion onClick={handleCreate}/>
        </PanelCell>
        <PanelCell>
          <WarTribute onClick={toggleWarTributeModal}>
            {showModal ? 'Choose Tribute' : 'War Tribute'}
          </WarTribute>
        </PanelCell>
      </PanelContainer>
      <SecundaryContainer>
        <ScrollableContainer>
          <ScrollableContent>
            {renderLegions()}
          </ScrollableContent>
        </ScrollableContainer>
      </SecundaryContainer>

      {showModal && (
              <ModalOverlay onClick={toggleWarTributeModal}>
                  <ModalContent onClick={e => e.stopPropagation()}>
                      <LegionDropdown legions={nftTokens} onSelect={setSelectedLegion} setCurrentTokenId={setCurrentTokenId}  />
                        <h2> Select Days of Blessing </h2>
                        <OptionList>
                          <li><OptionButton onClick={() => setSelectedOption(0)}>7 Days</OptionButton></li>
                          <li><OptionButton onClick={() => setSelectedOption(1)}>14 Days</OptionButton></li>
                          <li><OptionButton onClick={() => setSelectedOption(2)}>21 Days</OptionButton></li>
                          <li><OptionButton onClick={() => setSelectedOption(3)}>28 Days</OptionButton></li>
                        </OptionList>
                        <BuyButton onClick={() => handleBuyTribute(selectedOption)}>Buy</BuyButton>
                        <CloseButton onClick={toggleWarTributeModal}>Close</CloseButton>
                  </ModalContent>
              </ModalOverlay>
      )}

    </PrincipalContainer>
  );
};

// MODAL STYLES***************************************************************

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #0c0d10;
  color: white;
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.5);
  max-width: 400px;
  width: 100%;
  height: 40%;
  text-align: center;

  @media (min-width: 1200px) and (max-width: 1400px) {
    
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {

  }

  @media (max-width: 479px) {

  }
`;

const OptionList = styled.ul`
  list-style-type: none;  
  padding: 0;  
  margin: 20px 0; 

  li {
    margin: 10px 0;  
  }
`;

const CloseButton = styled.button`
  position: absolute;
  background-color: #CF010B;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  font-size: 16px;
  bottom: 5%;
  width: 20%;
  height: 10%; 
  top:85%;
  right: 25%;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    color: #CF010B;
    background-color: #FFFFFF;
    transform: scale(1.1);
  } 
`;


const BuyButton = styled.button`
  position: absolute;
  background-color: #218838;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  font-size: 16px;
  bottom: 5%;
  width: 20%; /* Ajusta el ancho del botón */
  height: 10%; 
  top:85%;
  left: 25%;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    color: #218838;
    background-color: #FFFFFF;
    transform: scale(1.1);
  } 
`;


const OptionButton = styled.button`
  padding: 10px 20px;
  background-color: rgba(16, 123, 179, 1);
  color: #FFFFFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  &:hover {
    color: rgba(16, 123, 179, 1);
    background-color: #FFFFFF;
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }
`;


// DROPDOWN STYLES***************************************************************

const DropdownLContainer = styled.div`
  position: absolute;
  left: 25%;
  top: -5%;
  width: 50%;
`;

const DropdownButton = styled.button`
  width: 100%;
  height: 25px;
  font-size: 14px;
  padding: 5px;
  background-color: #1b1b1b;
  color: #FFFFFF;
  border: 1px solid #8D51CF;
  border-radius: 5px;
  cursor: pointer;
    display: flex; /* Activar Flexbox */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
`;

const DropdownContent = styled.ul`
  position: absolute;
  font-size: 12px;
  width: 100%;
  background-color: #1b1b1b;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;

  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #8D51CF #242124;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #242124;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8D51CF;
    border-radius: 10px;
    border: 2px solid #242124;
  }
`;

const DropdownItem = styled.li`
  padding: 10px;
  color: #8D51CF;
  cursor: pointer;

  &:hover {
    background-color: #333333;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

// PRINCIPAL ************************************************

const DropdownText = styled.span`
  flex: 1;
  cursor: pointer;
`;

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    height: 100%;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 479px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

// Toggle Buttons Panel

const PanelContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 2fr));
  place-items: center;
  min-height: 10rem;
  padding: 0;
  margin: 0 auto;
  margin-top: 10vw;
  width: 70%;
  z-index: 2;
  @media (min-width: 1200px) and (max-width: 1400px) {
    
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 70%;
    margin-top: 10vw;
    gap: 0.5rem;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 96%;
    margin-top: 30vw;
    gap: 0.5rem;
  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 96%;
    margin-top: 35vw;
    gap: 0.5rem;

  }

  @media (max-width: 479px) {
    width: 96%;
    margin-top: 40vw;
    gap: 0.5rem;
  }
`;

const PanelCell = styled.div`
  background-color: #0c0d10;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8);
  height: 55%;
  width: 50%;
  @media (min-width: 1200px) and (max-width: 1400px) {
    
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 480px) and (max-width: 767px) {
    width: 70%;
  }

  @media (max-width: 479px) {
    width: 80%;
  }
`;

// Scrollables NFT container styles

// Legion Creator Space

const SecundaryContainer = styled.div`
  overflow-y: auto;
  margin-top: 0.5%;
  width: 100%;
  height: 70vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

// NFT Viewport

const ScrollableContainer = styled.div`
    
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    height: 70vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*Scrollbar*/
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      position: relative;
      height: 80vh;

    }
    @media screen and (min-width: 768px) and (max-width: 991px){
      position: relative;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {

    }

    @media screen and (max-width: 479px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      overflow-x: auto;
    }
  `;

const ScrollableContent = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 2rem;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    overflow-x: auto;
    overflow-y: auto;
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      justify-items: center;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      gap: 1rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      grid-auto-rows: 300px;
      margin-left: 0%;
      margin-right: 0%;
      width: 80%;
      height: 100%;
      padding-right: 12%;
      padding-left: 12%;
      justify-items: center;
      align-items: center; /* Alinear también verticalmente */
      gap: 3rem; /* Espacio entre filas y columnas */
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-left: 0%;
      margin-right: 0%;
      justify-items: center;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      grid-auto-rows: 300px;
      height: 100%;
      width: 100%;
      padding-top: 5%;
      gap: 2rem;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(500px, 1fr));
      height: 100%;
      width: 86%;
      padding-top: 5%;
      padding-left: 1rem;
      padding-right: 1rem;
      justify-items: center;
      gap: 0rem;
    }
     @media screen and (max-width: 479px) {
      grid-template-columns: repeat(1, minmax(500px, 1fr));
      height: 100%;
      width: 500%;
      padding-top: 5%;
      margin-left: 0%;
      margin-right: 0%;
      padding-left: 1rem;
      padding-right: 1rem;
      justify-items: center;
      gap: 0rem;
    }
`;

// Legion Styles

const LegionNftContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 18rem;
    width: 25rem;
    margin: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0px 4px 10px 0px rgba(255, 255, 255, 0.5);
      transform: scale(1.1);
    } 
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (max-width: 426px)
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const LegionNftImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
`;

const LegionFlagImgContainer = styled.div`
    position: relative;
    left: 91%;
    top: 5%;
    height: 40px;
    width: 10%;
    z-index: 1;
    background-color: rgba(36, 33, 36, 0.4);
    display: flex;
    justify-content: center; 
    align-items: center; 
`;

const LegionFlagImg = styled.img`
    max-width: 80%;
    max-height: 80%;
    height: auto;
    z-index: 3;
    position: absolute;
`;

const Grade = styled.div`
    margin: 0;
    position: absolute;
    top: 0%; 
    left: 35%;
    color: #107BB3; 
    border-radius: 5px;
    background-color: rgba(36, 33, 36, 1);
    height: 8px;
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.8rem;
    font-weight: bold;
    font-size: 0.8rem;
    z-index: 4; 
`;

const LegionName = styled.div`
    position: absolute;
    top: 10%; 
    left: 0; 
    padding: 3px 8px 1px 10px;
    color: white;
    font-size: 19px;
    font-weight: normal; 
    background-color: #1b1b1b; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const LegionGlory = styled.div`
    margin: 0;
    position: absolute;
    top: 20%; 
    left: 0%;
    color: rgba(225, 225, 225, 0.8);
    border-radius: 0px 5px 5px 0px;
    background-color: rgba(36, 33, 36, 0.8);
    height: 5px;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    font-size: 10px;
    z-index: 4; 
`;

const LegionExperience = styled.div`
    margin: 0;
    position: absolute;
    top: 74%; 
    left: 0%;
    color: rgba(225, 225, 225, 0.8);
    border-radius: 0px 5px 5px 0px;
    background-color: rgba(141, 81, 207, 0.8);
    height: 5px;
    width: 17%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    font-size: 10px;
    z-index: 4; 
`;

const LegionMiningPower = styled.div`
    position: absolute;
    white-space: pre-line;
    top: 83%; 
    left: 14%; 
    color: #8D51CF; 
    font-weight: bold; 
    /* background-color: rgba(0, 0, 0, 0.8); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionCombatPower = styled.div`
    position: absolute;
    top: 83%; 
    left: 68%; 
    color: #8D51CF; 
    font-weight: bold; 
    /* background-color: rgba(0, 0, 0, 0.8); 
    border-radius: 5px; 
    z-index: 1; 
`;

const LegionEnergyBlessContainer = styled.div`
    position: relative;
    left: 27%;
    border-radius: 2px;
    top: 60%;
    height: 25px;
    width: 45%;
    z-index: 1;
    background-color: rgba(36, 33, 36, 1);
    display: flex;
    justify-content: center; 
    align-items: center; 
`;

const LegionEnergyImg = styled.img`
    max-width: 85%;
    max-height: 85%;
    height: auto;
    z-index: 3;
    position: absolute;
    right: 35%;
`;

const LegionBlessImg = styled.img`
    max-width: 85%;
    max-height: 85%;
    height: auto;
    z-index: 3;
    position: absolute;
    left: 15%;
`;


const CounterBless = styled.div`
    color: white;
    font-size: 16px;
    position: absolute;
    z-indez: 3;
    top: 15%;
    left: 32%;
`;

const CounterEnergy = styled.div`
    color: white;
    font-size: 16px;
    position: absolute;
    z-indez: 3;
    top: 15%;
    left: 70%;
`;

const LegionMoral = styled.div`
    position: absolute;
    top: 75%; 
    right: 1px; 
    padding-right: 3px;
    padding-left: 4px;
    padding-top: 3px;
    color: #F5F5DC; 
    font-weight: bold; 
    font-size: 12px;
    background-color: rgba(16, 123, 179, 0.5); 
    border-radius: 5px 0 0 5px;
    z-index: 1; 
`;

const StyledButton = styled.button`
    position: absolute;
    top:85%;
    left: 38%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 90px;
    height: 40px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;


export default ShowLegions;
