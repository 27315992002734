import React, { useState, useEffect } from 'react';
import { GetName } from './LegionariesNames/Names'
import { GetLastName } from './LegionariesNames/LastNames';
import { checkLegionaryUsed } from '../../../Handlers/NftsContractsHandler';
import Web3 from 'web3';
import legionariesAbi from '../../../ABI/ABILegionaries.json';
import styled from "styled-components";
import R1 from '../../../Assets/Images/NFT/Legionaries/R1.png';
import R2 from '../../../Assets/Images/NFT/Legionaries/R2.png';
import R3 from '../../../Assets/Images/NFT/Legionaries/R3.png';
import R4 from '../../../Assets/Images/NFT/Legionaries/R4.png';
import R5 from '../../../Assets/Images/NFT/Legionaries/R5.png';
import R1rank from '../../../Assets/Images/NFT/Legionaries/R1rank.png';
import R2rank from '../../../Assets/Images/NFT/Legionaries/R2rank.png';
import R3rank from '../../../Assets/Images/NFT/Legionaries/R3rank.png';
import R4rank from '../../../Assets/Images/NFT/Legionaries/R4rank.png';
import R5rank from '../../../Assets/Images/NFT/Legionaries/R5rank.png';
import R1video from '../../../Assets/Video/Legionaries/R1 1.mp4';
import R2video from '../../../Assets/Video/Legionaries/R2 1.mp4';
import R3video from '../../../Assets/Video/Legionaries/R3 1.mp4';
import R4video from '../../../Assets/Video/Legionaries/R4 1.mp4';
import R5video from '../../../Assets/Video/Legionaries/R5 1.mp4';
import ContractsAddresses from '../../../Handlers/ContractsAdresses';


//Web3 Manager Functions

const contractLegionariesAdd = ContractsAddresses.legionary;

const LegionariesMarket = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [nftTokens, setNftTokens] = useState([]);
  const [videoSrc, setVideoSrc] = useState(null); 
  const [isVideoVisible, setIsVideoVisible] = useState(false); 

  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        try {
          // Request account access if needed
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          // Acccounts now exposed
          const accounts = await web3Instance.eth.getAccounts();
          setAccount(accounts[0]);
        } catch (error) {
          console.error('User denied account access');
        }
      } else {
        console.error('No Ethereum interface detected');
      }
    };

    loadWeb3();
  }, []); 

  const renderNftImage = (rank) => {
    switch (rank) {
      case "1":
        return <NftImg src={R1} alt="Hastati" />;
      case "2":
        return <NftImg src={R2} alt="Opitionis" />;
      case "3":
        return <NftImg src={R3} alt="Decurian" />;
      case "4":
        return <NftImg src={R4} alt="Centurian" />;
      case "5":
        return <NftImg src={R5} alt="Praetorian" />;
      default:
        return null;
    }
  };

  const getVideoSrc = (rank) => {
    switch (rank) {
      case "1":
        return R1video;
      case "2":
        return R2video;
      case "3":
        return R3video;
      case "4":
        return R4video;
      case "5":
        return R5video;
      default:
        return null;
    }
  };

  const renderRankImage = (rank) => {
    switch (rank) {
      case "1":
        return <RankImg src={R1rank} alt="Hastati" />;
      case "2":
        return <RankImg src={R2rank} alt="Opitionis" />;
      case "3":
        return <RankImg src={R3rank} alt="Decurian" />;
      case "4":
        return <RankImg src={R4rank} alt="Centurian" />;
      case "5":
        return <RankImg src={R5rank} alt="Praetorian" />;
      default:
        return null;
    }
  };

  const handleButtonClick = (rank) => {
    const videoSource = getVideoSrc(rank.toString());
    setVideoSrc(videoSource);
    setIsVideoVisible(true);
  };
  
  const renderRankName = (rank) => {
    let rankName = "";
    switch (rank) {
      case "1":
        rankName = "Hastatus";
        break;
      case "2":
        rankName = "Opitionis";
        break;
      case "3":
        rankName = "Decurian";
        break;
      case "4":
        rankName = "Centurian";
        break;
      case "5":
        rankName = "Praetorian";
        break;
    default:
      rankName = "Unknown";
      break;
    }
    return <Rank>{rankName}</Rank>;
  };

  function formatAddress(legionaryId_) {
    if (!legionaryId_) return '';
    const firstFive = legionaryId_.slice(0, 5);
    const lastSix = legionaryId_.slice(-6);
    return `${firstFive}...${lastSix}`;
  };

  const calculateTotalMiningPower = () => {
    return nftTokens.reduce((total, token) => total + parseInt(token.legionary.miningPower_), 0);
  };

  const calculateTotalCombatPower = () => {
    return nftTokens.reduce((total, token) => total + parseInt(token.legionary.combatPower_), 0);
  };

  useEffect(() => {
    const loadNFTTokens = async () => {
      if (web3 && account) {
        const contract = new web3.eth.Contract(legionariesAbi, contractLegionariesAdd);
        const ownedTokens = await contract.methods.getLegionary(account).call();
        
        const checkedTokens = await Promise.all(ownedTokens.map(async (token, index) => {
          const isUsed = await checkLegionaryUsed(token.tokenId_);
          return {
            id: token.tokenId_,
            legionary: {
              legionaryId_: token[0] || "",
              legionaryName_: token[1] || "",
              lastName_: token[2] || "",
              rank_: token[3] || "",
              miningPower_: token[4] || "",
              combatPower_: token[5] || "",
              stamina_: token[6] || "",
              tokenId_: token[7],
              isUsed: isUsed,
            }
          };
        }));

        console.log(checkedTokens);
        setNftTokens(checkedTokens);
      }
    };

    loadNFTTokens();
  }, [web3, account]);
  return (

    <PrincipalContainer>
    <PanelContainer>
        <PanelCell></PanelCell>
        <PanelCell>Total: <br/><center>{nftTokens.length}</center></PanelCell>
        <PanelCell>Total MP: {calculateTotalMiningPower()} / Total CP: {calculateTotalCombatPower()}</PanelCell>
    </PanelContainer>
    
    {/* Scrollable NFT cointainer */}
    
    <ScrollableContainer>
        <ScrollableContent>          
              {nftTokens
              .filter(token => !token.legionary.isUsed)
              .filter(token => token.legionary.legionaryId_ !=="0x0000000000000000000000000000000000000000000000000000000000000000").map(token => (
                <NftContainer key={token.id}> 
                    <p>Rank: {token.legionary.rank_.toString()}</p>
                    {renderNftImage(token.legionary.rank_.toString())}
                    {renderRankName(token.legionary.rank_.toString())}
                    <RankImgContainer>{renderRankImage(token.legionary.rank_.toString())}</RankImgContainer>
                    <UniqId>ID: {formatAddress(token.legionary.legionaryId_)} {"  "} </UniqId>
                    <Name>{GetName(token.legionary.legionaryName_)} {GetLastName(token.legionary.lastName_)}</Name>
                    <MiningPower>Mining P<br/><center>{token.legionary.miningPower_.toString()}</center></MiningPower>
                    <CombatPower>Combat P<br/><center>{token.legionary.combatPower_.toString()}</center></CombatPower>
                    <Stamina>HP: {token.legionary.stamina_.toString()}%</Stamina>
                    <StyledButton onClick={() => handleButtonClick(token.legionary.rank_)}>View</StyledButton>

                </NftContainer>
              ))}
        </ScrollableContent>
    </ScrollableContainer>

      {/* Superimposed Video */}
      {isVideoVisible && (
      <VideoOverlay>
            <VideoTop>
          <div className="links">
            <SellButton>SELL</SellButton>
            <BurnButton>BURN</BurnButton>
            <CloseButton onClick={() => setIsVideoVisible(false)}>X</CloseButton>
          </div>
        </VideoTop>
        <CloseButtonMobile onClick={() => setIsVideoVisible(false)}>X</CloseButtonMobile>
        <NftVideo src={videoSrc} controls autoPlay />
      </VideoOverlay>
    )}

</PrincipalContainer>

  );
};

export default LegionariesMarket;

const PrincipalContainer = styled.div`
    position: relative;
    margin-top: calc(1% + 80px);
    flex: 1;
    margin-left: 1rem;
    height: 100%;
    @media screen and (max-width: 1440px) {
      margin-left: 0:
      width: 100vw;
    }
    @media screen and (max-width: 1280px) {
      margin-left: 0;
      width: 100vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin-top: 0;
      margin left: 0rem;
      width: 99.5vw;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 0;
      margin left: 0rem;
      width: 100vw;
    }
    @media screen and (max-width: 426px) {
      margin-top: 0;
      margin-left: 0rem;
      width: 99.5vw;
    }

`;

// Mint And Counter Panels Styles

const PanelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  place-items: center;
  min-height: 6rem;
  padding: 1rem;
  margin: 0 auto;
  width: 70%;
  @media screen and (max-width: 1280px) {
    transform: translateY(18%);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const PanelCell = styled.div`
  background-color: #242124;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(16, 123, 179, 0.8);
  transform: translateY(8%);
  height: 55%;
  max-height: 60%;
  width: 50%;
  @media screen and (max-width: 1280px) {
    height: 25%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: 15%;
    padding: 1rem;
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    width: 50%;
    height: 15%;
    padding: 1rem;
    font-size: 10px;
  }
`;

// Scrollable NFT container styles

const ScrollableContainer = styled.div`
    overflow-y: auto;
    margin-top: 0.5%;
    width: 100%;
    height: 70vh;
    max-height: 74vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*Scrollbar*/
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #8D51CF #242124;
    @media screen and (max-width: 1280px) {
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
      width: 100%;
      margin-top: 13.5vh;
      max-height: 86.5vh;
    }
    @media screen and (min-width: 427px) and (max-width: 769px) {
      margin-top: 17vh;
      max-height: 83vh;
    }
    @media screen and (max-width: 426px) {
      margin-left: 0%;
      margin-right: 0%;
      width: 100%;
      max-height: 95vh;
      transform: translateY(11%);
      margin-top: 15vw;
    }
  `;

const ScrollableContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 2rem;
    box-sizing: border-box;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: 1280px) {
      justify-items: center;
    }
    @media screen and (max-width: 1024px){
      gap: 3vh;
      height: 100%;
      width: 86%;
      padding-top: 5%;
      justify-items: center;
    }
    @media screen and (max-width: 768px) {
      height: 100%;
      width: 86%;
      padding-top: 5%;
      justify-items: center;
      gap: 0rem;
    }
    @media screen and (max-width: 426px) {
      height: 100%;
      width: 86%;
      padding-top: 5%;
      padding-left: 0rem;
      padding-right: 0rem;
      justify-items: center;
      gap: 0rem;
    }
`;

// NFT styles

const NftContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 29rem;
    width: 13.5rem;
    margin: 1rem;
    position: relative;
    transition: transform 0.3s ease;
    &:hover {
      box-shadow: 0 4px 6px rgba(16, 123, 179, 0.8);
      transform: scale(1.1);
    } 
    @media screen and (min-width: 769px) and (max-width: 1024px){
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.01);
      } 
    }
    @media screen and (max-width: 426px)
      margin:0;
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.02);
      } 
    }
`;

const NftImg = styled.img`
    border-radius: 8px;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: auto;
    z-index: 0;
`;

const RankImgContainer = styled.div`
    position: relative;
    left: 3%;
    top: 59%;
    height: 7px;
    width: 83%;
    z-index: 1;
    clip-path: polygon(0 100%, 100% 100%, 80% 0, 20% 0);
    padding: 0.5rem 0.8rem;
    background-color: #242124;
    display: flex;
    justify-content: center; /* Alinear horizontalmente al centro */
    align-items: center; /* Alinear verticalmente al centro */
`;

const RankImg = styled.img`
    max-width: 80%; 
    max-height: 80%; 
    height: auto; 
    top: 1%;
    z-index: 3;
    position: absolute;
`;

const Rank = styled.p`
    margin: 0;
    position: absolute;
    top: 0; 
    left: 3%;
    color: #107BB3; 
    background-color: #242124;
    height: 7px;
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    box-shadow:  5px 10px rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0.8rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 0.8rem;
    z-index: 4; 
`;

const UniqId = styled.p`
    position: absolute;
    top: 71.1%; 
    left: 1px; 
    padding-right: 3px;
    padding-left: 1px;
    color: #F5F5DC;
    font-weight: bold; 
    font-size: 14px;
    background-color: rgba(141, 81, 207, 0.8); 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
`;

const Name = styled.p`
    position: absolute;
    top: 2.3%; 
    left: 0; 
    padding: 1px 3px 1px 3px;
    color: white;
    font-size: 15px;
    font-weight: normal; 
    background-color: #1b1b1b; 
    border-radius: 0 5px 5px 0;
    z-index: 1; 
    
`;

const MiningPower = styled.p`
    position: absolute;
    white-space: pre-line;
    top: 77%; /* Ajusta según sea necesario */
    left: 14%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const CombatPower = styled.p`
    position: absolute;
    top: 77%; /* Ajusta según sea necesario */
    left: 55%; /* Ajusta según sea necesario */
    color: #8D51CF; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    /* background-color: rgba(0, 0, 0, 0.8); Color de fondo con transparencia */
    border-radius: 5px; /* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const Stamina = styled.p`
    position: absolute;
    top: 71.1%; /* Ajusta según sea necesario */
    right: 1px; /* Ajusta según sea necesario */
    padding-right: 1px;
    padding-left: 3px;
    color: #F5F5DC; /* Color del texto */
    font-weight: bold; /* Estilo de la fuente */
    font-size: 14px;
    background-color: rgba(16, 123, 179, 0.7); /* Color de fondo con transparencia */
    border-radius: 5px 0 0 5px;/* Borde redondeado */
    z-index: 1; /* Asegura que los párrafos estén sobre la imagen */
`;

const StyledButton = styled.button`
    position: absolute;
    top:90%;
    left: 32%;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #218838;
    opacity: 0.9;
    color: white;
    transition: background-color 0.3s ease;
    width: 80px;
    height: 30px;
    &:hover {
      color: #218838;
      background-color: #FFFFFF;
      transform: scale(1.1);
    } 
`;

/*NFT Video */

const VideoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const NftVideo = styled.video`
  position: relative;
  width: 80%;
  height: auto;
  z-index: 5000;
  border-radius: 50px; 
  @media screen and (max-width: 1280px) {
    
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    border-radius: 10px;
    width: 90%;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    border-radius: 10px;
    width: 90%;
  }
  @media (max-width: 426px) {
    border-radius: 0;
    width: 100%;
  }
`;

const CloseButton = styled.button`
  position: relative;
  background: none;
  font-size: 1.5vh;
  left: 25%;
  cursor: pointer;
  color: white;
  border-radius: 50px;
  transition: background-color 0.3s;
  &:hover {
    border: 0.8px solid rgba(16, 123, 179, 1);
    color: rgba(16, 123, 179, 1);
  }
  @media screen and (max-width: 1280px) {
    left: 20%;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: none;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    display: none;
  }
  @media (max-width: 426px) {
    display: none;
  }
`;

const CloseButtonMobile = styled.button`
display: flex;
position: absolute;
background: none;
font-size: 3vh;
left: 47%;
top: 80%;
z-index: -5;
cursor: pointer;
color: white;
border-radius: 50px;
transition: background-color 0.3s;
&:hover {
  border: 0.8px solid rgba(16, 123, 179, 1);
  color: rgba(16, 123, 179, 1);
}
@media screen and (max-width: 1280px) {
  display: none;
}
@media screen and (min-width: 769px) and (max-width: 1024px){
  display: inherit;
  top: 93%;
  z-index: 999999;
}
@media screen and (min-width: 427px) and (max-width: 769px) {
  display: inherit;
}
  @media (max-width: 426px) {
  display: inherit;
  }
`;

const SellButton = styled.button`
  position: relative;
  padding: 1.5vh 2.8vh;
  left: 1%;
  font-size: 100%;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: 2px solid rgba(16, 123, 179, 0.8); 
  background-color: rgba(0, 0, 0, 0.5); 
  color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(16, 123, 179, 0.8);
    color: #FFFFFF;
  }
  @media screen and (max-width: 1280px) {
    
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: inherit;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    display: inherit;
  }
    @media (max-width: 426px) {
    left: 2%;
    }
  `;


const BurnButton = styled.button`
  position: relative;
  padding: 1.5vh 2.8vh;
  left: 3%;
  font-size: 100%;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: 2px solid rgba(141, 81, 207, 1); 
  background-color: rgba(0, 0, 0, 0.5); 
  color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(141, 81, 207, 0.8);
    color: #FFFFFF;
  }
  @media screen and (max-width: 1280px) {
    
  }
  @media screen and (min-width: 769px) and (max-width: 1024px){
    display: inherit;
  }
  @media screen and (min-width: 427px) and (max-width: 769px) {
    left: 2%;
  }
    @media (max-width: 426px) {
    left: 2%;
    }
  `;

const VideoTop = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -1%;
  left: 10%;
  width: 80%;
  padding: 0.5rem;
  z-index: 5001;
  
  .links {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #252425;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
    padding: 1.5rem 1rem;
    font-size: 0.9rem;
    width: 100%;
  }

  @media (max-width: 426px) {
    width: 100%;
    left: -3%;
    top: -2%;
    .links {
      padding: 1rem 0.5rem;
      font-size: 0.8rem;
    }
  }
`;



