import PrimarisOracleAbi from '../ABI/ABIPrimarisOracle.json'
import ContractsAddresses from './ContractsAdresses';

const primarisOracleAddress = ContractsAddresses.primarisOracle;

export async function getCurrentPrimarisPrice(web3) {
    try {
      if (window.ethereum) {
        const contract = new web3.eth.Contract(PrimarisOracleAbi, primarisOracleAddress);
        const reward = await contract.methods.getLastPrice().call();
        return reward;
      } else {
        console.error('MetaMask is not installed');
        return [];
      }
    } catch (error) {
      console.error('Error getting CURRENT TOKEN PRICE:', error);
      return [];
    }
}

export async function updatePrimaris(web3) {
    try {
  
      if (!window.ethereum) {
        console.error('MetaMask is not installed');
        return;
      }
  
      const accounts = await web3.eth.getAccounts();
      
      if (!accounts.length) {
        console.error('No accounts found');
        return;
      }
  
      const account = accounts[0];
      
      const primarisOracleContract = new web3.eth.Contract(PrimarisOracleAbi, primarisOracleAddress);
  
      // Estimate gas
      const gasEstimate = await primarisOracleContract.methods.getPriceInfo("USDC/USD").estimateGas({ from: account });
      const gasLimit = Math.floor(Number(gasEstimate) * 1.2);
      
      // Obtener el gas price y aumentar en un 10%
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = Math.floor(Number(gasPrice) * 1.1);
  
      // Send transaction
      const transaction = primarisOracleContract.methods.getPriceInfo("USDC/USD")
        .send({ 
          from: account,
          gas: gasLimit,
          gasPrice: increasedGasPrice,
        });
  
      transaction
        .on('transactionHash', function(hash) {
          console.log('Transaction Hash:', hash);
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          console.log('Confirmation:', confirmationNumber, 'Receipt:', receipt);
        })
        .on('receipt', function(receipt) {
          console.log('Receipt:', receipt);
        })
        .on('error', function(error, receipt) {
          console.error('Error in transaction:', error, 'Receipt:', receipt);
        });
  
      const receipt = await transaction;
      console.log('PRICE UPDATE', receipt);
      return receipt;
    } catch (error) {
      console.error('Error uppdating price', error);
      throw error; 
    }
  }